import { useAsync } from "@react-org/hooks"
import { useCallback, useEffect, useMemo } from "react"
import * as ListsApi from "../../../apis/lists.api"
import { useNotificationModalContext } from "../../../components/notification-modal/provider"
import Const from "../../../constants"
import Enums from "../../../constants/enums"
import generateContext from "../../../utils/generate-context"
import { FN, getEmissionInitialValue } from "./form-helper"

function useAddVoyageEmissionForm(props) {
  const notificationModal = useNotificationModalContext()

  const getFuelTypesQuery = useAsync(
    useCallback(
      payload => ListsApi.getFuelTypes({ params: { limit: 250 }, ...payload }),
      []
    )
  )

  const getArrivalPortsQuery = useAsync(
    useCallback(
      payload =>
        ListsApi.getPorts({
          params: { limit: 250 },
          countryId: Const.COUNTRIES.IND.VALUE,
          ...payload,
        }),
      []
    )
  )
  const getDepartureCountriesQuery = useAsync(
    useCallback(
      payload => ListsApi.getCountries({ params: { limit: 250 }, ...payload }),
      []
    )
  )

  const getDeparturePortsQuery = useAsync(
    useCallback(
      payload => ListsApi.getPorts({ params: { limit: 250 }, ...payload }),
      []
    ),
    {
      immediate: false,
    }
  )

  const handleOnDepartureCountryChange = useCallback(
    formik => countryId => {
      if (!countryId) {
        const getDeparturePortsQuerySetData =
          getDeparturePortsQuery.setQueryData
        getDeparturePortsQuerySetData(null)
      } else {
        const getDeparturePortsQueryExecute = getDeparturePortsQuery.execute
        getDeparturePortsQueryExecute({ countryId })
      }
      let timeout = setTimeout(() => {
        formik.setFieldValue(FN.DEPARTURE_PORT, "")
        formik.setFieldValue(FN.DEPARTURE_PORT_OTHER, "")
        clearTimeout(timeout)
      }, 0)
    },
    [getDeparturePortsQuery.execute, getDeparturePortsQuery.setQueryData]
  )

  const handleCancelFormClick = useCallback(
    formik => () => {
      formik.resetForm()
    },
    []
  )

  const handleOnAddEmissionClick = useCallback(
    arrayHelpers => () => {
      arrayHelpers.push(getEmissionInitialValue())
    },
    []
  )

  const handleDeleteEmissionClick = useCallback(
    (arrayHelpers, index) => () => {
      notificationModal.warning({
        confirmText: "Delete",
        canceltext: "Close",
        title: "Are you sure?",
        heading: "Are you sure you want to delete this record? ",
        onCancel: () => {
          console.log("cancel")
        },
        onConfirm: () => {
          arrayHelpers.remove(index)
        },
      })
    },
    [notificationModal]
  )

  const handleOnFuelTypeChange = useCallback(
    (formik, detailIndex) => value => {
      let conversionFactor = ""
      if (value !== Enums.OTHER_OPTION.value) {
        const fuelType = getFuelTypesQuery?.data?.data?.data.find(
          item => item.id === value
        )
        conversionFactor = fuelType?.conversion_factor
      }

      const timeout = setTimeout(() => {
        formik.setFieldValue(
          `${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.EMISSION_FACTOR}`,
          conversionFactor
        )
        formik.setFieldValue(
          `${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.FUEL_TYPE_OTHER}`,
          ""
        )
        clearTimeout(timeout)
      }, 0)
    },
    [getFuelTypesQuery?.data?.data?.data]
  )

  const handleVoyageTypeChange = useCallback((formik) => (value) => {
    if (value !== "ballast") {
      return
    }
    let timeout = setTimeout(() => {
      formik.setFieldValue(FN.CARGO_MASS, "")
      formik.setFieldValue(FN.CARGO_TYPE, "")
      formik.setFieldValue(FN.TRANSPORT_WORK, "")
      clearTimeout(timeout)
    }, 0)
  }, [])

  useEffect(() => {
    if (props?.data?.departure_country_id) {
      const getDeparturePortsQueryExecute = getDeparturePortsQuery.execute
      getDeparturePortsQueryExecute({
        countryId: props?.data?.departure_country_id,
      })
    }
  }, [getDeparturePortsQuery.execute, props?.data?.departure_country_id])

  return useMemo(() => {
    return {
      getArrivalPortsQuery,
      getDepartureCountriesQuery,
      getDeparturePortsQuery,
      getFuelTypesQuery,
      handleCancelFormClick,
      handleOnDepartureCountryChange,
      handleOnAddEmissionClick,
      handleDeleteEmissionClick,
      handleOnFuelTypeChange,
      handleVoyageTypeChange
    }
  }, [
    getArrivalPortsQuery,
    getDepartureCountriesQuery,
    getDeparturePortsQuery,
    getFuelTypesQuery,
    handleCancelFormClick,
    handleDeleteEmissionClick,
    handleOnAddEmissionClick,
    handleOnDepartureCountryChange,
    handleOnFuelTypeChange,
    handleVoyageTypeChange
  ])
}

export const [AddVoyageEmissionFormProvider, useAddVoyageEmissionFormContext] =
  generateContext(useAddVoyageEmissionForm)
