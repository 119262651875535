import { FormikButtonGroupRadio } from "components/FormControls/button-group-radio"
import { FormikDatepicker } from "components/FormControls/datepicker"
import FormLabel from "components/FormControls/form-label"
import Input, { FormikInput } from "components/FormControls/input"
import { FormikTextarea } from "components/FormControls/textarea"
import { FormikTimepicker } from "components/FormControls/timepicker"
import { FieldArray, Form, Formik } from "formik"
import React, { useMemo } from "react"
import { Button, Col, Row } from "reactstrap"
import { addDaysToDate, getFormattedDateforUI } from "utils/date-time"
import withHOC from "utils/with-hoc"
import Const from "../../../constants"
import Enums from "../../../constants/enums"
import { FormikSelect } from "../../FormControls/select"
import {
  EMISSION_MODE,
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
  VOYAGE_TYPE_LIST,
} from "./form-helper"
import {
  AddVoyageEmissionFormProvider,
  useAddVoyageEmissionFormContext,
} from "./provider"

function AddVoyageEmissionForm(props) {
  const { onSubmit, onClose, data = {} } = props
  const {
    getArrivalPortsQuery,
    getDepartureCountriesQuery,
    getDeparturePortsQuery,
    getFuelTypesQuery,
    handleOnDepartureCountryChange,
    handleCancelFormClick,
    handleOnAddEmissionClick,
    handleDeleteEmissionClick,
    handleOnFuelTypeChange,
    handleVoyageTypeChange,
  } = useAddVoyageEmissionFormContext()

  const departureCountryList = (
    getDepartureCountriesQuery?.data?.data?.data || []
  ).map(item => {
    return {
      label: item.name,
      value: item.id,
    }
  })

  const fuelTypeList = [
    ...(getFuelTypesQuery?.data?.data?.data || []).map(item => {
      return {
        label: item.name,
        value: item.id,
      }
    }),
    Enums.OTHER_OPTION,
  ]

  const departurePortList = (
    getDeparturePortsQuery?.data?.data?.data || []
  ).map(item => {
    return {
      label: item.name,
      value: item.id,
    }
  })
  const arrivalCountryList = [
    { value: Const.COUNTRIES.IND.VALUE, label: Const.COUNTRIES.IND.LABEL },
  ]

  const arrivalPortList = (getArrivalPortsQuery?.data?.data?.data || []).map(
    item => {
      return {
        label: item.name,
        value: item.id,
      }
    }
  )

  const intitialValues = useMemo(() => generateInitialValues(data), [data])
  const validationSchema = useMemo(() => generateValidationSchema({}), [])

  const handleOnSubmit = (formData, formikContext) => {
    const payload = generatePayload(formData)
    onSubmit && onSubmit(payload, formikContext)
  }

  if (fuelTypeList.length === 1) return null

  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {formik => {
        console.log(formik.errors)

        const formValues = formik.values
        const isDepartureCountryIndia =
          formValues[FN.DEPARTURE_COUNTRY] === Const.COUNTRIES.IND.VALUE

        const voyageAvgSpeed =
          (
            Number(formValues[FN.DISTANCE_REGULAR_NAVIGATION] || 0) /
            Number(formValues[FN.DURATION_REGULAR_NAVIGATION] || 0)
          ).toFixed(2) || ""

        const minArrivalDate =
          getFormattedDateforUI(
            addDaysToDate(formValues[FN.DEPARTURE_DATE], 1)
          ) || null

        const isVoyageTypeBallast = formik.values[FN.VOYAGE_TYPE] === "ballast"
        return (
          <Form className="add-emission-form add-voyage-emission-form form">
            <div className="modal-body">
              <div className="section">
                <FormLabel className="section-title">Voyage</FormLabel>
                <div className="section-body">
                  <Row>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel required>Country of Departure</FormLabel>
                        <FormikSelect
                          name={FN.DEPARTURE_COUNTRY}
                          options={departureCountryList}
                          onChange={handleOnDepartureCountryChange(formik)}
                          labelString="label"
                          valueString="value"
                        />
                      </div>
                    </Col>
                    {!isDepartureCountryIndia ? (
                      <Col xs={3}>
                        <div className="mb-3">
                          <FormLabel required>Port of Departure</FormLabel>
                          <FormikInput name={FN.DEPARTURE_PORT_OTHER} />
                        </div>
                      </Col>
                    ) : (
                      <Col xs={3}>
                        <div className="mb-3">
                          <FormLabel required>Port of Departure</FormLabel>
                          <FormikSelect
                            name={FN.DEPARTURE_PORT}
                            options={departurePortList}
                            labelString="label"
                            valueString="value"
                          />
                        </div>
                      </Col>
                    )}

                    <Col xs={6}>
                      <div className="mb-3">
                        <FormLabel required>
                          Actual Time of Departure (ATD)
                        </FormLabel>
                        <div className="flex">
                          <FormikDatepicker
                            name={FN.DEPARTURE_DATE}
                            wrapperClassName="w-200"
                            options={{ maxDate: "today" }}
                          />
                          <FormikTimepicker
                            name={FN.DEPARTURE_TIME}
                            wrapperClassName="w-200 ml-1"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel required>Country of Arrival</FormLabel>
                        <FormikSelect
                          name={FN.ARRIVAL_COUNTRY}
                          options={arrivalCountryList}
                          labelString="label"
                          valueString="value"
                          isDisabled
                        />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel required>Port of Arrival</FormLabel>
                        <FormikSelect
                          name={FN.ARRIVAL_PORT}
                          options={arrivalPortList}
                          labelString="label"
                          valueString="value"
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <FormLabel required>
                          Actual Time of Arrival (ATA)
                        </FormLabel>
                        <div className="flex">
                          <FormikDatepicker
                            name={FN.ARRIVAL_DATE}
                            wrapperClassName="w-200"
                            options={{
                              maxDate: "today",
                              minDate: minArrivalDate,
                            }}
                          />
                          <FormikTimepicker
                            name={FN.ARRIVAL_TIME}
                            wrapperClassName="w-200 ml-1"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div className="mb-3">
                        <FormLabel>Additional Notes</FormLabel>
                        <FormikTextarea
                          name={FN.ADDITIONAL_NOTES}
                          minRows={2}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="section mt-10">
                <FormLabel className="section-title">Time at Sea</FormLabel>
                <div className="section-body">
                  <Row>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel required>
                          Regular Navigation (hours)
                        </FormLabel>
                        <FormikInput
                          type="number"
                          min="0"
                          step="1"
                          strict
                          name={FN.DURATION_REGULAR_NAVIGATION}
                        />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel>Offshore Activity (hours)</FormLabel>
                        <FormikInput
                          type="number"
                          min="0"
                          step="1"
                          strict
                          name={FN.DURATION_OFFSHORE_ACTIVITY}
                        />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel>Maneuvering (hours)</FormLabel>
                        <FormikInput
                          type="number"
                          min="0"
                          step="1"
                          strict
                          name={FN.DURATION_MANOEVERING}
                        />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel>At Anchorage (hours)</FormLabel>
                        <FormikInput
                          type="number"
                          min="0"
                          step="1"
                          strict
                          name={FN.DURATION_AT_ANCHORAGE}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="section mt-10">
                <FormLabel className="section-title">
                  Distance Travelled
                </FormLabel>
                <div className="section-body">
                  <Row>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel required>
                          Regular Navigation (n miles)
                        </FormLabel>
                        <FormikInput
                          type="number"
                          name={FN.DISTANCE_REGULAR_NAVIGATION}
                        />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel>Offshore Activity (n miles)</FormLabel>
                        <FormikInput
                          type="number"
                          name={FN.DISTANCE_OFFSHORE_ACTIVITY}
                        />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel>Maneuvering (n miles)</FormLabel>
                        <FormikInput
                          type="number"
                          name={FN.DISTANCE_MANOEVERING}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="section mt-10">
                <div className="section-body">
                  <Row>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel required>Voyage Type</FormLabel>
                        <FormikButtonGroupRadio
                          name={FN.VOYAGE_TYPE}
                          options={VOYAGE_TYPE_LIST}
                          labelString="label"
                          valueString="value"
                          onChange={handleVoyageTypeChange(formik)}
                        />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel>
                          Average Speed During the Voyage (knots){" "}
                        </FormLabel>
                        <Row>
                          <Col xs={9}>
                            <Input
                              type="number"
                              name="voyage-avg-speed"
                              value={voyageAvgSpeed}
                              disabled
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="section mt-10">
                <FormLabel className="section-title">
                  Cargo and Transport Work
                </FormLabel>
                <div className="section-body">
                  <Row>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel required={!isVoyageTypeBallast}>
                          Cargo Mass (m tonnes)
                        </FormLabel>
                        <FormikInput
                          type="number"
                          name={FN.CARGO_MASS}
                          disabled={isVoyageTypeBallast}
                        />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel required={!isVoyageTypeBallast}>
                          Cargo Type
                        </FormLabel>
                        <FormikInput
                          name={FN.CARGO_TYPE}
                          disabled={isVoyageTypeBallast}
                        />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel>Transport Work</FormLabel>
                        <FormikInput
                          type="number"
                          name={FN.TRANSPORT_WORK}
                          disabled={isVoyageTypeBallast}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="section mt-10">
                <FormLabel className="section-title">
                  Emissions - Fuel Consumption Report
                </FormLabel>
                <FieldArray
                  name={FN.EMISSION.ROOT}
                  render={arrayHelpers => {
                    const details = formik.values?.[FN.EMISSION.ROOT] || []
                    const isOnlyItem = details.length === 1
                    const eleArr = details.map((detail, detailIndex) => {
                      const isFuelTypeOther =
                        detail[FN.EMISSION.FUEL_TYPE] ===
                        Enums.OTHER_OPTION.value

                      const co2Emission =
                        (
                          Number(detail[FN.EMISSION.FUEL_QUANTITY] || 0) *
                          Number(detail[FN.EMISSION.EMISSION_FACTOR] || 0)
                        ).toFixed(2) || ""

                      return (
                        <div
                          key={detailIndex}
                          className="section-body mb-10 position-relative"
                        >
                          <Row>
                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel required>Fuel Type</FormLabel>
                                <FormikSelect
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.FUEL_TYPE}`}
                                  options={fuelTypeList}
                                  onChange={handleOnFuelTypeChange(
                                    formik,
                                    detailIndex
                                  )}
                                  labelString="label"
                                  valueString="value"
                                />
                              </div>
                            </Col>
                            {isFuelTypeOther && (
                              <Col xs={3}>
                                <div className="mb-3">
                                  <FormLabel required>
                                    Fuel Type (Other)
                                  </FormLabel>
                                  <FormikInput
                                    name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.FUEL_TYPE_OTHER}`}
                                  />
                                </div>
                              </Col>
                            )}

                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel required>
                                  Quantity (m tonnes)
                                </FormLabel>
                                <FormikInput
                                  type="number"
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.FUEL_QUANTITY}`}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel>Mode</FormLabel>
                                <FormikSelect
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.MODE}`}
                                  options={EMISSION_MODE}
                                  labelString="label"
                                  valueString="value"
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel required={isFuelTypeOther}>
                                  Emission Factor (t-CO2/t-Fuel)
                                </FormLabel>
                                <FormikInput
                                  disabled={!isFuelTypeOther}
                                  type="number"
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.EMISSION_FACTOR}`}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel>CO2 Emissions (m tonnes)</FormLabel>
                                <Input
                                  disabled
                                  type="number"
                                  value={co2Emission}
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].co2_emission`}
                                />
                              </div>
                            </Col>
                          </Row>
                          {!isOnlyItem && (
                            <div
                              className="delete-action"
                              title="Remove Emission"
                              onClick={handleDeleteEmissionClick(
                                arrayHelpers,
                                detailIndex
                              )}
                            >
                              <i className="bx bx-trash"></i>
                            </div>
                          )}
                        </div>
                      )
                    })

                    return (
                      <>
                        {eleArr}
                        <Button
                          type="button"
                          className="btn-soft-success d-block rounded-10"
                          onClick={handleOnAddEmissionClick(arrayHelpers)}
                        >
                          + Add Fuel Consumption
                        </Button>
                      </>
                    )
                  }}
                />
              </div>
            </div>
            <div className="actions modal-footer">
              <div className="actions-left">
                <Button
                  color="success"
                  type="button"
                  outline
                  onClick={onClose}
                  className="action-button rounded-10"
                >
                  Close
                </Button>
              </div>
              <div className="actions-right">
                {formik.dirty && (
                  <Button
                    color="success"
                    type="button"
                    outline
                    className="action-button rounded-10"
                    onClick={handleCancelFormClick(formik)}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  color="success"
                  type="submit"
                  className="action-button rounded-10"
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default withHOC(AddVoyageEmissionFormProvider, AddVoyageEmissionForm)
