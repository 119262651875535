import { useAsync } from "@react-org/hooks"
import { useMemo, useCallback, useState, useEffect } from "react"
import generateContext from "utils/generate-context"
import * as ListsApi from "../../../apis/lists.api"
import * as UsersApi from "../../../apis/users.api"

function useEditCompanyForm(props) {
  const [file, setFile] = useState(null)

  const getUsersQuery = useAsync(UsersApi.getUsers)

  const getCountriesQuery = useAsync(
    useCallback(
      payload => ListsApi.getCountries({ params: { limit: 250 }, ...payload }),
      []
    )
  )

  const handleCancelFormClick = useCallback(
    formik => () => {
      formik.resetForm()
    },
    []
  )

  const handleOnDrop = useCallback(file => {
    setFile(file)
  }, [])

  const handleClearFile = useCallback(() => {
    setFile(null)
  }, [])

  useEffect(() => {
    if(props.logoUrl){
      setFile({ preview: props.logoUrl })
    }
  }, [props.logoUrl])

  return useMemo(() => {
    return {
      getUsersQuery,
      getCountriesQuery,
      handleCancelFormClick,
      file,
      handleClearFile,
      handleOnDrop,
    }
  }, [
    file,
    getCountriesQuery,
    getUsersQuery,
    handleCancelFormClick,
    handleClearFile,
    handleOnDrop,
  ])
}

export const [EditCompanyFormProvider, useEditCompanyFormContext] =
  generateContext(useEditCompanyForm)
