import Heading from "components/VerticalLayout/Heading"
import React from "react"
import { Container } from "reactstrap"
import Sidebar from "components/VerticalLayout/Sidebar"
import VirtualizeTable from "components/Common/virtualiza-table"
import ShipDetailCard from "components/Common/ship-details-card"
import NoRecords from "components/Common/no-records"
import { PortCallRecordsPageProvider, usePortCallRecordsPageContext } from "./provider"
import withHOC from "utils/with-hoc"


const renderNoDataRows = () => {
  return <NoRecords message="No port calls found" />
}

const PortCallRecordsPage = () => {
  //meta title
  document.title = "Port Call Records"

  const { getShipQuery, getPortCallsQuery, listRef, columns, breadcrumb } =
  usePortCallRecordsPageContext()

  return (
    <>
      <Heading title="Sanmar Songbird" breadcrumbs={breadcrumb} />
      <div id="my-fleet-page" className="page-content">
        <Container fluid={true} className="with-sidebar">
          <Sidebar />
          <div className="main">
            <ShipDetailCard data={getShipQuery?.data?.data} />

            <div className="section">
              <div className="section-body">
                <VirtualizeTable
                  ref={listRef}
                  rows={getPortCallsQuery?.data?.data}
                  columns={columns}
                  hasMore={false}
                  isLoading={getPortCallsQuery?.isPending}
                  isLoadMoreDirty={!getPortCallsQuery?.isIdle}
                  loadingRowCount={getPortCallsQuery?.isPending ? 10 : 0}
                  renderNoDataRows={renderNoDataRows}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
export default withHOC(PortCallRecordsPageProvider, PortCallRecordsPage)
