import React, { useRef, useState, useCallback } from "react"

import { Dropdown, DropdownItem, DropdownToggle } from "reactstrap"
import ThreeDotsIcon from "components/Vectors/three-dots"
import IconBox from "components/Common/icon-box"
import DropdownMenuPortal from "components/Core/dropdown-menu-portal"
import { useMyFleetPageContext } from "./provider"

const ThreeDotMenu = props => {
  const { handleThreeDotMenuClick } = useMyFleetPageContext()
  const [menu, setMenu] = useState(false)
  const targetElementRef = useRef()

  const handleMenuClick = useCallback(
    action => event => {
      handleThreeDotMenuClick(action, event, props.data)
    },
    [handleThreeDotMenuClick, props.data]
  )

  return (
    <div
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Dropdown
        isOpen={menu}
        direction="up"
        toggle={() => {
          setMenu(!menu)
        }}
      >
        <DropdownToggle tag="div" innerRef={targetElementRef}>
          <IconBox>
            <ThreeDotsIcon className="text-success" />
          </IconBox>
        </DropdownToggle>
        <DropdownMenuPortal
          isOpen={menu}
          targetElement={targetElementRef.current}
        >
          <DropdownItem onClick={handleMenuClick("edit")}>
            <span className="mr-1">
              <i className="bx bx-pencil"></i>
            </span>
            <span>Edit</span>
          </DropdownItem>
          <div className="dropdown-divider" />

          <DropdownItem onClick={handleMenuClick("delete")}>
            <span className="mr-1">
              <i className="mdi mdi-close"></i>
            </span>
            <span>Remove</span>
          </DropdownItem>
        </DropdownMenuPortal>
      </Dropdown>
    </div>
  )
}

export default ThreeDotMenu
