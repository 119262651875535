import * as validations from "../../../helpers/validation-helper"
import * as yup from "yup"
import trimString from "utils/trim-string"

export const FN = {
  IMO_NUMBER: "imo_number",
  SHIP_NAME: "ship_name",
  FLAG: "flag",
  SHIP_TYPE: "ship_type",
  SHIP_TYPE_OTHER: "ship_type_other",
  PORT_OF_REGISTRY: "port_of_registry",
  GROSS_TONNAGE: "gross_tonnage",
  DEADWEIGHT: "deadweight",
  ICE_CLASS: "ice_class",
  DUAL_FUEL_ENGINES: "dual_fuel_engines",
  UNDER_SPL: "under_spl",
  CLASSIFICATION_SOCIETY: "classification_society",
  CLASSIFICATION_SOCIETY_OTHER: "classification_society_other",
  COMPLY_WITH_EEXI: "comply_with_eexi",
  COMPLY_WITH_EEDI: "comply_with_eedi",
  SHIP_OWNER: "ship_owner",
  SHIP_MANAGER: "ship_manager",
}

export const OTHER_OPTION = { label: "Other", value: null }

export const generateInitialValues = (data = {}) => {
  const isClassSociOther = data.classification_society_id === OTHER_OPTION.value
  const isShipTypeOther = data.ship_type_id === OTHER_OPTION.value

  return {
    [FN.IMO_NUMBER]: data.imo_number,
    [FN.SHIP_NAME]: data.name,
    [FN.FLAG]: data.flag_id,
    [FN.SHIP_TYPE]: data.ship_type_id,
    [FN.SHIP_TYPE_OTHER]: isShipTypeOther ? data.ship_type_other : "",
    [FN.PORT_OF_REGISTRY]: data.port_of_registry,
    [FN.GROSS_TONNAGE]: data.gross_tonnage,
    [FN.DEADWEIGHT]: data.deadweight,
    [FN.ICE_CLASS]: data.ice_class,
    [FN.DUAL_FUEL_ENGINES]: data.dual_fuel_engines,
    [FN.UNDER_SPL]: data.under_spl,
    [FN.CLASSIFICATION_SOCIETY]: data.classification_society_id,
    [FN.CLASSIFICATION_SOCIETY_OTHER]: isClassSociOther
      ? data.classification_society_other
      : "",
    [FN.COMPLY_WITH_EEXI]: data.comply_with_eexi,
    [FN.COMPLY_WITH_EEDI]: data.comply_with_eedi,
    [FN.SHIP_OWNER]: data.ship_owner,
    [FN.SHIP_MANAGER]: data.ship_manager,
  }
}

export const generateValidationSchema = () => {
  return yup.lazy(value => {
    const isShipTypeOther = value[FN.SHIP_TYPE] === null
    const isClassSociOther = value[FN.CLASSIFICATION_SOCIETY] === null

    return yup.object().shape({
      [FN.IMO_NUMBER]: validations.isRequiredString(),
      [FN.SHIP_NAME]: validations.isRequiredString(),
      [FN.FLAG]: validations.isRequiredString(),
      [FN.SHIP_TYPE]: validations.isRequiredString({ nullable: true }),
      [FN.SHIP_TYPE_OTHER]: validations.isRequiredString({
        withRequired: isShipTypeOther,
        nullable: true,
      }),
      [FN.PORT_OF_REGISTRY]: validations.isRequiredString(),
      [FN.GROSS_TONNAGE]: validations.isRequiredString(),
      [FN.DEADWEIGHT]: validations.isRequiredString(),
      [FN.CLASSIFICATION_SOCIETY]: validations.isRequiredString({
        nullable: true,
      }),
      [FN.CLASSIFICATION_SOCIETY_OTHER]: validations.isRequiredString({
        withRequired: isClassSociOther,
        nullable: true,
      }),
      [FN.SHIP_OWNER]: validations.isRequiredString(),
      [FN.SHIP_MANAGER]: validations.isRequiredString(),
    })
  })
}

export const generatePayload = formData => {
  return {
    imo_number: trimString(formData[FN.IMO_NUMBER]),
    name: trimString(formData[FN.SHIP_NAME]),
    flag_id: trimString(formData[FN.FLAG]),
    ship_type_id: trimString(formData[FN.SHIP_TYPE]) || null,
    ship_type_other: trimString(formData[FN.SHIP_TYPE_OTHER]) || null,
    port_of_registry: trimString(formData[FN.PORT_OF_REGISTRY]),
    gross_tonnage: trimString(formData[FN.GROSS_TONNAGE]),
    deadweight: trimString(formData[FN.DEADWEIGHT]),
    ice_class: trimString(formData[FN.ICE_CLASS]),
    dual_fuel_engines: trimString(formData[FN.DUAL_FUEL_ENGINES]),
    under_spl: trimString(formData[FN.UNDER_SPL]),
    classification_society_id:
      trimString(formData[FN.CLASSIFICATION_SOCIETY]) || null,
    classification_society_other:
      trimString(formData[FN.CLASSIFICATION_SOCIETY_OTHER]) || null,
    comply_with_eexi: trimString(formData[FN.COMPLY_WITH_EEXI]),
    comply_with_eedi: trimString(formData[FN.COMPLY_WITH_EEDI]),
    ship_owner: trimString(formData[FN.SHIP_OWNER]),
    ship_manager: trimString(formData[FN.SHIP_MANAGER]),
  }
}
