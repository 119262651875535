import React from "react";

function SearchIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
        >
            <g
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                clipPath="url(#clip0_234_13497)"
            >
                <path d="M5.833 9.917a4.083 4.083 0 100-8.167 4.083 4.083 0 000 8.167zM12.25 12.25l-3.5-3.5"></path>
            </g>
            <defs>
                <clipPath id="clip0_234_13497">
                    <rect width="14" height="14" fill="#fff" rx="4"></rect>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SearchIcon;