import Heading from "components/VerticalLayout/Heading"
import React from "react"
import { Container } from "reactstrap"
import Sidebar from "components/VerticalLayout/Sidebar"
import VirtualizeTable from "components/Common/virtualiza-table"
import ShipDetailCard from "components/Common/ship-details-card"
import NoRecords from "components/Common/no-records"
import {
  ReportedVoyagesPageProvider,
  useReportedVoyagesPageContext,
} from "./provider"
import withHOC from "utils/with-hoc"

const renderNoDataRows = () => {
  return <NoRecords message="No voyages found" />
}

const ReportedVoyagesPage = () => {
  //meta title
  document.title = "Voyage Report"

  const { getShipQuery, getVoyageReportQuery, listRef, columns, breadcrumb } =
    useReportedVoyagesPageContext()

  return (
    <>
      <Heading title="Sanmar Songbird" breadcrumbs={breadcrumb} />
      <div id="my-fleet-page" className="page-content">
        <Container fluid={true} className="with-sidebar">
          <Sidebar />
          <div className="main">
            <ShipDetailCard data={getShipQuery?.data?.data} />

            <div className="section">
              <div className="section-body">
                <VirtualizeTable
                  ref={listRef}
                  rows={getVoyageReportQuery?.data?.data}
                  columns={columns}
                  hasMore={false}
                  isLoading={getVoyageReportQuery?.isPending}
                  isLoadMoreDirty={!getVoyageReportQuery?.isIdle}
                  loadingRowCount={getVoyageReportQuery?.isPending ? 10 : 0}
                  renderNoDataRows={renderNoDataRows}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
export default withHOC(ReportedVoyagesPageProvider, ReportedVoyagesPage)
