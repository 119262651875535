import React from "react"

import { Modal } from "reactstrap"

export default function ModalContainer(props) {
  const {
    title = "",
    isOpen,
    handleToggle,
    handleClose,
    children,
    ...restProps
  } = props

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={handleToggle}
      scrollable={true}
      {...restProps}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">{title}</h5>
        <button
          onClick={handleClose}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      {children}
    </Modal>
  )
}
