import React from "react"
import { Form, Formik } from "formik"
import { FormikSelect } from "../../FormControls/select"
import { Button, Col, Row } from "reactstrap"
import { FormikInput } from "components/FormControls/input"
import FormLabel from "components/FormControls/form-label"
import {
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
} from "./form-helper"
import { FormikTextarea } from "components/FormControls/textarea"
import { generatePath, useNavigate } from "react-router-dom"
import IfcrDropZone from "components/drop-zone"
import withHOC from "utils/with-hoc"
import { RegisterFormProvider, useRegisterFormContext } from "./provider"

function RegistartionForm(props) {
  const { onSubmit } = props

  const {
    getCountriesQuery,
    getCitiesQuery,
    handleCountryChange,
    handleCancelFormClick,
    file,
    handleClearFile,
    handleOnDrop,
  } = useRegisterFormContext()

  const cityList = (getCitiesQuery?.data?.data?.data || []).map(item => {
    return {
      label: item.name,
      value: item.id,
    }
  })
  const countryList = (getCountriesQuery?.data?.data?.data || []).map(item => {
    return {
      label: item.name,
      value: item.id,
    }
  })
  const navigate = useNavigate()

  const intitialValues = generateInitialValues({})
  const validationSchema = generateValidationSchema({})

  const handleOnSubmit = (formData, formikContext) => {
    const payload = { ...generatePayload(formData), file }

    onSubmit && onSubmit(payload, formikContext)
  }

  const handleBackToLogin = () => {
    const redirectLink = generatePath("/login")
    navigate(redirectLink)
  }

  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {formik => {
        const countryId = formik.values[FN.COUNTRY]
        const country = (getCountriesQuery?.data?.data?.data || []).find(
          item => item.id === countryId
        )
        const isCountryIndia =
          country?.code === "ind" || country?.code === "IND"

        return (
          <Form className="registration-form form">
            <div className="modal-body">
              <div className="section">
                <div className="section-title">Company Details</div>
                <div className="section-body">
                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel required>Company Name</FormLabel>
                        <FormikInput name={FN.COMPANY_NAME} />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel required>Company Number</FormLabel>
                        <FormikInput name={FN.COMPANY_NUMBER} />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel>Principal Place of Business</FormLabel>
                        <FormikInput name={FN.PRINCIPAL_PLACE_OF_BUSINESS} />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel required>Country</FormLabel>
                        <FormikSelect
                          name={FN.COUNTRY}
                          onChange={handleCountryChange(formik)}
                          options={countryList}
                          labelString="label"
                          valueString="value"
                        />
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div className="mb-3">
                        <FormLabel required>Company Address</FormLabel>
                        <FormikTextarea name={FN.COMPANY_ADDRESS} minRows={2} />
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div className="mb-3">
                        <FormLabel>Company Logo</FormLabel>
                        <IfcrDropZone
                          file={file}
                          onClear={handleClearFile}
                          onDrop={handleOnDrop}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="section">
                <div className="section-title">Contact Person</div>
                <div className="section-body">
                  <Row>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel required>Name</FormLabel>
                        <FormikInput
                          name={`${FN.CONTACT_PERSON.ROOT}.${FN.CONTACT_PERSON.NAME}`}
                        />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel required>Email</FormLabel>
                        <FormikInput
                          name={`${FN.CONTACT_PERSON.ROOT}.${FN.CONTACT_PERSON.EMAIL}`}
                        />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel required>Re-enter Email</FormLabel>
                        <FormikInput
                          name={`${FN.CONTACT_PERSON.ROOT}.${FN.CONTACT_PERSON.RE_EMAIL}`}
                        />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel required>Designation</FormLabel>
                        <FormikInput
                          name={`${FN.CONTACT_PERSON.ROOT}.${FN.CONTACT_PERSON.DESIGNATION}`}
                        />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel required>Telephone</FormLabel>
                        <FormikInput
                          name={`${FN.CONTACT_PERSON.ROOT}.${FN.CONTACT_PERSON.TELEPHONE}`}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="actions modal-footer">
              <div className="actions-left">
                <Button
                  color="success"
                  type="button"
                  outline
                  onClick={handleBackToLogin}
                  className="action-button rounded-10"
                >
                  Back to Login
                </Button>
              </div>
              <div className="actions-right">
                {formik.dirty && (
                  <Button
                    color="success"
                    type="button"
                    outline
                    className="action-button rounded-10"
                    onClick={handleCancelFormClick(formik)}
                  >
                    Cancel
                  </Button>
                )}
                <Button color="success" type="submit" className="action-button rounded-10">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default withHOC(RegisterFormProvider, RegistartionForm)
