import { useAsync } from "@react-org/hooks"
import * as AuthApi from "apis/auth.api"
import * as MeApi from "apis/me.api"
import { useNotificationModalContext } from "components/notification-modal/provider"
import { useUserFromStorage } from "contexts/user.context"
import { useMemo } from "react"
import { useCallback } from "react"
import generateContext from "../../utils/generate-context"

function useLoginPage() {
  const user = useUserFromStorage()

  const notificationModal = useNotificationModalContext()

  const loginQuery = useAsync(AuthApi.login, { immediate: false })
  const getUserInfoQuery = useAsync(MeApi.getUserInfo, { immediate: false })

  const handleLogin = useCallback(
    (formData, { setSubmitting }) => {
      notificationModal.progress({ heading: "Signing In" })
      const execute = loginQuery.execute
      execute(formData, {
        onSuccess: res => {
          setSubmitting(false)
          notificationModal.close()
          const executeMeInforQuery = getUserInfoQuery.execute
          executeMeInforQuery(
            {},
            {
              onSuccess: res => {
                user.setUser(res.data)
                navigate("/")
              },
            }
          )
        },
        onError: err => {
          if (err?.response?.status === 401) {
            notificationModal.error({
              title: "Unauthorized",
              heading: "The username or password you entered is incorrect",
              onClose: () => {
                console.log("closed")
              },
            })
          }
        },
      })
    },
    [loginQuery.execute, getUserInfoQuery.execute, notificationModal, user]
  )

  return useMemo(() => {
    return {
      handleLogin: handleLogin,
    }
  }, [handleLogin])
}

export const [LoginPageProvider, useLoginPageContext] =
  generateContext(useLoginPage)
