import React from "react"

import { Col, Row } from "reactstrap"
import FormLabel from "components/FormControls/form-label"

export default function UserDetailsBox(props) {
  const { name, email, designation, telephone } = props.data || {}
  return (
    <Row>
      <Col xs={3}>
        <FormLabel>Name</FormLabel>
        <p>{name}</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Email</FormLabel>
        <p>{email}</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Designation</FormLabel>
        <p>{designation}</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Telephone</FormLabel>
        <p>{telephone}</p>
      </Col>
    </Row>
  )
}
