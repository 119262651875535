import React from "react";
import  { Label } from  "reactstrap";

function FormLabel(props) {
  const { required, children, ...restProps } = props
  return (
    <div className="d-flex">
      {required && <span className="form-label_required">*</span>}
      <Label {...restProps}>{children}</Label>
    </div>
  )
}

export default FormLabel
