import { FieldArray, Form, Formik } from "formik"
import React, { useMemo } from "react"
import { Button, Col, Row } from "reactstrap"
import { addDaysToDate, getFormattedDateforUI } from "utils/date-time"
import { FormikButtonGroupRadio } from "../../../components/FormControls/button-group-radio"
import { FormikDatepicker } from "../../../components/FormControls/datepicker"
import FormLabel from "../../../components/FormControls/form-label"
import Input, { FormikInput } from "../../../components/FormControls/input"
import { FormikSelect } from "../../../components/FormControls/select"
import { FormikTextarea } from "../../../components/FormControls/textarea"
import { FormikTimepicker } from "../../../components/FormControls/timepicker"
import Const from "../../../constants"
import Enums from "../../../constants/enums"
import withHOC from "../../../utils/with-hoc"
import {
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
  PORT_ACTIVITY_LIST,
} from "./form-helper"
import {
  AddPortEmissionFormProvider,
  useAddPortEmissionFormContext,
} from "./provider"

function AddPortEmissionForm(props) {
  const { onSubmit, onClose, data = {} } = props
  const {
    getFuelTypesQuery,
    getArrivalPortsQuery,
    handleCancelFormClick,
    handleOnAddEmissionClick,
    handleDeleteEmissionClick,
    handleOnFuelTypeChange,
  } = useAddPortEmissionFormContext()

  const arrivalCountryList = [
    { value: Const.COUNTRIES.IND.VALUE, label: Const.COUNTRIES.IND.LABEL },
  ]
  const arrivalPortList = (getArrivalPortsQuery?.data?.data?.data || []).map(
    item => {
      return {
        label: item.name,
        value: item.id,
      }
    }
  )
  const fuelTypeList =  [
      ...(getFuelTypesQuery?.data?.data?.data || []).map(item => {
        return {
          label: item.name,
          value: item.id,
        }
      }),
      Enums.OTHER_OPTION,
    ];


  const intitialValues = useMemo(() => generateInitialValues(data), [data])
  const validationSchema = useMemo(() => generateValidationSchema({}), [])

  const handleOnSubmit = (formData, formikContext) => {
    const payload = generatePayload(formData)
    onSubmit && onSubmit(payload, formikContext)
  }

  if(fuelTypeList.length === 1) return null;

  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {formik => {

        const formValues = formik.values

        const isShorePowerWasAvailable =
          formValues[FN.SHORE_POWER_AVAILBLE] === true

        const minDepartureDate =
          getFormattedDateforUI(
            addDaysToDate(formValues[FN.ARRIVAL_DATE], 1)
          ) || null

        return (
          <Form className="add-emission-form add-port-emission-form form">
            <div className="modal-body">
              <div className="section">
                <FormLabel className="section-title">Port Call</FormLabel>
                <div className="section-body">
                  <Row>
                    <Col xs={5}>
                      <Row>
                        <Col xs={5}>
                          <div className="mb-3">
                            <FormLabel>Country</FormLabel>
                            <FormikSelect
                              name={FN.ARRIVAL_COUNTRY}
                              options={arrivalCountryList}
                              labelString="label"
                              valueString="value"
                              isDisabled
                            />
                          </div>
                        </Col>
                        <Col xs={7}>
                          <div className="mb-3">
                            <FormLabel required>Port</FormLabel>
                            <FormikSelect
                              name={FN.ARRIVAL_PORT}
                              options={arrivalPortList}
                              labelString="label"
                              valueString="value"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={7}>
                      <Row>
                        <Col xs={6}>
                          <div className="mb-3">
                            <FormLabel required>
                              Actual Time of Arrival (ATA)
                            </FormLabel>
                            <div className="flex">
                              <FormikDatepicker
                                name={FN.ARRIVAL_DATE}
                                wrapperClassName="w-200"
                                options={{ maxDate: "today" }}
                              />
                              <FormikTimepicker
                                name={FN.ARRIVAL_TIME}
                                wrapperClassName="w-100 ml-1"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="mb-3">
                            <FormLabel required>
                              Actual Time of Departure (ATD)
                            </FormLabel>
                            <div className="flex">
                              <FormikDatepicker
                                name={FN.DEPARTURE_DATE}
                                wrapperClassName="w-200"
                                options={{
                                  maxDate: "today",
                                  minDate: minDepartureDate,
                                }}
                              />
                              <FormikTimepicker
                                name={FN.DEPARTURE_TIME}
                                errorClassName="ml-1"
                                wrapperClassName="w-100 ml-1"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12}>
                      <div className="mb-3">
                        <FormLabel>Additional Notes</FormLabel>
                        <FormikTextarea
                          name={FN.ADDITIONAL_NOTES}
                          minRows={2}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="section mt-10">
                <div className="section-body">
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <FormLabel className="w-250" required>
                          Whether shore power was available?
                        </FormLabel>
                        <FormikButtonGroupRadio
                          name={FN.SHORE_POWER_AVAILBLE}
                          options={Enums.OPTION_YES_NO}
                          labelString="label"
                          valueString="value"
                          classNames={{
                            container: "d-inline-block ml-1",
                            item: "w-100",
                          }}
                        />
                      </div>
                    </Col>
                    {isShorePowerWasAvailable && (
                      <Col xs={6}>
                        <div className="mb-3">
                          <FormLabel className="w-250" required>
                            Whether shore power was used?
                          </FormLabel>
                          <FormikButtonGroupRadio
                            name={FN.SHORE_POWER_USED}
                            options={Enums.OPTION_YES_NO}
                            labelString="label"
                            valueString="value"
                            classNames={{
                              container: "d-inline-block ml-1",
                              item: "w-100",
                            }}
                          />
                        </div>
                      </Col>
                    )}
                    <Col xs={6}>
                      <div className="mb-3">
                        <FormLabel className="w-250" required>
                          Whether bunkering done at this port?
                        </FormLabel>
                        <FormikButtonGroupRadio
                          name={FN.BUNKERING_DONE}
                          options={Enums.OPTION_YES_NO}
                          labelString="label"
                          valueString="value"
                          classNames={{
                            container: "d-inline-block ml-1",
                            item: "w-100",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="section mt-10">
                <FormLabel className="section-title">
                  Emissions - Fuel Consumption Report
                </FormLabel>
                <FieldArray name={FN.EMISSION.ROOT}>
                  {arrayHelpers => {

                    const details = formik.values?.[FN.EMISSION.ROOT] || []
                    const isOnlyItem = details.length === 1
                    const eleArr = details.map((detail, detailIndex) => {
                      const isFuelTypeOther =
                        detail[FN.EMISSION.FUEL_TYPE] ===
                        Enums.OTHER_OPTION.value

                      const co2Emission =
                        (
                          Number(detail[FN.EMISSION.FUEL_QUANTITY] || 0) *
                          Number(detail[FN.EMISSION.EMISSION_FACTOR] || 0)
                        ).toFixed(2) || ""
                      return (
                        <div
                          key={detailIndex}
                          className="position-relative section-body mb-10"
                        >
                          <Row>
                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel required>Fuel Type</FormLabel>
                                <FormikSelect
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.FUEL_TYPE}`}
                                  options={fuelTypeList}
                                  onChange={handleOnFuelTypeChange(
                                    formik,
                                    detailIndex
                                  )}
                                  labelString="label"
                                  valueString="value"
                                />
                              </div>
                            </Col>
                            {isFuelTypeOther && (
                              <Col xs={3}>
                                <div className="mb-3">
                                  <FormLabel required>
                                    Fuel Type (Other)
                                  </FormLabel>
                                  <FormikInput
                                    name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.FUEL_TYPE_OTHER}`}
                                  />
                                </div>
                              </Col>
                            )}
                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel required>Quantity (m tonnes)</FormLabel>
                                <FormikInput
                                  type="number"
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.FUEL_QUANTITY}`}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel required>Port Activity</FormLabel>
                                <FormikSelect
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.PORT_ACTIVITY}`}
                                  options={PORT_ACTIVITY_LIST}
                                  labelString="label"
                                  valueString="value"
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel required={isFuelTypeOther}>
                                  Emission Factor (t-CO2/t-Fuel)
                                </FormLabel>
                                <FormikInput
                                  disabled={!isFuelTypeOther}
                                  type="number"
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.EMISSION_FACTOR}`}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="mb-3">
                                <FormLabel>CO2 Emissions (m tonnes)</FormLabel>
                                <Input
                                  disabled
                                  type="number"
                                  value={co2Emission}
                                  name={`${FN.EMISSION.ROOT}.[${detailIndex}].co2_emission`}
                                />
                              </div>
                            </Col>
                          </Row>
                          {!isOnlyItem && (
                            <div
                              className="delete-action"
                              title="Remove Emission"
                              onClick={handleDeleteEmissionClick(
                                arrayHelpers,
                                detailIndex
                              )}
                            >
                              <i className="bx bx-trash"></i>
                            </div>
                          )}
                        </div>
                      )
                    })
                    
                    return (
                      <>
                        {eleArr}
                        <Button
                          type="button"
                          className="btn-soft-success d-block rounded-10"
                          onClick={handleOnAddEmissionClick(arrayHelpers)}
                        >
                          + Add Fuel Consumption
                        </Button>
                      </>
                    )
                  }}
                </FieldArray>
              </div>
            </div>
            <div className="actions modal-footer">
              <div className="actions-left">
                <Button
                  color="success"
                  type="button"
                  outline
                  onClick={onClose}
                  className="action-button rounded-10"
                >
                  Close
                </Button>
              </div>
              <div className="actions-right">
                {formik.dirty && (
                  <Button
                    color="success"
                    type="button"
                    outline
                    className="action-button rounded-10"
                    onClick={handleCancelFormClick(formik)}
                  >
                    Cancel
                  </Button>
                )}
                <Button color="success" type="submit" className="action-button rounded-10">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default withHOC(AddPortEmissionFormProvider, AddPortEmissionForm)
