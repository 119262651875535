import PropTypes from "prop-types"
import React, { useEffect } from "react"
import withRouter from "components/Common/withRouter"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import RightSidebar from "../CommonForBoth/RightSidebar"

const Layout = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content">{props.children}</div>
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default withRouter(Layout)
