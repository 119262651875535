import { useAsync } from "@react-org/hooks"
import { useMemo, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import generateContext from "../../utils/generate-context"
import * as AuthApi from "../../apis/auth.api"
import { useNotificationModalContext } from "components/notification-modal/provider"
import { FN } from "components/Forms/reset-password-form/form-helper"
import useQueryParams from "hooks/use-query-params"

function useResetPasswordPage() {
  const navigate = useNavigate()
  const notificationModal = useNotificationModalContext()
  const queryParams = useQueryParams()
  const resetPasswordQuery = useAsync(AuthApi.resetPassword, {
    immediate: false,
  })

  const handleResetPassword = useCallback(
    (formData, { setSubmitting }) => {
      notificationModal.progress({
        heading: "Password Reset In Progress",
      })
      const payload = {
        password: formData[FN.PASSWORD],
        reset_token: queryParams.token,
      }
      const registerQueryExecute = resetPasswordQuery.execute
      registerQueryExecute(payload, {
        onSuccess: res => {
          notificationModal.success({
            title: "Password Reset Successful",
            heading:
              "Your password has been successfully reset. You can now log in to your account using your new password",
            closeText: "Go to Login",
            onClose: () => {
              navigate("/login")
            },
          })
        },
        onError: err => {
          if (err?.response?.status === 410) {
            notificationModal.error({
              title: "Password Reset Token Expired",
              heading:
                "We're sorry, but the password reset token you are using has expired. Password reset tokens typically have a limited validity period for security reasons. Please initiate the password reset process again to receive a new token and continue with resetting your password",
              closeText: "Go to Forgot Password",
              onClose: () => {
                navigate("/forgot-password")
              },
            })
          } else {
            notificationModal.close()
          }
        },
      })
    },
    [navigate, notificationModal, queryParams.token, resetPasswordQuery.execute]
  )

  return useMemo(() => {
    return {
      handleResetPassword,
    }
  }, [handleResetPassword])
}

export const [ResetPasswordPageProvider, useResetPasswordPageContext] =
  generateContext(useResetPasswordPage)
