import React from "react"
import { Navigate } from "react-router-dom"

import MyFleetPage from "../pages/my-fleet.page"
import EmissionsPage from "../pages/emissions.page"
import RegisterPage from "../pages/register.page"
import LoginPage from "../pages/login.page"
import ForgotPasswordPage from "../pages/forgot-password.page"
import ResetPasswordPage from "../pages/reset-password.page"
import AnualEmissionsPage from "../pages/annual-emissions.page"
import ReportedVoyagesPage from "../pages/reported-voyages.page"
import PortCallRecordsPage from "../pages/port-call-records.page"
import UsersPage from "../pages/users.page"
import ProfilePage from "pages/profile.page"

const privateRoutes = [
  {
    path: "/my-fleet",
    component: <MyFleetPage />,
  },
  {
    path: "/my-fleet/:shipId/emissions",
    component: <EmissionsPage />,
  },
  {
    path: "/my-fleet/:shipId/annual-emissions",
    component: <AnualEmissionsPage />,
  },
  {
    path: "/my-fleet/:shipId/reported-voyages",
    component: <ReportedVoyagesPage />,
  },
  {
    path: "/my-fleet/:shipId/port-call-records",
    component: <PortCallRecordsPage />,
  },
  
  {
    path: "/users",
    component: <UsersPage />,
  },
  {
    path: "/profile",
    component: <ProfilePage />,
  },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" replace={true} />,
  },
]

const protectedRoutes = [
  {
    path: "/login",
    component: <LoginPage />,
  },
  {
    path: "/logout",
    component: <span>Logout</span>,
  },
  {
    path: "/reset-password",
    component: <ResetPasswordPage />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPasswordPage />,
  },
  {
    path: "/register",
    component: <RegisterPage />,
  },
];

const publicRoutes = [
  {
    path: "*",
    component: <span>Not Found</span>,
  },
]

export { privateRoutes, publicRoutes, protectedRoutes  }
