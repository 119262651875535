import React from "react"
import { Route, Routes } from "react-router-dom"
import { privateRoutes, protectedRoutes, publicRoutes } from "./routes"

import {
  PrivateRouteMiddleware,
  ProtectedRouteMiddleware
} from "./routes/route"

import "./assets/scss/theme.scss"
import NonAuthLayout from "./components/NonAuthLayout"
import VerticalLayout from "./components/VerticalLayout/"

const App = () => {
  return (
    <React.Fragment>
      <Routes>
        {protectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <ProtectedRouteMiddleware>
                <NonAuthLayout>{route.component}</NonAuthLayout>
              </ProtectedRouteMiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}

        {privateRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <PrivateRouteMiddleware>
                <VerticalLayout>{route.component}</VerticalLayout>
              </PrivateRouteMiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}

        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

export default App
