import Enums from "constants/enums"
import moment from "moment"
import trimString from "utils/trim-string"
import * as yup from "yup"
import Const from "../../../constants"
import * as validations from "../../../helpers/validation-helper"
import {
  getFormattedDate,
  getFormattedDateforAPI,
} from "../../../utils/date-time"

export const FN = {
  DEPARTURE_COUNTRY: "departure_country",
  DEPARTURE_PORT: "departure_port",
  DEPARTURE_PORT_OTHER: "departure_port_other",
  DEPARTURE_DATE: "departure_date",
  DEPARTURE_TIME: "departure_time",

  ARRIVAL_COUNTRY: "arrival_country",
  ARRIVAL_PORT: "arrival_port",
  ARRIVAL_DATE: "arrival_date",
  ARRIVAL_TIME: "arrival_time",

  ADDITIONAL_NOTES: "additional_notes",

  DURATION_REGULAR_NAVIGATION: "duration_regular_navigation",
  DURATION_AT_ANCHORAGE: "duration_at_anchorage",
  DURATION_MANOEVERING: "duration_manoeuvring",
  DURATION_OFFSHORE_ACTIVITY: "duration_offshore_activity",

  DISTANCE_REGULAR_NAVIGATION: "distance_regular_navigation",
  DISTANCE_MANOEVERING: "distance_manoevering",
  DISTANCE_OFFSHORE_ACTIVITY: "distance_offshore_activity",

  VOYAGE_TYPE: "voyage_type",

  CARGO_MASS: "cargo_mass",
  CARGO_TYPE: "cargo_type",
  TRANSPORT_WORK: "transport_work",

  EMISSION: {
    ROOT: "emission",
    MODE: "mode",
    FUEL_TYPE: "fuel_type",
    FUEL_TYPE_OTHER: "fuel_type_other",
    FUEL_QUANTITY: "fuel_quantity",
    EMISSION_FACTOR: "emission_factor",
  },
}

export const VOYAGE_TYPE_LIST = [
  { label: "Loaded", value: "loaded" },
  { label: "Ballast", value: "ballast" },
]

export const EMISSION_MODE = [
  { label: "Regular Navigation", value: "regular_navigation" },
  { label: "At Anchorage", value: "at_anchorage" },
  { label: "Maneuvering", value: "manoeuvring" },
  { label: "Offshore Activity", value: "offshore_activity" },
]

export const getEmissionInitialValue = (data = {}) => {
  return {
    id: data.id || "",
    [FN.EMISSION.MODE]: data.mode,
    [FN.EMISSION.FUEL_TYPE]: data.fuel_type_id,
    [FN.EMISSION.FUEL_TYPE_OTHER]: data.fuel_type_other || "",
    [FN.EMISSION.FUEL_QUANTITY]: data.fuel_quantity || "",
    [FN.EMISSION.EMISSION_FACTOR]: data.emission_factor || "",
  }
}

export const generateInitialValues = (data = {}) => {
  return {
    [FN.DEPARTURE_COUNTRY]: data.departure_country_id,
    [FN.DEPARTURE_PORT]: data.departure_port_id,
    [FN.DEPARTURE_PORT_OTHER]: data.departure_port_other,
    [FN.DEPARTURE_DATE]: data.departure_date
      ? [moment(data.departure_date).toDate()]
      : [],
    [FN.DEPARTURE_TIME]: data.departure_time
      ? [moment(data.departure_time).toDate()]
      : [],

    [FN.ARRIVAL_COUNTRY]: Const.COUNTRIES.IND.VALUE,
    [FN.ARRIVAL_PORT]: data.arrival_port_id,
    [FN.ARRIVAL_DATE]: data.arrival_date
      ? [moment(data.arrival_date).toDate()]
      : [],
    [FN.ARRIVAL_TIME]: data.arrival_time
      ? [moment(data.arrival_time).toDate()]
      : [],

    [FN.ADDITIONAL_NOTES]: data.additional_notes,

    [FN.DURATION_REGULAR_NAVIGATION]: data.duration_regular_navigation,
    [FN.DURATION_AT_ANCHORAGE]: data.duration_at_anchorage,
    [FN.DURATION_MANOEVERING]: data.duration_manoeuvring,
    [FN.DURATION_OFFSHORE_ACTIVITY]: data.duration_offshore_activity,

    [FN.DISTANCE_REGULAR_NAVIGATION]: data.distance_regular_navigation,
    [FN.DISTANCE_MANOEVERING]: data.distance_manoeuvring,
    [FN.DISTANCE_OFFSHORE_ACTIVITY]: data.distance_offshore_activity,

    [FN.VOYAGE_TYPE]: data.voyage_type,

    [FN.CARGO_MASS]: data.cargo_mass,
    [FN.CARGO_TYPE]: data.cargo_type,
    [FN.TRANSPORT_WORK]: data.transport_work, // TODO: readonly calculated data

    [FN.EMISSION.ROOT]: (data.actual_emissions || [{}]).map(item => {
      return getEmissionInitialValue(item)
    }),
  }
}

export const generateValidationSchema = () => {
  return yup.lazy(values => {
    const isCountryIndia =
      values[FN.DEPARTURE_COUNTRY] === Const.COUNTRIES.IND.VALUE
    const isVoyageTypeBallast = values[FN.VOYAGE_TYPE] === "ballast"

    return yup.object().shape({
      [FN.DEPARTURE_COUNTRY]: validations.isRequiredString(),
      [FN.DEPARTURE_PORT]: validations.isRequiredString({
        withRequired: isCountryIndia,
        nullable: true,
      }),
      [FN.DEPARTURE_PORT_OTHER]: validations.isRequiredString({
        withRequired: !isCountryIndia,
        nullable: true,
      }),
      [FN.DEPARTURE_DATE]: validations.isRequiredDate(),
      [FN.DEPARTURE_TIME]: validations.isRequiredDate(),

      [FN.ARRIVAL_COUNTRY]: validations.isRequiredString(),
      [FN.ARRIVAL_PORT]: validations.isRequiredString(),
      [FN.ARRIVAL_DATE]: validations.isRequiredDate(),
      [FN.ARRIVAL_TIME]: validations.isRequiredDate(),

      [FN.DURATION_REGULAR_NAVIGATION]: validations.isRequiredString(),

      [FN.DISTANCE_REGULAR_NAVIGATION]: validations.isRequiredString(),

      [FN.VOYAGE_TYPE]: validations.isRequiredString(),

      [FN.CARGO_MASS]: validations.isRequiredString({
        withRequired: !isVoyageTypeBallast,
      }),
      [FN.CARGO_TYPE]: validations.isRequiredString({
        withRequired: !isVoyageTypeBallast,
      }),

      [FN.EMISSION.ROOT]: yup.array().of(
        yup.lazy(values => {
          const isFuelTypeOther =
            values[FN.EMISSION.FUEL_TYPE] === Enums.OTHER_OPTION.value

          return yup.object().shape({
            [FN.EMISSION.FUEL_TYPE]: validations.isRequiredString({
              nullable: true,
            }),
            [FN.EMISSION.FUEL_TYPE_OTHER]: validations.isRequiredString({
              withRequired: isFuelTypeOther,
            }),
            [FN.EMISSION.FUEL_QUANTITY]: validations.isRequiredString(),
            [FN.EMISSION.MODE]: validations.isRequiredString(),
            [FN.EMISSION.EMISSION_FACTOR]: validations.isRequiredString({
              withRequired: isFuelTypeOther,
            }),
          })
        })
      ),
    })
  })
}

export const generatePayload = formData => {
  return {
    type: "voyage",

    departure_country_id: Number(formData[FN.DEPARTURE_COUNTRY]) || null,
    departure_port_id: Number(formData[FN.DEPARTURE_PORT]) || null,
    departure_port_other: trimString(formData[FN.DEPARTURE_PORT_OTHER]),
    departure_date: getFormattedDateforAPI(formData[FN.DEPARTURE_DATE]),
    departure_time: getFormattedDate(formData[FN.DEPARTURE_TIME], "hh:mm"),

    arrival_country_id: Number(formData[FN.ARRIVAL_COUNTRY]) || null,
    arrival_port_id: Number(formData[FN.ARRIVAL_PORT]) || null,
    arrival_date: getFormattedDateforAPI(formData[FN.ARRIVAL_DATE]),
    arrival_time: getFormattedDate(formData[FN.ARRIVAL_DATE], "hh:mm"),

    additional_notes: trimString(formData[FN.ADDITIONAL_NOTES]),

    duration_regular_navigation: Number(formData[FN.DURATION_REGULAR_NAVIGATION]),
    duration_at_anchorage: Number(formData[FN.DURATION_AT_ANCHORAGE]),
    duration_manoeuvring: Number(formData[FN.DURATION_MANOEVERING]),
    duration_offshore_activity: Number(formData[FN.DURATION_OFFSHORE_ACTIVITY]),

    distance_regular_navigation: Number(formData[FN.DISTANCE_REGULAR_NAVIGATION]),
    distance_manoeuvring: Number(formData[FN.DISTANCE_MANOEVERING]),
    distance_offshore_activity: Number(formData[FN.DISTANCE_OFFSHORE_ACTIVITY]),

    voyage_type: formData[FN.VOYAGE_TYPE],

    cargo_mass: Number(formData[FN.CARGO_MASS]) || null,
    cargo_type: trimString(formData[FN.CARGO_TYPE]) || null,
    transport_work: Number(formData[FN.TRANSPORT_WORK]) || null,

    actual_emissions: formData[FN.EMISSION.ROOT].map(actEm => {
      const isFuelTypeOther =
        actEm[FN.EMISSION.FUEL_TYPE] === Enums.OTHER_OPTION.value

      const item = {
        fuel_type_id: Number(actEm[FN.EMISSION.FUEL_TYPE]) || null,
        fuel_type_other: isFuelTypeOther
          ? trimString(actEm[FN.EMISSION.FUEL_TYPE_OTHER])
          : null,
        fuel_quantity: Number(actEm[FN.EMISSION.FUEL_QUANTITY]),
        emission_factor: Number(actEm[FN.EMISSION.EMISSION_FACTOR]),
        mode: actEm[FN.EMISSION.MODE],
      }
      if (actEm.id) {
        item.id = actEm.id
      }
      return item
    }),
  }
}
