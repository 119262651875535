import * as React from "react"

function CalendarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={18}
      viewBox="0 0 20 18"
      fill="none"
      {...props}
    >
      <path
        d="M15.5 13.161V3.645a2 2 0 00-2-2h-11a2 2 0 00-2 2v2.387m15 7.13L12.5 17m3-3.839h-1a2 2 0 00-2 2V17m0 0h-10a2 2 0 01-2-2V6.032m0 0h13"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M2.306 9.856c.016-.428.156-.758.42-.99.264-.232.622-.348 1.074-.348.3 0 .556.052.768.156a1.108 1.108 0 01.654 1.032c0 .264-.066.48-.198.648a1.009 1.009 0 01-.462.336v.024c.228.076.408.202.54.378.132.176.198.402.198.678 0 .248-.058.468-.174.66a1.151 1.151 0 01-.498.444 1.713 1.713 0 01-.774.162c-.48 0-.864-.118-1.152-.354-.284-.236-.434-.592-.45-1.068h.996a.617.617 0 00.15.42c.096.1.236.15.42.15.156 0 .276-.044.36-.132a.5.5 0 00.132-.36c0-.192-.062-.33-.186-.414-.12-.088-.314-.132-.582-.132H3.35v-.834h.192c.204 0 .368-.034.492-.102.128-.072.192-.198.192-.378a.454.454 0 00-.12-.336.446.446 0 00-.33-.12c-.152 0-.266.046-.342.138a.632.632 0 00-.126.342H2.306zm5.763-.054a.601.601 0 00-.168-.33c-.08-.076-.194-.114-.342-.114a.51.51 0 00-.492.3c-.1.196-.152.508-.156.936a.899.899 0 01.378-.33c.168-.08.352-.12.552-.12.392 0 .706.124.942.372.24.248.36.594.36 1.038 0 .292-.06.548-.18.768-.116.22-.288.392-.516.516-.224.124-.49.186-.798.186-.616 0-1.044-.192-1.284-.576-.24-.388-.36-.944-.36-1.668 0-.76.126-1.324.378-1.692.256-.372.664-.558 1.224-.558.3 0 .552.06.756.18.208.116.364.27.468.462.108.192.172.402.192.63h-.954zm-.474 1.152a.623.623 0 00-.432.156c-.112.1-.168.244-.168.432 0 .192.052.342.156.45.108.108.26.162.456.162a.547.547 0 00.414-.156.605.605 0 00.156-.438c0-.188-.05-.336-.15-.444-.1-.108-.244-.162-.432-.162zm5.212-1.44h-1.872v.828a.872.872 0 01.336-.216c.144-.056.3-.084.468-.084.3 0 .548.068.744.204.2.136.346.312.438.528.092.216.138.45.138.702 0 .468-.132.84-.396 1.116-.264.272-.636.408-1.116.408-.32 0-.598-.054-.834-.162a1.298 1.298 0 01-.546-.462 1.33 1.33 0 01-.21-.678h1.002a.554.554 0 00.174.312c.092.08.218.12.378.12.188 0 .328-.06.42-.18.092-.12.138-.28.138-.48 0-.196-.048-.346-.144-.45-.096-.104-.236-.156-.42-.156a.533.533 0 00-.336.102.448.448 0 00-.174.258h-.99v-2.61h2.802v.9z"
        fill="currentColor"
      />
      <rect
        x={3.35}
        y={0.35}
        width={1.3}
        height={3.6871}
        rx={0.65}
        fill="#fff"
        stroke="currentColor"
        strokeWidth={0.7}
      />
      <rect
        x={11.35}
        y={0.35}
        width={1.3}
        height={3.6871}
        rx={0.65}
        fill="#fff"
        stroke="currentColor"
        strokeWidth={0.7}
      />
    </svg>
  )
}

export default CalendarIcon
