import { useAsync } from "@react-org/hooks"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import * as ShipsApi from "../../apis/ships.api"
import generateContext from "../../utils/generate-context"

function useAnnualEmissionPage() {
  const urlParams = useParams()

  const listRef = useRef()
  const [columns, setColumns] = useState([])

  const [sortBy, setSortBy] = useState("reporting_period")
  const [sortOrder, setSortOrder] = useState("desc")

  const breadcrumb = useMemo(
    () => [
      {
        label: "My Fleet",
        onClick: () => {},
      },
    ],
    []
  )

  const getShipQuery = useAsync(
    useCallback(
      payload => {
        return ShipsApi.getShipById({ shipId: urlParams?.shipId, ...payload })
      },
      [urlParams?.shipId]
    )
  )

  const getAnnualEmissionsQuery = useAsync(ShipsApi.getShipAnnualEmissions, {
    intialState: { data: [] },
    immediate: false,
  })

  const isColumnSorted = useCallback(
    columnId => {
      return sortBy === columnId
    },
    [sortBy]
  )

  const fetchEmissions = useCallback(
    data => {
      const { page = 1, sort = sortBy, order = sortOrder } = data

      if (page === 1) {
        const getAnnualEmissionsQuerySetData =
          getAnnualEmissionsQuery.setQueryData
        getAnnualEmissionsQuerySetData(null)
      }
      const getAnnualEmissionsQueryCancel = getAnnualEmissionsQuery.cancel
      getAnnualEmissionsQueryCancel()

      const getAnnualEmissionsQueryExecute = getAnnualEmissionsQuery.execute
      getAnnualEmissionsQueryExecute({
        shipId: urlParams?.shipId,
        params: {
          page,
          sort: sort ? `${sort}:${order}` : "reporting_period:desc",
        },
      })
    },
    [
      getAnnualEmissionsQuery.cancel,
      getAnnualEmissionsQuery.execute,
      getAnnualEmissionsQuery.setQueryData,
      sortBy,
      sortOrder,
      urlParams?.shipId,
    ]
  )

  const handleThreeDotMenuClick = useCallback((action, event, data) => {
    console.log({ action, event })
  }, [])

  const handleSortChange = useCallback(
    (order, column) => {
      let sortBy = order === "" ? "" : column.id
      setSortBy(sortBy)
      setSortOrder(order)
      fetchEmissions({ page: 1, sort: sortBy, order: order })
    },
    [fetchEmissions]
  )

  useEffect(() => {
    const columns = [
      {
        id: "reporting_period",
        accessor: "reporting_period",
        header: "Reporting Period",
        width: "18%",
        sortOrder: isColumnSorted("reporting_period") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "distance_travelled",
        header: "Distance Travelled (n miles)",
        accessor: "distance_travelled",
        sortOrder: isColumnSorted("distance_travelled") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "time_at_sea",
        header: "Time at Sea (hours)",
        accessor: "time_at_sea",
        sortOrder: isColumnSorted("time_at_sea") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "co2_emission",
        header: "CO2 Emissions (m tonnes)",
        accessor: "co2_emission",
        sortOrder: isColumnSorted("co2_emission") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
    ]
    setColumns(columns)
  }, [handleSortChange, isColumnSorted, sortOrder])

  useEffect(() => {
    if (
      urlParams?.shipId &&
      getShipQuery?.isComplete &&
      getAnnualEmissionsQuery?.isIdle &&
      !getAnnualEmissionsQuery?.isPending
    ) {
      fetchEmissions({ page: 1 })
    }
  }, [
    fetchEmissions,
    getAnnualEmissionsQuery?.isIdle,
    getAnnualEmissionsQuery?.isPending,
    getShipQuery?.isComplete,
    urlParams?.shipId,
  ])

  return useMemo(() => {
    return {
      getShipQuery,
      getAnnualEmissionsQuery,
      listRef,
      columns,
      breadcrumb,
      handleThreeDotMenuClick,
    }
  }, [
    breadcrumb,
    columns,
    getAnnualEmissionsQuery,
    getShipQuery,
    handleThreeDotMenuClick,
  ])
}

export const [AnnualEmissionPageProvider, useAnnualEmissionPageContext] =
  generateContext(useAnnualEmissionPage)
