import { withConfig, getApi } from "./index"
const api = getApi("users")

export const getUsers = withConfig((data, conf) => {
  const { params } = data
  const config = conf({
    url: "/",
    method: "GET",
    params: params,
  })
  return api(config)
})


export const addUser = withConfig((data, conf) => {
  const { body } = data
  const config = conf({
    url: "/",
    method: "POST",
    data: body,
  })
  return api(config)
})

export const updateUser = withConfig((data, conf) => {
  const { userId, body } = data
  const config = conf({
    url: `/${userId}`,
    method: "PUT",
    data: body,
  })
  return api(config)
})

export const deleteUser = withConfig((data, conf) => {
  const { userId } = data
  const config = conf({
    url: `/${userId}`,
    method: "DELETE",
  })
  return api(config)
})