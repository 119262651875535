import Input from "components/FormControls/input"
import React, { useCallback } from "react"
import cx from "utils/cx"
import is from "utils/is"
import List from "../list"

const VirtualizeTable = React.forwardRef(function VirtualizeTable(props, ref) {
  const {
    columns,
    isLoadMoreDirty,
    loadingRowCount,
    isLoading,
    rows,
    hasMore,
    loadMore,
    renderCell: _renderCell,
    renderHeaderCell: _renderHeaderCell,
    renderSkeletonCell: _renderSkeletonCell,
    renderNoDataRows,
    onRowClick,
    ...restProps
  } = props

  const renderHeaderCell = useCallback(
    column => {
      if (_renderHeaderCell) return _renderHeaderCell(column)
      return column.header
    },
    [_renderHeaderCell]
  )

  const getColumnWidth = useCallback(
    column => {
      if (column.width) return column.width
      let columnWithWidth = columns
        .filter(col => col.width)
        .map(col => col.width)
      let totalWidth = columnWithWidth.join(" + ")

      let columnsWithoutWidth = columns.length - columnWithWidth.length

      let width = `calc((100% - (${totalWidth})) / ${columnsWithoutWidth})`
      return width
    },
    [columns]
  )

  const renderCell = useCallback(
    data => {
      const { row, rowIndex, column, columnIndex } = data
      if (column.renderCell) return column.renderCell(data)
      if (_renderCell) return _renderCell(data)
      let value = ""
      if (is.function(column.accessor)) {
        value = column.accessor({row,column})
      }else if(column.accessor){
        value = row[column.accessor]
      }
      return <div key={columnIndex}>{value}</div>
    },
    [_renderCell]
  )

  const handleRowClick = useCallback(
    data => () => {
      onRowClick && onRowClick(data)
    },
    [onRowClick]
  )

  const renderRow = useCallback(
    props => {
      const { style, key, row, index: rowIndex } = props
      return (
        <div
          className={cx(
            "virtualize-table_row",
            onRowClick && "virtualize-table_row-clickable"
          )}
          key={key}
          style={style}
          onClick={handleRowClick(props)}
        >
          {columns.map((column, columnIndex) => {
            return (
              <div
                key={columnIndex}
                className="virtualize-table_cell"
                style={{ width: getColumnWidth(column) }}
              >
                {renderCell({ row, rowIndex, column, columnIndex })}
              </div>
            )
          })}
        </div>
      )
    },
    [columns, getColumnWidth, handleRowClick, onRowClick, renderCell]
  )

  const renderSkeletonCell = useCallback(
    props => {
      if (_renderSkeletonCell) return _renderSkeletonCell(props)
      const { row, rowIndex, column, columnIndex } = props
      return (
        <div key={columnIndex} className="placeholder-glow">
          <div className="placeholder col-12 bg-secondary"></div>
        </div>
      )
    },
    [_renderSkeletonCell]
  )

  const renderSkeletonRow = useCallback(() => {
    const { style, row, index: rowIndex } = props
    return (
      <div key={rowIndex} style={style} className="virtualize-table_row">
        {columns.map((column, columnIndex) => {
          return (
            <div
              key={columnIndex}
              className="virtualize-table_cell"
              style={{ width: getColumnWidth(column) }}
            >
              {renderSkeletonCell({ row, rowIndex, column, columnIndex })}
            </div>
          )
        })}
      </div>
    )
  }, [columns, getColumnWidth, props, renderSkeletonCell])
  
  const handleSortClick = useCallback((column,columnIndex)=>(e)=>{
    let order = "";
    if(column.sortOrder === 'asc'){
      order = "desc";
    }else if(column.sortOrder === "desc"){
      order = "";
    }else if(column.sortOrder === ""){
      order = "asc";
    }
    if(column.onSortChange) column.onSortChange(order,column)
  },[])

  const renderHeaderRow = useCallback(() => {
    return (
      <div className="virtualize-table_header"> 
        {columns.map((column, columnIndex) => {
          const isSortable = column.sort !== false
          const isSearchable = column.search === true
          return (
            <div
              key={columnIndex}
              className="virtualize-table_cell"
              style={{ width: getColumnWidth(column) }}
            >
              <div className="virtualize-table_sortwrapper" onClick={handleSortClick(column,columnIndex)}>
                {renderHeaderCell(column, columnIndex)}
                {isSortable && (
                  <div className="virtualize-table_sort">
                    <i className={cx("bx bx-sort", column.sortOrder==='asc' && 'bx-sort-up active',column.sortOrder==='desc' && 'bx-sort-down active', )}></i>
                  </div>
                )}
              </div>
              {isSearchable && (
                <Input
                  placeholder="Search"
                  type="text"
                  className="virtualize-table_search"
                />
              )}
            </div>
          )
        })}
      </div>
    )
  }, [columns, getColumnWidth, handleSortClick, renderHeaderCell])

  return (
    <div className="virtualize-table">
      {renderHeaderRow()}
      <div className="virtualize-table_body">
        <List
          ref={ref}
          rows={rows}
          renderRow={renderRow}
          renderSkeletonRow={renderSkeletonRow}
          hasMore={hasMore}
          loadMore={loadMore}
          isLoading={isLoading}
          loadingRowCount={loadingRowCount}
          isLoadMoreDirty={isLoadMoreDirty}
          renderNoRows={renderNoDataRows}
          {...restProps}
        />
      </div>
    </div>
  )
})

export default VirtualizeTable
