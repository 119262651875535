import { useAsync } from "@react-org/hooks"
import { useNotificationModalContext } from "../../components/notification-modal/provider"
import moment from "moment"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import is from "utils/is"
import * as ShipsApi from "../../apis/ships.api"
import generateContext from "../../utils/generate-context"
import debounce from "utils/debounce"
import paginateSelector from "utils/paginate-selector"
import ThreeDotMenu from "./three-dot-menu"
import cx from "utils/cx"

function useEmissionPage() {
  const notificationModal = useNotificationModalContext()
  const urlParams = useParams()

  const listRef = useRef()
  const [columns, setColumns] = useState([])
  const [activeMenuRowIndex, setActiveMenuRowIndex] = useState(null)
  const [isAddPortEmissionModalOpen, setIsAddPortEmissionModalOpen] =
    useState(false)
  const [isAddVoyageEmissionModalOpen, setIsAddVoyageEmissionModalOpen] =
    useState(false)

  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState("reporting_period")
  const [sortOrder, setSortOrder] = useState("desc")

  const breadcrumb = useMemo(
    () => [
      {
        label: "My Fleet",
        onClick: () => {},
      },
    ],
    []
  )

  const getShipQuery = useAsync(
    useCallback(
      payload => {
        return ShipsApi.getShipById({ shipId: urlParams?.shipId, ...payload })
      },
      [urlParams?.shipId]
    )
  )

  const getEmissionsQuery = useAsync(ShipsApi.getShipEmissions, {
    intialState: { data: [] },
    immediate: false,
    infinite: true,
    select: paginateSelector,
  })

  const addShipEmissionsQuery = useAsync(ShipsApi.addShipEmissions, {
    immediate: false,
  })
  const updateShipEmissionsQuery = useAsync(ShipsApi.updateShipEmission, {
    immediate: false,
  })
  const deleteShipEmissionsQuery = useAsync(ShipsApi.deleteShipEmission, {
    immediate: false,
  })

  const isHasMorePages = useMemo(() => {
    if (getEmissionsQuery.isPending) return true
    return (
      getEmissionsQuery?.paginate?.page_no *
        getEmissionsQuery?.paginate?.page_limit <
      getEmissionsQuery?.paginate?.total
    )
  }, [
    getEmissionsQuery.isPending,
    getEmissionsQuery?.paginate?.page_limit,
    getEmissionsQuery?.paginate?.page_no,
    getEmissionsQuery?.paginate?.total,
  ])

  const isColumnSorted = useCallback(
    columnId => {
      return sortBy === columnId
    },
    [sortBy]
  )

  const fetchEmissions = useCallback(
    data => {
      const { page = 1, term = search, sort = sortBy, order = sortOrder } = data

      if (page === 1) {
        const getEmissionsQuerySetData = getEmissionsQuery.setQueryData
        getEmissionsQuerySetData(null)
      }
      const getEmissionsQueryCancel = getEmissionsQuery.cancel
      getEmissionsQueryCancel()

      const getEmissionsQueryExecute = getEmissionsQuery.execute
      getEmissionsQueryExecute({
        shipId: urlParams?.shipId,
        params: {
          page,
          term,
          sort: sort ? `${sort}:${order}` : "created_date:desc",
        },
      })
    },
    [
      getEmissionsQuery.cancel,
      getEmissionsQuery.execute,
      getEmissionsQuery.setQueryData,
      search,
      sortBy,
      sortOrder,
      urlParams?.shipId,
    ]
  )

  const loadingRowCount = useCallback(() => {
    if (getEmissionsQuery?.isIdle || getEmissionsQuery?.data?.length === 0) {
      return 10
    }
    return isHasMorePages ? 2 : 0
  }, [
    getEmissionsQuery?.data?.length,
    getEmissionsQuery?.isIdle,
    isHasMorePages,
  ])

  const handleLoadMore = useCallback(() => {
    if (
      getEmissionsQuery?.isPending ||
      getEmissionsQuery?.isIdle ||
      !isHasMorePages
    )
      return
    let page = (getEmissionsQuery?.paginate?.page_no || 0) + 1
    fetchEmissions({ page })
  }, [
    fetchEmissions,
    getEmissionsQuery?.isIdle,
    getEmissionsQuery?.isPending,
    getEmissionsQuery?.paginate?.page_no,
    isHasMorePages,
  ])

  const handleOnAddPortEmissionClick = useCallback(() => {
    setActiveMenuRowIndex(null)
    setIsAddPortEmissionModalOpen(true)
  }, [])

  const handleCloseAddPortEmissionClick = useCallback(() => {
    setActiveMenuRowIndex(null)
    setIsAddPortEmissionModalOpen(false)
  }, [])

  const handleOnAddVoyageEmissionClick = useCallback(() => {
    setActiveMenuRowIndex(null)
    setIsAddVoyageEmissionModalOpen(true)
  }, [])

  const handleCloseAddVoyageEmissionClick = useCallback(() => {
    setActiveMenuRowIndex(null)
    setIsAddVoyageEmissionModalOpen(false)
  }, [])

  const editEmission = useCallback(
    formData => {
      const activeRow = getEmissionsQuery.data[activeMenuRowIndex]
      notificationModal.progress({
        heading: "Updating Emission",
      })
      const { type, id, ...restFormData } = formData
      const payload = {
        shipId: urlParams?.shipId,
        emissionId: activeRow.id,
        body: restFormData,
      }
      const updateShipEmissionsQueryExecute = updateShipEmissionsQuery.execute
      updateShipEmissionsQueryExecute(payload, {
        onSuccess: res => {
          notificationModal.close()
          setActiveMenuRowIndex(null)
          setIsAddVoyageEmissionModalOpen(false)
          setIsAddPortEmissionModalOpen(false)
          fetchEmissions({ page: 1 })
        },
        onError: err => {
          if (err?.response?.status === 409) {
            notificationModal.error({
              title: "Conflict",
              heading: "Emission is already added for the given date",
              onClose: () => {
                console.log("closed")
              },
            })
          } else {
            notificationModal.close()
          }
        },
      })
    },
    [
      activeMenuRowIndex,
      fetchEmissions,
      getEmissionsQuery.data,
      notificationModal,
      updateShipEmissionsQuery.execute,
      urlParams?.shipId,
    ]
  )

  const addEmission = useCallback(
    formData => {
      notificationModal.progress({
        heading: "Adding Emission",
      })
      const payload = {
        shipId: urlParams?.shipId,
        body: formData,
      }
      const addShipEmissionsQueryExecute = addShipEmissionsQuery.execute
      addShipEmissionsQueryExecute(payload, {
        onSuccess: () => {
          notificationModal.close()
          setIsAddVoyageEmissionModalOpen(false)
          setIsAddPortEmissionModalOpen(false)
          fetchEmissions({ page: 1 })
        },
        onError: err => {
          if (err?.response?.status === 409) {
            notificationModal.error({
              title: "Conflict",
              heading: "Emission is already added for the given date",
              onClose: () => {
                console.log("closed")
              },
            })
          } else {
            // TODO: manage generic error
            notificationModal.close()
          }
        },
      })
    },
    [
      addShipEmissionsQuery.execute,
      fetchEmissions,
      notificationModal,
      urlParams?.shipId,
    ]
  )

  const handleAddPortEmissionSubmit = useCallback(
    formData => {
      if (!is.undefined(activeMenuRowIndex) && !is.null(activeMenuRowIndex)) {
        return editEmission(formData)
      }
      return addEmission(formData)
    },
    [activeMenuRowIndex, addEmission, editEmission]
  )

  const handleAddVoyageEmissionSubmit = useCallback(
    formData => {
      if (!is.undefined(activeMenuRowIndex) && !is.null(activeMenuRowIndex)) {
        return editEmission(formData)
      }
      return addEmission(formData)
    },
    [activeMenuRowIndex, addEmission, editEmission]
  )

  const handleEmissionEditClick = useCallback((event, data = {}) => {
    const { rowIndex, row } = data
    setActiveMenuRowIndex(rowIndex)
    if (row.type === "port") {
      setIsAddPortEmissionModalOpen(true)
    } else if (row.type === "voyage") {
      setIsAddVoyageEmissionModalOpen(true)
    }
  }, [])

  const handleDeleteEmissionClick = useCallback(
    (event, data) => {
      const { rowIndex, row } = data
      setActiveMenuRowIndex(rowIndex)
      notificationModal.warning({
        confirmText: "Delete",
        canceltext: "Close",
        title: "Are you sure?",
        heading:
          "Are you sure you want to delete this record? This action cannot be undone.",
        onCancel: () => {
          setActiveMenuRowIndex(null)
        },
        onConfirm: () => {
          notificationModal.progress({ heading: "Deleting Emission" })
          setActiveMenuRowIndex(null)
          const deleteShipEmissionsQueryExecute =
            deleteShipEmissionsQuery.execute
          deleteShipEmissionsQueryExecute(
            { emissionId: row.id, shipId: urlParams?.shipId },
            {
              onSuccess: () => {
                notificationModal.close()
                setActiveMenuRowIndex(null)
                fetchEmissions({ page: 1 })
              },
              onError: () => {
                notificationModal.error({
                  heading: "Failed to delete Emission",
                  onClose: () => {
                    setActiveMenuRowIndex(null)
                  },
                })
              },
            }
          )
        },
      })
    },
    [
      deleteShipEmissionsQuery.execute,
      fetchEmissions,
      notificationModal,
      urlParams?.shipId,
    ]
  )

  const handleThreeDotMenuClick = useCallback(
    (action, event, data) => {
      switch (action) {
        case "edit": {
          handleEmissionEditClick(event, data)
          break
        }
        case "delete": {
          handleDeleteEmissionClick(event, data)
          break
        }
        default: {
          // do nothing
        }
      }
    },
    [handleDeleteEmissionClick, handleEmissionEditClick]
  )

  const handleSortChange = useCallback(
    (order, column) => {
      let sortBy = order === "" ? "" : column.id
      setSortBy(sortBy)
      setSortOrder(order)
      fetchEmissions({ page: 1, sort: sortBy, order: order })
    },
    [fetchEmissions]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(value => {
      fetchEmissions({ page: 1, term: value })
    }, 500),
    []
  )

  const handleSearchChange = useCallback(
    e => {
      setSearch(e.target.value)
      handleSearch(e.target.value)
    },
    [handleSearch]
  )

  useEffect(() => {
    const columns = [
      {
        id: "type",
        header: "Type",
        width: "150px",
        accessor: ({ row }) => (
          <span className={cx("text-capitalize emission-type",row.type)}>{row?.type}</span>
        ),
        sortOrder: isColumnSorted("type") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "port",
        header: "Port",
        width: "30%",
        accessor: ({ row }) => {
          if (row.type === "port") {
            return row?.arrival_port?.name || row?.arrival_port_other
          }
          return (
            <div>
              <div>
                <span>Arr.</span>
                <span>
                  - {row?.arrival_port?.name || row?.arrival_port_other}
                </span>
              </div>
              <div>
                <span>Dept.</span>
                <span>
                  - {row?.departure_port?.name || row?.departure_port_other}
                </span>
              </div>
            </div>
          )
        },
        sortOrder: isColumnSorted("port") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "ata",
        header: "ATA",
        accessor: ({ row, column }) => {
          const date = moment(row.arrival_date).format("DD-MM-YYYY")
          const time = moment(row.arrival_time).format("HH:mm")
          return (
            <div>
              <span>{date}</span> <span>{time}</span>
            </div>
          )
        },
        sortOrder: isColumnSorted("ata") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "atd",
        header: "ATD",
        accessor: ({ row, column }) => {
          const date = moment(row.departure_date).format("DD-MM-YYYY")
          const time = moment(row.departure_time).format("HH:mm")
          return (
            <div>
              <span>{date}</span> <span>{time}</span>
            </div>
          )
        },
        sortOrder: isColumnSorted("atd") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "co2_emission",
        header: "CO2 Emissions (m tonnes)",
        accessor: ({ row, column }) => {
          return row.total_emissions;
        },
        sortOrder: isColumnSorted("co2_emission") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        header: "",
        width: "24px",
        search: false,
        sort: false,
        renderCell: data => {
          return <ThreeDotMenu data={data} />
        },
      },
    ]
    setColumns(columns)
  }, [handleSortChange, isColumnSorted, sortOrder])

  useEffect(() => {
    if (
      urlParams?.shipId &&
      getShipQuery?.isComplete &&
      getEmissionsQuery?.isIdle &&
      !getEmissionsQuery?.isPending
    ) {
      fetchEmissions({ page: 1 })
    }
  }, [
    fetchEmissions,
    getEmissionsQuery?.isIdle,
    getEmissionsQuery?.isPending,
    getShipQuery?.isComplete,
    urlParams?.shipId,
  ])

  return useMemo(() => {
    return {
      getShipQuery,
      getEmissionsQuery,
      listRef,
      columns,
      isHasMorePages,
      loadingRowCount,
      handleLoadMore,
      breadcrumb,
      isAddPortEmissionModalOpen,
      setIsAddPortEmissionModalOpen,
      isAddVoyageEmissionModalOpen,
      setIsAddVoyageEmissionModalOpen,
      handleOnAddPortEmissionClick,
      handleCloseAddPortEmissionClick,
      handleAddPortEmissionSubmit,
      handleOnAddVoyageEmissionClick,
      handleCloseAddVoyageEmissionClick,
      handleAddVoyageEmissionSubmit,
      handleThreeDotMenuClick,
      activeMenuRowIndex,
      search,
      handleSearch,
      handleSearchChange,
    }
  }, [
    activeMenuRowIndex,
    breadcrumb,
    columns,
    getEmissionsQuery,
    getShipQuery,
    handleAddPortEmissionSubmit,
    handleAddVoyageEmissionSubmit,
    handleCloseAddPortEmissionClick,
    handleCloseAddVoyageEmissionClick,
    handleLoadMore,
    handleOnAddPortEmissionClick,
    handleOnAddVoyageEmissionClick,
    handleSearch,
    handleSearchChange,
    handleThreeDotMenuClick,
    isAddPortEmissionModalOpen,
    isAddVoyageEmissionModalOpen,
    isHasMorePages,
    loadingRowCount,
    search,
  ])
}

export const [EmissionPageProvider, useEmissionPageContext] =
  generateContext(useEmissionPage)
