import React from "react"
import NoRecordsImg from "../../assets/images/no-records.svg"

export default function NoRecords(props) {
  return (
    <div className="no-records d-flex flex-column align-items-center">
      <div className="no-records-icon">
        <img src={NoRecordsImg} alt="" />
      </div>
      <div className="no-records-message mt-3">{props.message}</div>
    </div>
  )
}
