import { ButtonGroup, Button } from "reactstrap"
import React from "preact/compat"
import FormError from "./form-error"
import { useField, useFormikContext } from "formik"
import cx from "utils/cx"

const ButtonGroupRadio = function ButtonGroupRadio(props) {
  const {
    value,
    onChange,
    options,
    labelString,
    valueString,
    error,
    classNames={},
    ...restProps
  } = props

  const isActive = option => {
    let valueToCompare = valueString ? option[valueString] : option
    return valueToCompare === value
  }

  const handleOnClick = option => {
    let valueToPass = valueString ? option[valueString] : option
    onChange && onChange(valueToPass)
  }

  return (
    <ButtonGroup className={cx("button-group-radio",classNames.container)} {...restProps}>
      {options.map(option => {
        const labelToShow = labelString ? option[labelString] : option
        return (
          <Button
            key={labelToShow}
            color="success"
            outline
            onClick={() => handleOnClick(option)}
            active={isActive(option)}
            className={classNames.item}
          >
            {labelToShow}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

export function FormikButtonGroupRadio(props) {
  const { name, onChange, onBlur, ...restProps } = props
  const [field, meta, { setValue }] = useField(name)
  const { submitCount } = useFormikContext()
  const isError = submitCount > 0 && !!meta.error
  const helperText = (submitCount > 0 && meta.error) || ""

  const handleOnChange = value => {
    setValue(value)
    onChange && onChange(value)
  }

  return (
    <>
      <ButtonGroupRadio
        error={isError}
        {...restProps}
        {...field}
        onChange={handleOnChange}
      />
      <FormError show={isError} message={helperText} />
    </>
  )
}

export default ButtonGroupRadio
