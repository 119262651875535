import { useAsync } from "@react-org/hooks"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import * as ShipsApi from "../../apis/ships.api"
import generateContext from "../../utils/generate-context"

function useReportedVoyagesPage() {
  const urlParams = useParams()

  const listRef = useRef()
  const [columns, setColumns] = useState([])

  const [sortBy, setSortBy] = useState("reporting_period")
  const [sortOrder, setSortOrder] = useState("desc")

  const breadcrumb = useMemo(
    () => [
      {
        label: "My Fleet",
        onClick: () => {},
      },
    ],
    []
  )

  const getShipQuery = useAsync(
    useCallback(
      payload => {
        return ShipsApi.getShipById({ shipId: urlParams?.shipId, ...payload })
      },
      [urlParams?.shipId]
    )
  )

  const getVoyageReportQuery = useAsync(ShipsApi.getShipVoyageReport, {
    immediate: false,
  })

  const isColumnSorted = useCallback(
    columnId => {
      return sortBy === columnId
    },
    [sortBy]
  )

  const fetchVoyageReport = useCallback(
    data => {
      const { page = 1, sort = sortBy, order = sortOrder } = data

      if (page === 1) {
        const getVoyageReportQuerySetData = getVoyageReportQuery.setQueryData
        getVoyageReportQuerySetData(null)
      }
      const getVoyageReportQueryCancel = getVoyageReportQuery.cancel
      getVoyageReportQueryCancel()

      const getVoyageReportQueryExecute = getVoyageReportQuery.execute
      getVoyageReportQueryExecute({
        shipId: urlParams?.shipId,
        params: {
          page,
          sort: sort ? `${sort}:${order}` : "reporting_period:desc",
        },
      })
    },
    [
      getVoyageReportQuery.cancel,
      getVoyageReportQuery.execute,
      getVoyageReportQuery.setQueryData,
      sortBy,
      sortOrder,
      urlParams?.shipId,
    ]
  )

  const handleThreeDotMenuClick = useCallback((action, event, data) => {
    console.log({ action, event })
  }, [])

  const handleSortChange = useCallback(
    (order, column) => {
      let sortBy = order === "" ? "" : column.id
      setSortBy(sortBy)
      setSortOrder(order)
      fetchVoyageReport({ page: 1, sort: sortBy, order: order })
    },
    [fetchVoyageReport]
  )

  useEffect(() => {
    const columns = [
      {
        id: "reporting_period",
        accessor: "reporting_period",
        header: "Reporting Period",
        width: "18%",
        sortOrder: isColumnSorted("reporting_period") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "within_india",
        header: "No of Voyages within India",
        accessor: "within_india",
        sortOrder: isColumnSorted("within_india") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "india_to_non_india",
        header: "No of Voyages from India to Non-India",
        accessor: "india_to_non_india",
        sortOrder: isColumnSorted("india_to_non_india") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
      {
        id: "non_india_to_india",
        header: "No of Voyages from Non-India to India",
        accessor: "non_india_to_india",
        sortOrder: isColumnSorted("non_india_to_india") ? sortOrder : "",
        onSortChange: handleSortChange,
      },
    ]
    setColumns(columns)
  }, [handleSortChange, isColumnSorted, sortOrder])

  useEffect(() => {
    if (
      urlParams?.shipId &&
      getShipQuery?.isComplete &&
      getVoyageReportQuery?.isIdle &&
      !getVoyageReportQuery?.isPending
    ) {
      fetchVoyageReport({ page: 1 })
    }
  }, [
    fetchVoyageReport,
    getVoyageReportQuery?.isIdle,
    getVoyageReportQuery?.isPending,
    getShipQuery?.isComplete,
    urlParams?.shipId,
  ])

  return useMemo(() => {
    return {
      getShipQuery,
      getVoyageReportQuery,
      listRef,
      columns,
      breadcrumb,
      handleThreeDotMenuClick,
    }
  }, [
    breadcrumb,
    columns,
    getVoyageReportQuery,
    getShipQuery,
    handleThreeDotMenuClick,
  ])
}

export const [ReportedVoyagesPageProvider, useReportedVoyagesPageContext] =
  generateContext(useReportedVoyagesPage)
