import React, {
  useContext,
  useCallback,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import storageService from "../services/storage.service"
import Const from "../constants"

const TokenContext = createContext()

export function TokenProvider(props) {
  const { children } = props
  const [data, setData] = useState(() => {
    let tokens = storageService.getItem(Const.CONTEXT_TYPE.TOKEN)
    return tokens || {}
  })

  const setToken = useCallback((tokenType, tokenData) => {
    let tokens = storageService.getItem(Const.CONTEXT_TYPE.TOKEN) || {}
    tokens[tokenType] = tokenData
    storageService.setItem(Const.CONTEXT_TYPE.TOKEN, tokens)
    setData(tokens)
  }, [])

  const clearTokens = useCallback(() => {
    storageService.clear(Const.CONTEXT_TYPE.TOKEN)
    setData({})
  }, [])

  const syncToSession = useCallback(() => {
    syncLocalTokensToSession()
  }, [])

  useEffect(() => {
    function subscribeHandler(tokens) {
      setData(tokens)
    }
    storageService.addChangeListener(Const.CONTEXT_TYPE.TOKEN, subscribeHandler)
    return () => {
      storageService.removeChangeListener(
        Const.CONTEXT_TYPE.TOKEN,
        subscribeHandler
      )
    }
  }, [])

  const value = useMemo(() => {
    return { tokens: data, setToken, clearTokens, syncToSession }
  }, [clearTokens, data, setToken, syncToSession])

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
}

export function useTokensFromStorage() {
  const context = useContext(TokenContext)
  if (context === undefined) {
    throw new Error("useTokensFromStorage must be used within a TokenProvider")
  }
  return context
}

// NOTE: only and only use this functions when needed to use outside react
export function getTokenDataFromStorage(tokenType) {
  let tokens = storageService.getItem(Const.CONTEXT_TYPE.TOKEN)
  return tokens?.[tokenType]
}
export function setTokenDataToStorage(tokenType, tokenData) {
  let tokens = storageService.getItem(Const.CONTEXT_TYPE.TOKEN) || {}
  tokens[tokenType] = tokenData
  storageService.setItem(Const.CONTEXT_TYPE.TOKEN, tokens)
}
export function clearTokenDataFromStorage() {
  storageService.removeItem(Const.CONTEXT_TYPE.TOKEN)
}

export default TokenProvider
