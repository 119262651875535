import EmissionShipIcon from "components/Vectors/emission-ship-icon"
import CalendarIcon from "components/Vectors/calendar-icon"
import VoyageShipIcon from "components/Vectors/voyage-ship-icon"
import { Link, NavLink, useParams } from "react-router-dom"
import React, { useEffect } from "react"
import MetisMenu from "metismenujs"
import cx from "utils/cx"
import types from "utils/types"

function SidebarNavLink(props) {
  const { children, className, ...restProps } = props
  const _className = function (data) {
    const { isActive, isPending } = data
    if (types.isFunction(className)) {
      return cx("sidebar-item", className(data))
    }
    return cx(
      "sidebar-item",
      className,
      isActive && "sidebar-item_active",
      isPending && "sidebar-item_pending"
    )
  }

  return (
    <NavLink className={_className} {...restProps}>
      {children}
    </NavLink>
  )
}

export default function Sidebar() {
  const { shipId } = useParams()

  useEffect(() => {
    new MetisMenu(".side-menu")
  }, [])

  useEffect(() => {
    function handler(e) {
      e.preventDefault()
      e.stopPropagation()
    }

    $(".side-menu .non-collapsible > a").on("click", handler)
    return () => {
      $(".side-menu .non-collapsible > a").off("click", handler)
    }
  }, [])

  return (
    <div className="sidebar">
      <ul className="side-menu">
        <li className="side-menu_item">
          <SidebarNavLink to={`/my-fleet/${shipId}/emissions`}>
            <span className="sidebar-item_icon">
              <EmissionShipIcon />
            </span>
            <span className="sidebar-item_text">Emissions</span>
          </SidebarNavLink>
        </li>
        <li className="side-menu_item">
          <SidebarNavLink to={`/my-fleet/${shipId}/annual-emissions`}>
            <span className="sidebar-item_icon">
              <CalendarIcon />
            </span>
            <span className="sidebar-item_text">Annual Emissions</span>
          </SidebarNavLink>
        </li>
        <li className="side-menu_item mm-active">
          <Link className="sidebar-item has-arrow" to="#" aria-expanded="true">
            <span className="sidebar-item_icon">
              <VoyageShipIcon />
            </span>
            <span className="sidebar-item_text">Voyage Figures</span>
          </Link>

          <ul className="nested-side-menu" aria-expanded="true">
            <li className="side-menu_item">
              <SidebarNavLink to={`/my-fleet/${shipId}/reported-voyages`}>
                <span className="sidebar-item_icon">-</span>
                <span className="sidebar-item_text">Reported Voyages</span>
              </SidebarNavLink>
            </li>
            <li className="side-menu_item">
              <SidebarNavLink to={`/my-fleet/${shipId}/port-call-records`}>
                <span className="sidebar-item_icon">-</span>
                <span className="sidebar-item_text">Port Call Records</span>
              </SidebarNavLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}
