import withRouter from "components/Common/withRouter"
import { useTokensFromStorage } from "contexts/token.context"
import { useUserFromStorage } from "contexts/user.context"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const user = useUserFromStorage();
  const tokens = useTokensFromStorage();
  const navigate = useNavigate();

  const handleLogoutClick = (e)=>{
    e.preventDefault();
    e.stopPropagation();
    tokens.clearTokens();
    user.removeUser();
    navigate("/");
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="avatar-text">PN</span>
          <span className="d-none d-xl-inline-block ms-2 me-1 text-capitalize">{user?.user?.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            <Link to="/profile">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              Profile
            </Link>
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem>
            <Link to="/users">
              <i className="bx bx-user-plus font-size-16 align-middle me-1" />
              Manage Users
            </Link>
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem>
            <Link onClick={handleLogoutClick}>
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>Logout</span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(ProfileMenu)
