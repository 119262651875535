import { useField, useFormikContext } from "formik"
import React from "react"
import { Input as InputRaw } from "reactstrap"
import FormError from "./form-error"
import TextareaAutosize from "react-textarea-autosize"

const Textarea = React.forwardRef(function Textarea(props, ref) {
  const { error, ...restProps } = props
  return (
    <TextareaAutosize
      className="form-control text-input"
      {...restProps}
      type="textarea"
      ref={ref}
    />
  )
})

export function FormikTextarea(props) {
  const { name, setRef = () => {}, onChange, onBlur, ...restProps } = props
  const [field, meta, { setValue }] = useField(name)
  const { submitCount } = useFormikContext()
  const isError = submitCount > 0 && !!meta.error
  const helperText = (submitCount > 0 && meta.error) || ""

  const handleOnChange = e => {
    const value = e.target.value
    setValue(value)
    onChange && onChange(value)
  }

  const handleOnBlur = e => {
    onBlur && onBlur(e)
  }
  return (
    <>
      <Textarea
        ref={setRef}
        error={isError}
        {...restProps}
        {...field}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      <FormError show={isError} message={helperText} />
    </>
  )
}

export default Textarea
