import * as validations from "../../../helpers/validation-helper"
import * as yup from "yup"

export const FN = {
  COMPANY_NAME: "company_name",
  COMPANY_NUMBER: "comapny_number",
  COMPANY_ADDRESS: "company_address",
  COUNTRY: "country",
  PRINCIPAL_PLACE_OF_BUSINESS: "principal_place_of_bussiness",
  CONTACT_PERSON: {
    ROOT: "contact_person",
    NAME: "name",
    EMAIL: "email",
    RE_EMAIL: "re_email",
    DESIGNATION: "designation",
    TELEPHONE: "telephone",
  },
}

export const generateInitialValues = () => {
  return {
    [FN.COMPANY_NAME]: "",
    [FN.COMPANY_NUMBER]: "",
    [FN.COMPANY_ADDRESS]: "",
    [FN.COUNTRY]: "",
    [FN.PRINCIPAL_PLACE_OF_BUSINESS]: "",
    [FN.CONTACT_PERSON.ROOT]: {
      [FN.CONTACT_PERSON.NAME]: "",
      [FN.CONTACT_PERSON.EMAIL]: "",
      [FN.CONTACT_PERSON.RE_EMAIL]: "",
      [FN.CONTACT_PERSON.DESIGNATION]: "",
      [FN.CONTACT_PERSON.TELEPHONE]: "",
    },
  }
}

export const generateValidationSchema = () => {
  return yup.object().shape({
    [FN.COMPANY_NAME]: validations.isRequiredString(),
    [FN.COMPANY_NUMBER]: validations.isRequiredString(),
    [FN.COMPANY_ADDRESS]: validations.isRequiredString(),
    [FN.COUNTRY]: validations.isRequiredString(),
    [FN.CONTACT_PERSON.ROOT]: yup.object().shape({
      [FN.CONTACT_PERSON.NAME]: validations.isRequiredString(),
      [FN.CONTACT_PERSON.EMAIL]: validations.isRequiredEMailString(),
      [FN.CONTACT_PERSON.RE_EMAIL]: yup
        .string()
        .oneOf([yup.ref(FN.CONTACT_PERSON.EMAIL), null], "Emails must match")
        .required("Please confirm your email"),
      [FN.CONTACT_PERSON.DESIGNATION]: validations.isRequiredString(),
      [FN.CONTACT_PERSON.TELEPHONE]: validations.isRequiredString(),
    }),
  })
}

export const generatePayload = formData => {
  const contactPerson = formData[FN.CONTACT_PERSON.ROOT]
  return {
    company: {
      name: formData[FN.COMPANY_NAME],
      number: formData[FN.COMPANY_NUMBER],
      address: formData[FN.COMPANY_ADDRESS],
      country_id: formData[FN.COUNTRY],
      principal_place_of_business: formData[FN.PRINCIPAL_PLACE_OF_BUSINESS],
    },
    contact_person: {
      name: contactPerson[FN.CONTACT_PERSON.NAME],
      email: contactPerson[FN.CONTACT_PERSON.EMAIL],
      telephone: contactPerson[FN.CONTACT_PERSON.TELEPHONE],
      designation: contactPerson[FN.CONTACT_PERSON.DESIGNATION],
    },
  }
}
