import React from "react"
import Authbox from "components/Common/auhbox"
import AuthHeader from "components/Common/auth-header"
import LoginForm from "components/Forms/login-form"
import withHOC from "utils/with-hoc"
import { LoginPageProvider, useLoginPageContext } from "./provider"

function LoginPage() {
  const { handleLogin } = useLoginPageContext()
  //meta title
  document.title = "Login"

  return (
    <div className="layout-wrapper auth-layout">
      <div id="register-page" className="main">
        <AuthHeader />
        <div className="main-content">
          <div className="page-content">
            <Authbox>
              <LoginForm onSubmit={handleLogin} />
            </Authbox>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withHOC(LoginPageProvider, LoginPage)
