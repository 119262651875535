import { useAsync } from "@react-org/hooks"
import { useCallback, useEffect, useMemo, useState } from "react"
import * as MeApi from "../../apis/me.api"
import * as AssetsApi from "../../apis/assets.api"
import generateContext from "../../utils/generate-context"
import { useNotificationModalContext } from "components/notification-modal/provider"
import { useUserFromStorage } from "contexts/user.context"

function useProfilePage() {
  const notificationModal = useNotificationModalContext()
  const user = useUserFromStorage()

  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] = useState(false)
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)

  const getUserInfoQuery = useAsync(MeApi.getUserInfo)
  const updateUserInfoQuery = useAsync(MeApi.updateUserInfo, {
    immediate: false,
  })
  const updateCompanyDetailsQuery = useAsync(MeApi.updateCompanyDetails, {
    immediate: false,
  })

  const getCompanyLogoQuery = useAsync(AssetsApi.getCompanyLogo, {
    immediate: false,
  })
  const uploadCompanyLogoQuery = useAsync(AssetsApi.uploadCompanyLogo, {
    immediate: false,
  })
  const deleteCompanyLogoQuery = useAsync(AssetsApi.deleteCompanyLogo, {
    immediate: false,
  })

  const handleCloseEditCompanyModalClick = () => {
    setIsEditCompanyModalOpen(false)
  }

  const handleEditCompanyClick = () => {
    setIsEditCompanyModalOpen(true)
  }

  const handleCloseEditUserModalClick = () => {
    setIsEditUserModalOpen(false)
  }

  const handleEditUserClick = () => {
    setIsEditUserModalOpen(true)
  }

  const handleEditUser = useCallback(
    formData => {
      notificationModal.progress({
        heading: "Updating User Info",
      })

      const payload = { body: formData }
      const updateUserInfoQueryExecute = updateUserInfoQuery.execute

      updateUserInfoQueryExecute(payload, {
        onSuccess: res => {
          debugger
          notificationModal.close()
          setIsEditUserModalOpen(false)
          const getUserInfoQueryExecute = getUserInfoQuery.execute
          getUserInfoQueryExecute(
            {},
            {
              onSuccess: res => {
                user.setUser(res.data)
              },
            }
          )
        },
        onError: err => {
          notificationModal.error({
            title: "Failed to update user info",
            heading:
              "Try again, if the error persist contact IFCR support team",
            onClose: () => {
              console.log("closed")
            },
          })
        },
      })
    },
    [
      getUserInfoQuery.execute,
      notificationModal,
      updateUserInfoQuery.execute,
      user,
    ]
  )

  const handleEditCompany = useCallback(
    formData => {
      const { file, ...restFormData } = formData
      notificationModal.progress({
        heading: "Updating Company Details",
      })

      const payload = {
        companyId: getUserInfoQuery?.data?.data?.company?.id,
        body: restFormData,
      }
      const updateCompanyDetailsQueryExecute = updateCompanyDetailsQuery.execute

      updateCompanyDetailsQueryExecute(payload, {
        onSuccess: res => {
          if (file?.name) {
            // update file
            const uploadCompanyLogoQueryExecute = uploadCompanyLogoQuery.execute
            uploadCompanyLogoQueryExecute(
              {
                companyId: user?.user?.company_id,
                file,
              },
              {
                onComplete: () => {
                  notificationModal.close()
                  setIsEditCompanyModalOpen(false)
                  const getUserInfoQueryExecute = getUserInfoQuery.execute
                  getUserInfoQueryExecute(
                    {},
                    {
                      onSuccess: res => {
                        user.setUser(res.data)
                      },
                    }
                  )
                  const getCompanyLogoQueryExecute = getCompanyLogoQuery.execute
                  getCompanyLogoQueryExecute({
                    companyId: user?.user?.company?.id,
                  })
                },
              }
            )
          } else if (!file) {
            // delete file
            const deleteCompanyLogoQueryExecute = deleteCompanyLogoQuery.execute
            deleteCompanyLogoQueryExecute(
              {
                companyId: user?.user?.company_id,
              },
              {
                onComplete: () => {
                  notificationModal.close()
                  setIsEditCompanyModalOpen(false)
                  const getUserInfoQueryExecute = getUserInfoQuery.execute
                  getUserInfoQueryExecute(
                    {},
                    {
                      onSuccess: res => {
                        user.setUser(res.data)
                      },
                    }
                  )
                  const getCompanyLogoQuerySetData =
                    getCompanyLogoQuery.setQueryData
                  getCompanyLogoQuerySetData(null)
                },
              }
            )
          } else {
            notificationModal.close()
            setIsEditCompanyModalOpen(false)
            const getUserInfoQueryExecute = getUserInfoQuery.execute
            getUserInfoQueryExecute(
              {},
              {
                onSuccess: res => {
                  user.setUser(res.data)
                },
              }
            )
          }
        },
        onError: err => {
          notificationModal.error({
            title: "Failed to update company info",
            heading:
              "Try again, if the error persist contact IFCR support team",
            onClose: () => {
              console.log("closed")
            },
          })
        },
      })
    },
    [
      deleteCompanyLogoQuery.execute,
      getCompanyLogoQuery.execute,
      getCompanyLogoQuery.setQueryData,
      getUserInfoQuery?.data?.data?.company?.id,
      getUserInfoQuery.execute,
      notificationModal,
      updateCompanyDetailsQuery.execute,
      uploadCompanyLogoQuery.execute,
      user,
    ]
  )

  useEffect(() => {
    if (
      getCompanyLogoQuery?.isIdle &&
      !getCompanyLogoQuery?.isPending &&
      user?.user?.company?.logo_path
    ) {
      const getCompanyLogoQueryExecute = getCompanyLogoQuery.execute
      getCompanyLogoQueryExecute({ companyId: user?.user?.company?.id })
    }
  }, [
    getCompanyLogoQuery.execute,
    getCompanyLogoQuery?.isIdle,
    getCompanyLogoQuery?.isPending,
    user?.user?.company?.id,
    user?.user?.company?.logo_path,
  ])

  return useMemo(() => {
    return {
      getUserInfoQuery,
      isEditCompanyModalOpen,
      isEditUserModalOpen,
      handleEditCompany,
      handleCloseEditCompanyModalClick,
      handleEditCompanyClick,
      handleEditUser,
      handleCloseEditUserModalClick,
      handleEditUserClick,
      getCompanyLogoQuery,
    }
  }, [
    getCompanyLogoQuery,
    getUserInfoQuery,
    handleEditCompany,
    handleEditUser,
    isEditCompanyModalOpen,
    isEditUserModalOpen,
  ])
}

export const [ProfilePageProvider, useProfilePageContext] =
  generateContext(useProfilePage)
