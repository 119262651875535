import React from "react";

function DropIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            fill="none"
            viewBox="0 0 16 15"
        >
            <path
                stroke="#495057"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M1.031 8v6H14.81V8"
            ></path>
            <path
                fill="#495057"
                d="M7.168 9.5a.75.75 0 001.5 0h-1.5zm1.5 0V3.2h-1.5v6.3h1.5z"
            ></path>
            <path
                stroke="#495057"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M4.79 5l3.13-3 3.132 3"
            ></path>
        </svg>
    );
}

export default DropIcon;