import { useUserFromStorage } from "contexts/user.context"
import React from "react"
import { Navigate } from "react-router-dom"

const PrivateRouteMiddleware = props => {
  const { user } = useUserFromStorage()

  if (!user?.id) {
    return <Navigate to="/login" />
  }

  return <React.Fragment>{props.children}</React.Fragment>
}
const ProtectedRouteMiddleware = props => {
  const { user } = useUserFromStorage()

  if (user?.id) {
    return <Navigate to="/my-fleet" />
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export { PrivateRouteMiddleware, ProtectedRouteMiddleware }
