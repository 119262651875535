import { withConfig, getApi } from "./index"
const api = getApi("auth")


export const login = withConfig((data, conf) => {
  const { email, password } = data
  const config = conf({
    url: "/login",
    method: "POST",
    auth: {
      username: email,
      password,
    },
  })
  return api(config)
})

export const register = withConfig((data, conf) => {
  const config = conf({
    url: "/register",
    method: "POST",
    data: data,
  })
  return api(config)
})

export const resetPassword = withConfig((data, conf) => {
  const config = conf({
    url: "/reset-password",
    method: "POST",
    data: data,
  })
  return api(config)
})
export const forgotPassword = withConfig((data, conf) => {
  const config = conf({
    url: "forgot-password",
    method: "POST",
    data: data,
  })
  return api(config)
})

export const getAccessToken = withConfig((data, conf) => {
  const { refreshToken } = data
  const config = conf({
    url: "/access-token",
    method: "GET",
    headers: { "x-refresh-token": refreshToken },
  })
  return api(config)
})
