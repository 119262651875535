import { useAsync } from "@react-org/hooks"
import { useEffect } from "react"
import React from "react"
import * as AssetsApi from "../../apis/assets.api"

export default function Flag(props) {
  const getContryFlagQuery = useAsync(AssetsApi.getCountryFlagByCode, {
    immediate: false,
  })

  useEffect(() => {
    if (getContryFlagQuery?.isIdle && !getContryFlagQuery?.isPending) {
      const getContryFlagQueryExecute = getContryFlagQuery.execute
      getContryFlagQueryExecute({ countryCode: props.code })
    }
  }, [getContryFlagQuery, props.code])

  return (
    <img
      style={{ width: "100%" }}
      src={getContryFlagQuery?.data?.data}
      alt=""
    />
  )
}
