import DropIcon from "components/Vectors/drop-icon"
import React, { useState } from "react"
import { useDropzone } from "react-dropzone"

function IfcrDropZone(props) {
  const { file, onClear, onDrop } = props

  const handleOnDrop = files => {
    const file = Object.assign(files[0], {
      preview: URL.createObjectURL(files[0]),
    })

    onDrop && onDrop(file)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    }, // Specify the file types you want to accept (in this case, images)
    multiple: false, // Set to false if you want to allow only a single file upload
    onDrop: handleOnDrop,
  })

  const handleClearFileClick = e => {
    e.preventDefault()
    e.stopPropagation()

    onClear && onClear()
  }

  return (
    <section>
      <div className="drop-zone-sekelton" {...getRootProps()}>
        <div>
          <input {...getInputProps()} />
          <div className="drop-zone-child">
            {file ? (
              <div className="text-success ">
                <div className="image-preview">
                  <img
                    src={file.preview}
                    alt=""
                    onLoad={() => {
                      URL.revokeObjectURL(file.preview)
                    }}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className="text-danger c-pointer w-6 h-6 d-flex align-items-center justify-content-center"
                    onClick={handleClearFileClick}
                  >
                    <span>Remove</span>
                    <i className="mdi mdi-close"></i>
                  </div>
                </div>
              </div>
            ) : (
              <>
                Drop file here or click to upload.
                <div className="ml-1 mb-1">
                  <DropIcon />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default IfcrDropZone
