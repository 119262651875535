import React from "react";
import { DropdownMenu } from "reactstrap";
import Portal from "./Portal";

const DropdownMenuPortal = ({ isOpen, targetElement, children }) => {
  if (!isOpen) {
    return null // Don't render the dropdown menu if it's not open
  }

  const dropdownMenuStyle = {
    position: "fixed",
    top: targetElement.offsetTop + targetElement.offsetHeight,
    left: targetElement.offsetLeft,
  }

  return (
    <Portal>
      <div style={dropdownMenuStyle}>
        <DropdownMenu>{children}</DropdownMenu>
      </div>
    </Portal>
  )
}

export default DropdownMenuPortal;