import * as Yup from "yup"
import moment from "moment"

const VALIDATION_STRING_MSG = "The value must be string"
const VALIDATION_DATE_MSG = "The value must be date"
const VALIDATION_NUMBER_MSG = "The value must be numeric"
const VALIDATION_CHARACTER_MSG = "The value must be character"
const VALIDATION_BOOL_MSG = "The value must be bool"

export const isRequiredEMailString = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config

  let typeErrorMsg = VALIDATION_STRING_MSG
  let requiredErrorMsg = `${fieldName} is required`
  let emailErrorMsg = `Value of ${fieldName} must be a valid email`

  if (withRequired) {
    return Yup.string()
      .typeError(typeErrorMsg)
      .email(emailErrorMsg)
      .required(message || requiredErrorMsg)
  }
  return Yup.string().typeError(typeErrorMsg)
}
export const isRequiredString = (config = {}) => {
  let {
    withRequired = true,
    fieldName = "This field",
    message = "",
    nullable = false,
  } = config

  let typeErrorMsg = VALIDATION_STRING_MSG
  let requiredErrorMsg = `${fieldName} is required`

  if (withRequired) {
    if (nullable) {
      return Yup.string()
        .transform((currentValue, originalValue) => {
          // mock null as false boolean value to allow nullable values in boolean check
          return originalValue === null ? " " : currentValue
        })
        .typeError(typeErrorMsg)
        .required(message || requiredErrorMsg)
    }
    return Yup.string()
      .typeError(typeErrorMsg)
      .required(message || requiredErrorMsg)
  }
  if (nullable) {
    return Yup.string()
      .transform((currentValue, originalValue) => {
        // mock null as false boolean value to allow nullable values in boolean check
        return originalValue === null ? " " : currentValue
      })
      .typeError(typeErrorMsg)
  }
  return Yup.string().typeError(typeErrorMsg)
}
export const isRequiredNumber = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config

  let typeErrorMsg = VALIDATION_NUMBER_MSG
  let requiredErrorMsg = `${fieldName} is required`
  let nagativeNumberErrorMsg = `${fieldName} must be of positive number`

  if (withRequired) {
    return Yup.number()
      .typeError(typeErrorMsg)
      .required(message || requiredErrorMsg)
      .positive(nagativeNumberErrorMsg)
  }
  return Yup.number()
    .transform((val, ogVal) => (ogVal?.trim?.() === "" ? null : val))
    .nullable()
    .typeError(typeErrorMsg)
}
export const isRequiredBoolean = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config

  let typeErrorMsg = VALIDATION_BOOL_MSG
  let requiredErrorMsg = `${fieldName} is required`

  if (withRequired) {
    return Yup.bool()
      .transform((currentValue, originalValue) => {
        // mock null as false boolean value to allow nullable values in boolean check
        return originalValue === null ? false : currentValue
      })
      .typeError(typeErrorMsg)
      .required(message || requiredErrorMsg)
  }
  return Yup.bool().nullable().typeError(typeErrorMsg)
}
export const isRequiredDate = (config = {}) => {
  let {
    withRequired = true,
    fieldName = "This field",
    message = "",
    typeErrorMsg = "",
  } = config
  let _typeErrorMsg = typeErrorMsg || VALIDATION_DATE_MSG
  let requiredErrorMsg = `${fieldName} is required`
  if (withRequired) {
    return Yup.array()
      .of(Yup.date().typeError(_typeErrorMsg).nullable())
      .nullable()
      .min(1, message || requiredErrorMsg)
      .nullable()
      .required(_typeErrorMsg)
  }
  return Yup.array()
    .of(Yup.date().typeError(typeErrorMsg))
    .required(typeErrorMsg)
}
export const checkMinDate = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config
  let typeErrorMsg = `${fieldName} should be future date`
  let requiredErrorMsg = `This field is required`
  if (withRequired) {
    return Yup.array()
      .of(
        Yup.date()
          .min(moment().subtract(1, "days"), message || typeErrorMsg)
          .nullable()
      )
      .nullable()
      .min(1, message || requiredErrorMsg)
      .required(typeErrorMsg)
  }
  return Yup.array()
    .of(Yup.date().typeError(typeErrorMsg))
    .required(typeErrorMsg)
}
export const checkMaxDate = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config
  let typeErrorMsg = `${fieldName} should be past date`
  let requiredErrorMsg = `This field is required`
  if (withRequired) {
    return Yup.array()
      .of(Yup.date().max(moment().subtract(1, "days"), message || typeErrorMsg))
      .min(1, message || requiredErrorMsg)
      .required(typeErrorMsg)
  }
  return Yup.array()
    .of(Yup.date().typeError(typeErrorMsg))
    .required(typeErrorMsg)
}
export const isCharOnlyString = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config

  let typeErrorMsg = VALIDATION_CHARACTER_MSG
  let requiredErrorMsg = `${fieldName} is required`

  if (withRequired) {
    return Yup.string()
      .matches(/^[a-zA-Z\s]*$/, typeErrorMsg)
      .typeError(typeErrorMsg)
      .required(message || requiredErrorMsg)
  }
  return Yup.string().typeError(typeErrorMsg)
}
