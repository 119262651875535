import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { AxiosInterceptorProvider } from "./apis"
import App from "./App"
import NotificationModal from "./components/notification-modal"
import { NotificationModalProvider } from "./components/notification-modal/provider"
import TokenProvider from "./contexts/token.context"
import UserProvider from "./contexts/user.context"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.Fragment>
    <BrowserRouter>
      <NotificationModalProvider>
        <NotificationModal />
        <AxiosInterceptorProvider>
          <UserProvider>
            <TokenProvider>
              <App />
            </TokenProvider>
          </UserProvider>
        </AxiosInterceptorProvider>
      </NotificationModalProvider>
    </BrowserRouter>
  </React.Fragment>
)
