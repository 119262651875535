import { withConfig, getApi } from "./index"
const api = getApi("me")

export const getUserInfo = () => {
  return api({
    url: "/info",
    method: "GET",
  })
}

export const updateUserInfo = withConfig((data, conf) => {
  const { body } = data
  const config = conf({
    url: `/info`,
    method: "PUT",
    data: body,
  })
  return api(config)
})

export const getCompany = withConfig((data, conf) => {
  const { companyId, params } = data
  const config = conf({
    url: `/companies/${companyId}`,
    method: "GET",
    params: params,
  })
  return api(config)
})

export const updateCompanyDetails = withConfig((data, conf) => {
  const { companyId, body } = data
  const config = conf({
    url: `/companies/${companyId}`,
    method: "PUT",
    data: body,
  })
  return api(config)
})
