import * as validations from "../../../helpers/validation-helper"
import * as yup from "yup"

export const FN = {
  NAME: "name",
  EMAIL: "email",
  RE_EMAIL: "re_email",
  TELEPHONE: "telephone",
  DESIGNATION: "designation",
}

export const generateInitialValues = (data = {}) => {
  return {
    [FN.NAME]: data.name,
    [FN.EMAIL]: data.email,
    [FN.RE_EMAIL]: data.email,
    [FN.TELEPHONE]: data.telephone,
    [FN.DESIGNATION]: data.designation,
  }
}

export const generateValidationSchema = isEditMode => {
  if (isEditMode) {
    return yup.object().shape({
      [FN.NAME]: validations.isRequiredString(),
      [FN.TELEPHONE]: validations.isRequiredString(),
      [FN.DESIGNATION]: validations.isRequiredString(),
    })
  }
  return yup.object().shape({
    [FN.NAME]: validations.isRequiredString(),
    [FN.EMAIL]: validations.isRequiredString(),
    [FN.RE_EMAIL]: yup
      .string()
      .oneOf([yup.ref(FN.EMAIL), null], "Emails must match")
      .required("Please confirm your email"),
    [FN.TELEPHONE]: validations.isRequiredString(),
    [FN.DESIGNATION]: validations.isRequiredString(),
  })
}

export const generatePayload = (formData, isEditMode) => {
  if (isEditMode) {
    return {
      name: formData[FN.NAME],
      telephone: formData[FN.TELEPHONE],
      designation: formData[FN.DESIGNATION],
    }
  }
  return {
    name: formData[FN.NAME],
    email: formData[FN.EMAIL],
    telephone: formData[FN.TELEPHONE],
    designation: formData[FN.DESIGNATION],
  }
}
