import React from "react"
import cx from "../../utils/cx"
import { useNotificationModalContext } from "./provider"

function Overlay(props) {
  const { children } = props

  const notificationModal = useNotificationModalContext()

  return (
    <div
      onClick={notificationModal.handleOverlayClick}
      className={cx(
        "notification-modal_overlay ",
        notificationModal.open && "notification-modal_overlay-visible",
        notificationModal.classNames?.root
      )}
    >
      {children}
    </div>
  )
}

export default Overlay
