import React from "react"
import { Col, Row } from "reactstrap"
import LogoImg from "../../assets/images/logo.svg"

export default function Authbox(props) {
  const { children } = props
  return (
    <div className="scollable-container">
      <div className="scollable-content">
        <Row className="authbox">
          <Col className="authbox-left">
            <div className="logo">
              <div className="logo-icon">
                <img src={LogoImg} alt="logo" />
              </div>
              <div className="logo-content">
                <span className="logo-text">India-FCR</span>
                <span className="logo-name">Fuel Consumption Reporting</span>
              </div>
            </div>
          </Col>
          <Col className="authbox-right p-0">{children}</Col>
        </Row>
      </div>
    </div>
  )
}
