import { useAsync } from "@react-org/hooks"
import { useNotificationModalContext } from "components/notification-modal/provider"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import generateContext from "../../utils/generate-context"
import React from "react"
import { useUserFromStorage } from "contexts/user.context"
import { generatePath } from "react-router-dom"
import is from "utils/is"
import * as MeApi from "../../apis/me.api"
import * as ShipsApi from "../../apis/ships.api"
import * as AssetsApi from "../../apis/assets.api"
import ThreeDotMenu from "./three-dot-menu"
import debounce from "../../utils/debounce"
import paginateSelector from "utils/paginate-selector"
import Flag from "./flag"

function useMyFleetPage() {
  const navigate = useNavigate()
  const notificationModal = useNotificationModalContext()
  const user = useUserFromStorage()

  const listRef = useRef()
  const [isAddShipModalOpen, setIsAddShipModalOpen] = useState(false)
  const [columns, setColumns] = useState([])
  const [activeMenuRowIndex, setActiveMenuRowIndex] = useState(null)

  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState("name")
  const [sortOrder, setSortOrder] = useState("asc")

  const getShipsQuery = useAsync(ShipsApi.getShips, {
    intialState: { data: [] },
    immediate: false,
    infinite: true,
    select: paginateSelector,
  })

  const getCompanyQuery = useAsync(
    useCallback(
      payload =>
        MeApi.getCompany({ companyId: user?.user?.company?.id, ...payload }),
      [user?.user?.company?.id]
    )
  )

  const getCompanyLogoQuery = useAsync(AssetsApi.getCompanyLogo, {
    immediate: false,
  })
  const addShipQuery = useAsync(ShipsApi.addShip, { immediate: false })
  const updateShipQuery = useAsync(ShipsApi.updateShip, { immediate: false })
  const deleteShipQuery = useAsync(ShipsApi.deleteShip, { immediate: false })

  const isColumnSorted = useCallback(
    columnId => {
      return sortBy === columnId
    },
    [sortBy]
  )

  const handleOnRowClick = useCallback(
    ({ row }) => {
      const redirectLink = generatePath("/my-fleet/:shipId/emissions", {
        shipId: row.id,
      })
      navigate(redirectLink)
    },
    [navigate]
  )

  const fetchShips = useCallback(
    data => {
      const { page = 1, term = search, sort = sortBy, order = sortOrder } = data

      if (page === 1) {
        const getShipsQuerySetData = getShipsQuery.setQueryData
        getShipsQuerySetData(null)
      }
      const getShipsQueryCancel = getShipsQuery.cancel
      getShipsQueryCancel()

      const getShipsQueryExecute = getShipsQuery.execute
      getShipsQueryExecute({
        params: {
          page,
          term,
          sort: sort ? `${sort}:${order}` : "created_date:desc",
          companyId: getCompanyQuery?.data?.data?.id,
        },
      })
    },
    [
      getCompanyQuery?.data?.data?.id,
      getShipsQuery.cancel,
      getShipsQuery.execute,
      getShipsQuery.setQueryData,
      search,
      sortBy,
      sortOrder,
    ]
  )

  const handleEditShip = useCallback(
    formData => {
      const activeRow = getShipsQuery.data[activeMenuRowIndex]
      notificationModal.progress({
        heading: "Updating Ship",
      })

      const payload = {
        shipId: activeRow.id,
        body: formData,
      }

      const updateShipQueryExecute = updateShipQuery.execute

      updateShipQueryExecute(payload, {
        onSuccess: res => {
          notificationModal.close()
          setActiveMenuRowIndex(null)
          setIsAddShipModalOpen(false)
          fetchShips({ page: 1 })
        },
        onError: err => {
          if (err?.response?.status === 409) {
            notificationModal.error({
              title: "Conflict",
              heading: "IMO Number is already associated with another Ship.",
              onClose: () => {
                console.log("closed")
              },
            })
          } else {
            notificationModal.close()
          }
        },
      })
    },
    [
      activeMenuRowIndex,
      fetchShips,
      getShipsQuery.data,
      notificationModal,
      updateShipQuery.execute,
    ]
  )

  const handleAddShip = useCallback(
    formData => {
      if (!is.undefined(activeMenuRowIndex) && !is.null(activeMenuRowIndex)) {
        return handleEditShip(formData)
      }
      notificationModal.progress({
        heading: "Adding New Ship",
      })

      const payload = {
        ...formData,
        company_id: getCompanyQuery?.data?.data?.id,
      }

      const addShipQueryExecute = addShipQuery.execute
      addShipQueryExecute(
        { body: payload },
        {
          onSuccess: res => {
            notificationModal.close()
            setIsAddShipModalOpen(false)
            fetchShips({ page: 1 })
          },
          onError: err => {
            if (err?.response?.status === 409) {
              notificationModal.error({
                title: "Conflict",
                heading: "IMO Number is already associated with another Ship.",
                onClose: () => {
                  console.log("closed")
                },
              })
            } else {
              notificationModal.close()
            }
          },
        }
      )
    },
    [
      activeMenuRowIndex,
      addShipQuery.execute,
      fetchShips,
      getCompanyQuery?.data?.data?.id,
      handleEditShip,
      notificationModal,
    ]
  )

  const handleDeleteShipClick = useCallback(
    (event, data) => {
      const { rowIndex, row } = data
      setActiveMenuRowIndex(rowIndex)
      notificationModal.warning({
        confirmText: "Delete",
        canceltext: "Close",
        title: "Are you sure?",
        heading:
          "Are you sure you want to delete this record? This action cannot be undone.",
        onCancel: () => {
          setActiveMenuRowIndex(null)
        },
        onConfirm: () => {
          notificationModal.progress({ heading: "Deleting Ship" })
          setActiveMenuRowIndex(null)
          const deleteShipQueryExecute = deleteShipQuery.execute
          deleteShipQueryExecute(
            { shipId: row.id },
            {
              onSuccess: () => {
                notificationModal.close()
                setActiveMenuRowIndex(null)
                fetchShips({ page: 1 })
              },
              onError: () => {
                notificationModal.error({
                  heading: "Failed to delete ship",
                  onClose: () => {
                    setActiveMenuRowIndex(null)
                  },
                })
              },
            }
          )
        },
      })
    },
    [deleteShipQuery.execute, fetchShips, notificationModal]
  )

  const handleEditShipClick = useCallback((event, data = {}) => {
    const { rowIndex } = data
    setIsAddShipModalOpen(true)
    setActiveMenuRowIndex(rowIndex)
  }, [])

  const handleCloseAddShipModalClick = useCallback(() => {
    setIsAddShipModalOpen(false)
  }, [])

  const renderFlagCell = useCallback(function (data) {
    const { row, columnIndex } = data
    const value = row?.flag?.name
    return (
      <div key={columnIndex} className="d-flex">
        <div className="flag-sm">
          <div className="flag-sm_placeholder">
            <Flag code={row?.flag?.code} />
          </div>
        </div>
        {value}
      </div>
    )
  }, [])

  const isHasMorePages = useMemo(() => {
    if (getShipsQuery.isPending) return true
    return (
      getShipsQuery?.paginate?.page_no * getShipsQuery?.paginate?.page_limit <
      getShipsQuery?.paginate?.total
    )
  }, [
    getShipsQuery.isPending,
    getShipsQuery?.paginate?.page_limit,
    getShipsQuery?.paginate?.page_no,
    getShipsQuery?.paginate?.total,
  ])

  const loadingRowCount = useMemo(() => {
    if (getShipsQuery?.isIdle || !getShipsQuery?.data?.length) {
      return 10
    }
    if (isHasMorePages) {
      return 2
    }
    return 0
  }, [getShipsQuery?.data?.length, getShipsQuery?.isIdle, isHasMorePages])

  const handleLoadMore = useCallback(() => {
    if (getShipsQuery?.isPending || getShipsQuery?.isIdle || !isHasMorePages)
      return
    let page = (getShipsQuery?.paginate?.page_no || 0) + 1
    fetchShips({ page })
  }, [
    fetchShips,
    getShipsQuery?.isIdle,
    getShipsQuery?.isPending,
    getShipsQuery?.paginate?.page_no,
    isHasMorePages,
  ])

  const handleThreeDotMenuClick = useCallback(
    (action, event, data) => {
      switch (action) {
        case "edit": {
          handleEditShipClick(event, data)
          break
        }
        case "delete": {
          handleDeleteShipClick(event, data)
          break
        }
        default: {
          // do nothing
        }
      }
    },
    [handleDeleteShipClick, handleEditShipClick]
  )

  const handleSortChange = useCallback(
    (order, column) => {
      let sortBy = order === "" ? "" : column.id
      setSortBy(sortBy)
      setSortOrder(order)
      fetchShips({ page: 1, sort: sortBy, order: order })
    },
    [fetchShips]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(value => {
      fetchShips({ page: 1, term: value })
    }, 500),
    []
  )

  const handleSearchChange = useCallback(
    e => {
      setSearch(e.target.value)
      handleSearch(e.target.value)
    },
    [handleSearch]
  )

  useEffect(
    function setColumnsEffect() {
      const columns = [
        {
          id: "imo_number",
          header: "IMO Number",
          accessor: "imo_number",
          sortOrder: isColumnSorted("imo_number") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          id: "name",
          header: "Ship Name",
          accessor: "name",
          sortOrder: isColumnSorted("name") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          id: "ship_type",
          header: "Ship Type",
          accessor: ({ row, column }) =>
            row?.ship_type_other || row?.ship_type?.name,
          sortOrder: isColumnSorted("ship_type") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          id: "flag",
          header: "Flag",
          renderCell: renderFlagCell,
          sortOrder: isColumnSorted("flag") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          id: "port_of_registry",
          header: "Port of Registry",
          accessor: ({ row, column }) => row?.port_of_registry,
          sortOrder: isColumnSorted("port_of_registry") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          id: "classification_society",
          header: "Classification Society",
          accessor: ({ row, column }) =>
            row?.classification_society_other ||
            row?.classification_society?.name,
          sortOrder: isColumnSorted("classification_society") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          header: "",
          width: "24px",
          sort: false,
          renderCell: data => {
            return <ThreeDotMenu data={data} />
          },
        },
      ]
      setColumns(columns)
    },
    [handleSortChange, isColumnSorted, renderFlagCell, sortOrder]
  )

  useEffect(() => {
    if (
      getCompanyQuery?.isComplete &&
      getShipsQuery?.isIdle &&
      !getShipsQuery?.isPending
    ) {
      fetchShips({ page: 1 })
    }
  }, [
    fetchShips,
    getCompanyQuery?.isComplete,
    getShipsQuery?.isIdle,
    getShipsQuery?.isPending,
  ])

  useEffect(() => {
    if (
      getCompanyLogoQuery?.isIdle &&
      !getCompanyLogoQuery?.isPending &&
      user?.user?.company?.logo_path
    ) {
      const getCompanyLogoQueryExecute = getCompanyLogoQuery.execute
      getCompanyLogoQueryExecute({ companyId: user?.user?.company?.id })
    }
  }, [
    getCompanyLogoQuery.execute,
    getCompanyLogoQuery?.isIdle,
    getCompanyLogoQuery?.isPending,
    user?.user?.company?.id,
    user?.user?.company?.logo_path,
  ])

  return useMemo(() => {
    return {
      listRef,
      isAddShipModalOpen,
      setIsAddShipModalOpen,
      columns,
      setColumns,
      activeMenuRowIndex,
      setActiveMenuRowIndex,
      getShipsQuery,
      getCompanyQuery,
      handleOnRowClick,
      handleAddShip,
      handleEditShipClick,
      handleCloseAddShipModalClick,
      renderFlagCell,
      isHasMorePages,
      loadingRowCount,
      handleLoadMore,
      handleThreeDotMenuClick,
      search,
      handleSearchChange,
      getCompanyLogoQuery,
    }
  }, [
    isAddShipModalOpen,
    columns,
    activeMenuRowIndex,
    getShipsQuery,
    getCompanyQuery,
    handleOnRowClick,
    handleAddShip,
    handleEditShipClick,
    handleCloseAddShipModalClick,
    renderFlagCell,
    isHasMorePages,
    loadingRowCount,
    handleLoadMore,
    handleThreeDotMenuClick,
    search,
    handleSearchChange,
    getCompanyLogoQuery,
  ])
}

export const [MyFleetPageProvider, useMyFleetPageContext] =
  generateContext(useMyFleetPage)
