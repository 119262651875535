import Portal from "components/Core/Portal"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

const Heading = props => {
  const { title = "", breadcrumbs = [], ...restPops } = props
  const [element, setElement] = useState()

  const breadcrumbItems = breadcrumbs.map((breadcrumb, breadcrumbIndex) => {
    return (
      <React.Fragment key={breadcrumbIndex}>
        <li>{breadcrumb.label}</li>
        <li>/</li>
      </React.Fragment>
    )
  })

  useEffect(() => {
    let ele = document.getElementById("page-heading")
    setElement(ele)
  }, [])

  if (!element) return null
  return (
    <Portal parent={element} {...restPops}>
      <div className="page-title-wrapper flex-column">
        <h4 className="page-title">{props.title}</h4>
        {breadcrumbItems.length > 0 && (
          <ul className="page-title-breadcrumbs">
            {breadcrumbItems}
            <li>{props.title}</li>
          </ul>
        )}
      </div>
    </Portal>
  )
}
Heading.propTypes = {
  title: PropTypes.any,
  breadcrumb: PropTypes.any,
}

export default Heading
