import React from "react"
import { Form, Formik } from "formik"
import { Button, Col, Row } from "reactstrap"
import { FormikInput } from "components/FormControls/input"
import FormLabel from "components/FormControls/form-label"
import {
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
} from "./form-helper"
import { useMemo } from "react"

export default function AddUserForm(props) {
  const { onSubmit, onClose, data = {} } = props

  const isEditMode = Object.keys(data).length !== 0

  const intitialValues = useMemo(
    () => generateInitialValues(data, isEditMode),
    [data, isEditMode]
  )

  const validationSchema = useMemo(
    () => generateValidationSchema(isEditMode),
    [isEditMode]
  )

  const handleOnSubmit = (formData, formikContext) => {
    const payload = generatePayload(formData, isEditMode)
    onSubmit && onSubmit(payload, formikContext)
  }

  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {formik => {
        return (
          <Form className="add-user-form form">
            <div className="modal-body">
              <Row>
                <Col xs={4}>
                  <div className="mb-3">
                    <FormLabel required>Name</FormLabel>
                    <FormikInput name={FN.NAME} />
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-3">
                    <FormLabel required>Email</FormLabel>
                    <FormikInput name={FN.EMAIL} disabled={isEditMode} />
                  </div>
                </Col>
                {!isEditMode && (
                  <Col xs={4}>
                    <div className="mb-3">
                      <FormLabel required>Re-type Email</FormLabel>
                      <FormikInput name={FN.RE_EMAIL} />
                    </div>
                  </Col>
                )}
                <Col xs={4}>
                  <div className="mb-3">
                    <FormLabel required>Telephone</FormLabel>
                    <FormikInput name={FN.TELEPHONE} />
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-3">
                    <FormLabel required>Designation</FormLabel>
                    <FormikInput name={FN.DESIGNATION} />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="actions modal-footer">
              <div className="actions-left">
                <Button
                  color="success"
                  type="button"
                  outline
                  className="action-button rounded-10"
                  onClick={onClose}
                >
                  Close
                </Button>
              </div>
              <div className="actions-right">
                {formik.dirty && (
                  <Button
                    color="success"
                    type="button"
                    outline
                    className="action-button rounded-10"
                  >
                    Cancel
                  </Button>
                )}
                <Button color="success" type="submit" className="action-button rounded-10">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
