import { forwardRef } from "react";
import { InfiniteLoader, List as VirtualizeList, AutoSizer } from "react-virtualized";
import cx from "../../../utils/cx";
import React from "react";
import PropTypes from "prop-types";

import useList from "./useList";

const List = forwardRef(function List(props, listRef) {
  const {
    isRenderNoRows,
    renderNoRows,
    getInfiniteLoaderProps,
    getListProps,
    classNames = {},
  } = useList({
    ...props,
    listRef,
  });

  if (isRenderNoRows) {
    return <div className={cx("list_no-row w-full flex m-4 bg-[#F4F9FF] items-center justify-center", classNames.renderNoRows)}>{renderNoRows()}</div>;
  }
  
  return (
    <AutoSizer className="list auto-sizer">
      {({ width, height }) => (
        <InfiniteLoader {...getInfiniteLoaderProps()} className="infinite-loader">
          {({ onRowsRendered, registerChild }) => (
            <VirtualizeList
              width={width}
              height={height}
              ref={(ref) => {
                if (listRef) listRef.current = ref;
                registerChild(ref);
              }}
              className="virtualize-list" 
              onRowsRendered={onRowsRendered}
              {...getListProps()}
            />
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
});

List.defaultProps = {
  rows: [],
  renderRow: null,
  renderSkeletonRow: null,
  isLoading: false,
  isLoadMoreDirty: false,
  loadingRowCount: 0,
  hasMore: false,
};

List.propTypes = {
  rows: PropTypes.array,
  renderRow: PropTypes.func,
  renderSkeletonRow: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadMoreDirty: PropTypes.bool,
  loadingRowCount: PropTypes.number,
  hasMore: PropTypes.bool,
};

export default List;
