import React from "react"
import cx from "../../utils/cx"

export default function IconBox(props) {
  const { children, className, ...restProps } = props
  return (
    <div className={cx("icon-box", className)} {...restProps}>
      {props.children}
    </div>
  )
}
