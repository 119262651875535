import Info from "components/Common/Info"
import React, { useMemo } from "react"
import { Card, Col, Row } from "reactstrap"
import companyPlaceholderImg from "../../assets/images/company-placeholder.svg"

export default function DetailsCard(props) {
  const {
    name,
    number,
    address: address_line,
    country,
    principal_place_of_business,
    contact_person,
  } = props.data||{};


  const address = useMemo(() => {
    return [address_line, country?.name].filter(i => i).join(", ")
  }, [address_line, country?.name])


  const logoUrl = props.logoUrl || companyPlaceholderImg

  return (
    <Card className="detail-card">
      <div className="company-logo d-flex align-items-center justify-content-center">
        <img src={logoUrl} alt="" />
        <div className="company-logo_placeholder"></div>
      </div>
      <Row className="comapny-details">
        <Col>
          <Info>
            <Info.Label md={6} lg={6} xl={6} className="fw-500">
              {name}
            </Info.Label>
          </Info>
          <Info>
            <Info.Label>Company Number</Info.Label>
            <Info.Value> {number}</Info.Value>
          </Info>
          <Info>
            <Info.Label>Company Address</Info.Label>
            <Info.Value>{address}</Info.Value>
          </Info>
          <Info>
            <Info.Label>Principal Place of Business</Info.Label>
            <Info.Value>{principal_place_of_business}</Info.Value>
          </Info>
        </Col>
        <Col>
          <Info>
            <Info.Label md={6} lg={6} xl={6} className="fw-500">
              {" "}
              Contact Person Details
            </Info.Label>
          </Info>
          <Info>
            <Info.Label>Name</Info.Label>
            <Info.Value className="text-capitalize">
              {contact_person?.name}
            </Info.Value>
          </Info>
          <Info>
            <Info.Label>Designation</Info.Label>
            <Info.Value>{contact_person?.designation}</Info.Value>
          </Info>
          <Info>
            <Info.Label>Email</Info.Label>
            <Info.Value>{contact_person?.email}</Info.Value>
          </Info>
          <Info>
            <Info.Label>Telephone</Info.Label>
            <Info.Value>{contact_person?.telephone || "-"}</Info.Value>
          </Info>
        </Col>
      </Row>
    </Card>
  )
}
