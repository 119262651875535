import ReactSelect from "react-select"
import React from "react"
import FormError from "./form-error"
import { useField, useFormikContext } from "formik"

const Select = React.forwardRef(function Select(props, ref) {
  const {
    value,
    onChange,
    options = [],
    valueString,
    labelString,
    error,
    ...restProps
  } = props

  const cleanValue = options.find(val => {
    let valueToCompare = valueString ? val[valueString] : val
    return valueToCompare === value
  })

  const handleOnChange = _value => {
    let valueToPass = ""
    if (_value === null) {
      valueToPass = ""
    } else {
      valueToPass = valueString ? _value[valueString] : _value
    }
    onChange && onChange(valueToPass)
  }

  const getOptionLabel = option => {
    return labelString ? option[labelString] : option
  }
  const getOptionValue = option => {
    return valueString ? option[valueString] : option
  }

  return (
    <ReactSelect
      menuPortalTarget={document.body}
      styles={{
        menuPortal: base => ({
          ...base,
          zIndex: 9999,
          backgroundColor: "#ffffff",
        }),
      }}
      className="select2-selection"
      value={cleanValue || ""}
      options={options}
      onChange={handleOnChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isClearable
      {...restProps}
      ref={ref}
    />
  )
})

export function FormikSelect(props) {
  const { name, setRef = () => {}, onChange, onBlur, ...restProps } = props
  const [field, meta, { setValue }] = useField(name)
  const { submitCount } = useFormikContext()
  const isError = submitCount > 0 && !!meta.error
  const helperText = (submitCount > 0 && meta.error) || ""

  const handleOnChange = value => {
    setValue(value)
    onChange && onChange(value)
  }

  const handleOnBlur = e => {
    onBlur && onBlur(e)
  }

  return (
    <>
      <Select
        ref={setRef}
        error={isError}
        {...restProps}
        {...field}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      <FormError show={isError} message={helperText} />
    </>
  )
}

export default Select
