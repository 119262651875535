import React from "react"
import Authbox from "components/Common/auhbox"
import AuthHeader from "components/Common/auth-header"
import ResetPasswordForm from "components/Forms/reset-password-form"
import withHOC from "utils/with-hoc"
import {
  ResetPasswordPageProvider,
  useResetPasswordPageContext,
} from "./provider"

function ResetPasswordPage() {
  //meta title
  document.title = "Reset Password"

  const { handleResetPassword } = useResetPasswordPageContext()

  return (
    <div className="layout-wrapper auth-layout">
      <div id="register-page" className="main">
        <AuthHeader />
        <div className="main-content">
          <div className="page-content">
            <Authbox>
              <ResetPasswordForm onSubmit={handleResetPassword} />
            </Authbox>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withHOC(ResetPasswordPageProvider, ResetPasswordPage)
