import Info from "components/Common/Info"
import Heading from "components/VerticalLayout/Heading"
import React, { useMemo } from "react"
import { Button, Card, Col, Container, Row } from "reactstrap"
import AddShipForm from "components/Forms/add-ship-form"
import ModalContainer from "components/Common/modal-container"
import VirtualizeTable from "components/Common/virtualiza-table"
import SearchInput from "components/search-input"
import withHOC from "utils/with-hoc"
import { MyFleetPageProvider, useMyFleetPageContext } from "./provider"
import NoRecords from "components/Common/no-records"
import DetailsCard from "./top-card"

const renderNoDataRows = () => {
  return <NoRecords message="No ships found" />
}

const MyFleetPage = () => {
  //meta title
  document.title = "My Fleet"

  const {
    listRef,
    isAddShipModalOpen,
    columns,
    getShipsQuery,
    getCompanyQuery,
    handleOnRowClick,
    handleAddShip,
    handleEditShipClick,
    handleCloseAddShipModalClick,
    isHasMorePages,
    loadingRowCount,
    handleLoadMore,
    activeMenuRowIndex,
    search,
    handleSearchChange,
    getCompanyLogoQuery,

  } = useMyFleetPageContext()

  const activeRow = useMemo(
    () => getShipsQuery?.data?.[activeMenuRowIndex],
    [activeMenuRowIndex, getShipsQuery?.data]
  )

  const logoUrl = useMemo(()=>{
    return  getCompanyLogoQuery?.data?.data;
  },[getCompanyLogoQuery?.data?.data]) 


  return (
    <>
      <Heading title="My Fleet" breadcrumbItem="breadcrumb" />
      <div id="my-fleet-page" className="page-content theme-white-bg">
        <Container fluid={true} className="full-box">
          <div className="main">
            <DetailsCard data={getCompanyQuery?.data?.data} logoUrl={logoUrl} />

            <div className="section">
              <div className="section-heading">
                <div className="section-heading_left">
                  <div>
                    <SearchInput
                      value={search}
                      onChange={handleSearchChange}
                      placeholder="Search"
                      type="text"
                      className="search-input"
                      iconLeft
                    />
                  </div>
                </div>
                <div className="section-heading_right">
                  <div className="section-heading_actions">
                    <Button
                      type="button"
                      color="success"
                      onClick={handleEditShipClick}
                      className="rounded-10"
                    >
                      <i className="bx bx-plus font-size-16 align-middle me-2"></i>
                      Add Ship
                    </Button>
                  </div>
                </div>
              </div>
              <div className="section-body">
                <VirtualizeTable
                  ref={listRef}
                  rows={getShipsQuery.data}
                  columns={columns}
                  hasMore={isHasMorePages}
                  loadMore={handleLoadMore}
                  isLoading={getShipsQuery?.isPending}
                  loadingRowCount={loadingRowCount}
                  isLoadMoreDirty={!getShipsQuery?.isIdle}
                  renderNoDataRows={renderNoDataRows}
                  onRowClick={handleOnRowClick}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <ModalContainer
        isOpen={isAddShipModalOpen}
        title={activeRow ? "Edit Ship" : "Add Ship"}
        handleClose={handleCloseAddShipModalClick}
      >
        <AddShipForm
          data={activeRow}
          onSubmit={handleAddShip}
          onClose={handleCloseAddShipModalClick}
        />
      </ModalContainer>
    </>
  )
}

export default withHOC(MyFleetPageProvider, MyFleetPage)
