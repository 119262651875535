import React from "react"
import { Form, Formik } from "formik"
import { Button, Col, Row } from "reactstrap"
import { FormikInput } from "components/FormControls/input"
import FormLabel from "components/FormControls/form-label"
import {
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
} from "./form-helper"
import { generatePath, Link, useNavigate } from "react-router-dom"

export default function LoginForm(props) {
  const { onSubmit } = props

  const navigate = useNavigate()

  const intitialValues = generateInitialValues({})
  const validationSchema = generateValidationSchema({})

  const handleOnSubmit = (formData, formikContext) => {
    const payload = generatePayload(formData)
    onSubmit && onSubmit(payload, formikContext)
  }

  const handleRegisterClick = () => {
    const redirectLink = generatePath("/register")
    navigate(redirectLink)
  }
  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {() => {
        return (
          <Form className="login-form form">
            <div className="form-title">Welcome to India-FCR</div>
            <div className="form-body">
              <div className="form-body_top">
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <FormLabel required>Email</FormLabel>
                      <FormikInput name={FN.EMAIL} />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="mb-3">
                      <FormLabel required>Password</FormLabel>
                      <FormikInput type="password" name={FN.PASSWORD} />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="form-body_bottom">
                <Row>
                  <Col xs={12} className="mb-3">
                    <Button color="success" type="submit" className="w-full rounded-10">
                      Login
                    </Button>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Button
                      color="success"
                      outline
                      type="button"
                      className="w-full rounded-10"
                      onClick={handleRegisterClick}
                    >
                      Register here
                    </Button>
                  </Col>
                  <Col xs={12} className="d-flex justify-content-between">
                    <span></span>
                    <Link to="/forgot-password">Forgot your Password?</Link>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
