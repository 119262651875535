import * as validations from "../../../helpers/validation-helper"
import * as yup from "yup"

export const FN = {
  COMPANY_NAME: "company_name",
  COMPANY_NUMBER: "comapny_number",
  COMPANY_ADDRESS: "company_address",
  COUNTRY: "country",
  PRINCIPAL_PLACE_OF_BUSINESS: "principal_place_of_bussiness",
  CONTACT_PERSON: "contact_person",
}

export const generateInitialValues = (data = {}) => {
  return {
    [FN.COMPANY_NAME]: data.name,
    [FN.COMPANY_ADDRESS]: data.address,
    [FN.COUNTRY]: data.country_id,
    [FN.PRINCIPAL_PLACE_OF_BUSINESS]: data.principal_place_of_business,
    [FN.CONTACT_PERSON]: data.contact_person_id,
  }
}

export const generateValidationSchema = () => {
  return yup.object().shape({
    [FN.COMPANY_NAME]: validations.isRequiredString(),
    [FN.COMPANY_ADDRESS]: validations.isRequiredString(),
    [FN.COUNTRY]: validations.isRequiredString(),
    [FN.PRINCIPAL_PLACE_OF_BUSINESS]: validations.isRequiredString(),
    [FN.CONTACT_PERSON]: validations.isRequiredString(),
  })
}

export const generatePayload = formData => {
  return {
    name: formData[FN.COMPANY_NAME],
    address: formData[FN.COMPANY_ADDRESS],
    country_id: formData[FN.COUNTRY],
    principal_place_of_business: formData[FN.PRINCIPAL_PLACE_OF_BUSINESS],
    contact_person_id: formData[FN.CONTACT_PERSON],
  }
}
