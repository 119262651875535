import React from "react"
import Const from "../../constants"
import { useMemo } from "react"

function NotificationIcon(props) {
  const { type } = props

  const icon = useMemo(() => {
    const style = {
      width: "24px",
      height: "24px",
      color: "#34c38f",
      fontWeight: "bold",
      fontSize: "44px",
    }

    switch (type) {
      case Const.NOTIFICATION_TYPE.SUCCESS:
        return <i className="bx bx-check" style={style}></i>
      case Const.NOTIFICATION_TYPE.ERROR:
        return (
          <i className="mdi mdi-close" style={{ ...style, color: "red" }}></i>
        )
      case Const.NOTIFICATION_TYPE.INFO:
        return (
          <i
            className="bx bx-info-circle"
            style={{ ...style, color: "blue" }}
          ></i>
        )
      case Const.NOTIFICATION_TYPE.PROGRESS:
        return (
          <div role="status" className="ms-2 spinner-border text-primary">
            <span className="visually-hidden">Loading...</span>
          </div>
        )

      case Const.NOTIFICATION_TYPE.WARNING:
        return <i className="bx bx-error " style={{ ...style, color: "#d5d500" }}></i>
      default:
        return null
    }
  }, [type])

  return <div className="notification-modal_icon ">{icon}</div>
}

export default NotificationIcon
