import ModalContainer from "components/Common/modal-container"
import AddCompanyForm from "components/Forms/edit-company-form"
import AddUserForm from "components/Forms/add-user-form"
import Heading from "components/VerticalLayout/Heading"
import React, { useMemo } from "react"
import { Button, Container } from "reactstrap"
import withHOC from "utils/with-hoc"
import CompnayDetailsBox from "./company-details-box"
import { ProfilePageProvider, useProfilePageContext } from "./provider"
import UserDetailsBox from "./user-details-box"
const ProfilePage = () => {
  const {
    getUserInfoQuery,
    isEditCompanyModalOpen,
    isEditUserModalOpen,
    handleEditCompany,
    handleCloseEditCompanyModalClick,
    handleEditCompanyClick,
    handleEditUser,
    handleCloseEditUserModalClick,
    handleEditUserClick,
    getCompanyLogoQuery,
  } = useProfilePageContext()

  const logoUrl = useMemo(() => {
    return getCompanyLogoQuery?.data?.data
  }, [getCompanyLogoQuery?.data?.data])

  return (
    <>
      <Heading title="Profile" />
      <div id="profile-page" className="page-content">
        <Container fluid={true} className="full-box">
          <div className="main">
            <div className="section">
              <div className="section-heading d-flex justify-content-between">
                <p className="section-title">User Profile</p>
                <Button color="success" onClick={handleEditUserClick} className="rounded-10 w-100">
                  Edit
                </Button>
              </div>
              <div className="section-body">
                <UserDetailsBox data={getUserInfoQuery?.data?.data} />
              </div>
            </div>

            <div className="section">
              <div className="section-heading d-flex justify-content-between">
                <p className="section-title">Company</p>
                <Button color="success" onClick={handleEditCompanyClick} className="rounded-10 w-100">
                  Edit
                </Button>
              </div>
              <div className="section-body">
                <CompnayDetailsBox
                  data={getUserInfoQuery?.data?.data?.company}
                  logoUrl={logoUrl}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isEditCompanyModalOpen}
        title="Edit Company Details"
        handleClose={handleCloseEditCompanyModalClick}
      >
        <AddCompanyForm
          logoUrl={logoUrl}
          data={getUserInfoQuery?.data?.data?.company}
          onSubmit={handleEditCompany}
          onClose={handleCloseEditCompanyModalClick}
        />
      </ModalContainer>

      <ModalContainer
        isOpen={isEditUserModalOpen}
        title="Edit My Details"
        handleClose={handleCloseEditUserModalClick}
      >
        <AddUserForm
          data={getUserInfoQuery?.data?.data}
          onSubmit={handleEditUser}
          onClose={handleCloseEditUserModalClick}
        />
      </ModalContainer>
    </>
  )
}

export default withHOC(ProfilePageProvider, ProfilePage)
