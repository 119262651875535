import * as React from "react"

function ThreeDotsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={4}
      height={11}
      viewBox="0 0 4 11"
      fill="none"
      {...props}
    >
      <path
        d="M3.103 9.73c0 .337-.127.66-.352.898a1.174 1.174 0 01-.852.372c-.319 0-.625-.134-.851-.372a1.305 1.305 0 01-.353-.897c0-.337.127-.66.353-.898.226-.238.532-.371.851-.371.32 0 .626.133.852.371.225.238.352.561.352.898zm0-4.23c0 .337-.127.66-.352.897a1.173 1.173 0 01-.852.372c-.319 0-.625-.133-.851-.372A1.305 1.305 0 01.695 5.5c0-.337.127-.66.353-.897.226-.239.532-.372.851-.372.32 0 .626.133.852.372.225.238.352.56.352.897zm0-4.23c0 .336-.127.659-.352.897a1.173 1.173 0 01-.852.371c-.319 0-.625-.133-.851-.371a1.305 1.305 0 01-.353-.898c0-.336.127-.66.353-.897C1.274.134 1.58 0 1.899 0c.32 0 .626.134.852.372.225.238.352.56.352.897z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ThreeDotsIcon
