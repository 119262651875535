const Enums = {
  OPTION_YES_NO: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  OTHER_OPTION: {
    label: "Other",
    value: null,
  },
  OPTION_YES_NO_NA: [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
    { label: "N/A", value: "na" },
  ],
}

export default Enums  
