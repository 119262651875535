import React, { useMemo } from "react"
import { Form, Formik } from "formik"
import { FormikSelect } from "../../FormControls/select"
import { Button, Col, Row } from "reactstrap"
import { FormikInput } from "components/FormControls/input"
import FormLabel from "components/FormControls/form-label"
import {
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
} from "./form-helper"
import { FormikTextarea } from "components/FormControls/textarea"
import withHOC from "utils/with-hoc"
import { EditCompanyFormProvider, useEditCompanyFormContext } from "./provider"
import { useUserFromStorage } from "contexts/user.context"
import IfcrDropZone from "components/drop-zone"

function EditCompanyForm(props) {
  const { user } = useUserFromStorage()
  const { onSubmit, onClose, data = {} } = props
  const isEditMode = Object.keys(data).length !== 0

  const {
    file,
    handleClearFile,
    handleOnDrop,
    getUsersQuery,
    getCountriesQuery,
    handleCancelFormClick,
  } = useEditCompanyFormContext()

  const countryList = (getCountriesQuery?.data?.data?.data || []).map(item => {
    return {
      label: item.name,
      value: item.id,
    }
  })

  const userList = [user, ...(getUsersQuery?.data?.data?.data || [])].map(
    item => {
      return {
        label: item.name,
        value: item.id,
      }
    }
  )

  const intitialValues = useMemo(
    () => generateInitialValues(data, isEditMode),
    [data, isEditMode]
  )

  const validationSchema = useMemo(
    () => generateValidationSchema(isEditMode),
    [isEditMode]
  )

  const handleOnSubmit = (formData, formikContext) => {
    const payload = generatePayload(formData)
    payload.file = file
    onSubmit && onSubmit(payload, formikContext)
  }

  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {formik => {
        return (
          <Form className="add-company-form form">
            <div className="modal-body">
              <Row>
                <Col xs={4}>
                  <div className="mb-3">
                    <FormLabel required>Company Name</FormLabel>
                    <FormikInput name={FN.COMPANY_NAME} />
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-3">
                    <FormLabel required>Contact Person</FormLabel>
                    <FormikSelect
                      name={FN.CONTACT_PERSON}
                      options={userList}
                      labelString="label"
                      valueString="value"
                    />
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-3">
                    <FormLabel required>Country</FormLabel>
                    <FormikSelect
                      name={FN.COUNTRY}
                      options={countryList}
                      labelString="label"
                      valueString="value"
                    />
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-3">
                    <FormLabel required>Principal Place of Business</FormLabel>
                    <FormikInput name={FN.PRINCIPAL_PLACE_OF_BUSINESS} />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="mb-3">
                    <FormLabel required>Company Address</FormLabel>
                    <FormikTextarea name={FN.COMPANY_ADDRESS} minRows={2} />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="mb-3">
                    <FormLabel>Company Logo</FormLabel>
                    <IfcrDropZone
                      file={file}
                      onClear={handleClearFile}
                      onDrop={handleOnDrop}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="actions modal-footer">
              <div className="actions-left">
                <Button
                  color="success"
                  type="button"
                  outline
                  onClick={onClose}
                  className="action-button rounded-10"
                >
                  Close
                </Button>
              </div>
              <div className="actions-right">
                {formik.dirty && (
                  <Button
                    color="success"
                    type="button"
                    outline
                    className="action-button rounded-10"
                    onClick={handleCancelFormClick(formik)}
                  >
                    Cancel
                  </Button>
                )}
                <Button color="success" type="submit" className="action-button rounded-10">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default withHOC(EditCompanyFormProvider, EditCompanyForm)
