import React from "react"
import cx from "../../utils/cx"
import { useNotificationModalContext } from "./provider"
import NotificationIcon from "./notification-icon"
import { Button } from "reactstrap"

function Modal(props) {
  const { children } = props

  const notificationModal = useNotificationModalContext()

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={cx(
        "notification-modal ",
        notificationModal.open && "notification-modal_open"
      )}
    >
      {children}
    </div>
  )
}

function ModalBody() {
  const {
    type,
    title,
    heading,
    body,
    classNames = {},
  } = useNotificationModalContext()

  return (
    <div className="notification-modal_body">
      <NotificationIcon type={type} />
      {title ? <p className="notification-modal_title">{title}</p> : null}
      {heading ? (
        <p
          className={cx(
            "notification-modal_heading",
            classNames?.heading,
            title && "with-title"
          )}
        >
          {heading}
        </p>
      ) : null}
      {body ? (
        <p className={cx("notification-modal_body ", classNames?.body)}>
          {body}
        </p>
      ) : null}
    </div>
  )
}

function ModalFooter() {
  const {
    confirmText,
    cancelText,
    closeText,
    onConfirm,
    onCancel,
    onClose,
    classNames,
    showConfirmCTA,
    showCancelCTA,
    showCloseCTA,
  } = useNotificationModalContext()

  return (
    <div className={cx("notification-modal_footer", classNames?.footer)}>
      {showConfirmCTA ? (
        <>
          {showCancelCTA ? (
            <Button
              onClick={onCancel}
              color="success"
              outline
              className={cx("rounded-10",showCancelCTA && "mr-1")}
            >
              {cancelText}
            </Button>
          ) : null}

          <Button
            onClick={onConfirm}
            color="success"
            className={cx("rounded-10",showCancelCTA && "mr-2")}
          >
            {confirmText}
          </Button>
        </>
      ) : null}

      {showCloseCTA ? (
        <Button onClick={onClose} color="success" outline className="rounded-10">
          {closeText}
        </Button>
      ) : null}
    </div>
  )
}

Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
