import React from "react"
//__TODO__: restructure error messages by module,
//__TODO__: move non message keys into constants
const MESSAGES = {
  SEND_TO_REVIEW_SUCCESS: "Sent for review",
  SEND_TO_REVIEW_FAILED: "Send for review failed",
  SEND_TO_REVIEW_PROGRESS: "Sending for review",

  FORM_MAPPING_SUCCESS: "Field mappings saved",
  FORM_MAPPING_FAILED: "Failed to save field mappings",
  FORM_MAPPING_PROGRESS: "Saving field mappings",

  SURVEY_SELECT_MESSAGE: "Please select survey type and category",

  FORM_DATA_SUCCESS: "Form saved",
  FORM_DATA_PROGRESS: "Saving form",
  FORM_DATA_FAILED: "Failed to save form",

  SYNC_OFFLINE_FAILED: "Sync to offline application failed",
  SYNC_OFFLINE_SUCCESS:
    "Data is now ready in desktop application for offline survey",

  NO_FORMS: "No forms found",
  NO_FORM: "No form found",
  NO_SURVEY: "No reports found",

  ADD_VISIT_PLANNING_FAILED: "Failed to save visit planning",

  REPORT_NO_GENERATED: "Report number has been generated",
  REPORT_NO_GENERATING: "Report number is being generated",
  REPORT_NO_GENERATE_FAILED: "Failed to generate report number",

  SEND_TO_DGS_PROGRESS: "Sending to DGS",
  SEND_SUCCESS: "Sent",
  SEND_DGS_FAILED: "Sending to DGS failed",

  SEND_AUTH_PROGRESS: "Sending authorisation letter",
  SEND_AUTH_FAILED: "Sending authorisation letter failed",
  SEND_AUTH_SUCCESS: "Authorisation letter sent",

  SAVE_AUTH_LETTER_PROGRESS: "Saving authorisation letter",
  SAVE_AUTH_LETTER_SUCCESS: "Authorisation letter saved",
  SAVE_AUTH_LETTER_FAILED: "Failed to save authorisation letter",

  SAVE_AUTH_LETTER_TEMPLATE_PROGRESS: "Saving authorisation letter template",
  SAVE_AUTH_LETTER_TEMPLATE_SUCCESS: "Authorisation letter template saved",
  SAVE_AUTH_LETTER_TEMPLATE_FAILED:
    "Failed to save authorisation letter template",

  SEND_DOC_REQ_PROGRESS: "Sending Document Request",
  SEND_DOC_REQ_SUCCESS: "Document Request Sent",
  SEND_DOC_REQ_FAILED: "Document Request Failed",

  SIGN_IN_PROGRESS: "Signing in",
  SIGN_IN_WRONG_DATA: "	The username or password you entered is incorrect",
  SIGN_IN_FAILED: "Bad Request. Please try again later.",

  RETURN_PROGRESS: "Returning for amendment",
  RETURN_SUCCESS: "Returned for amendment",
  RETURN_ERROR: "Returning for amendment failed",

  DELETE_PROGRESS: "Deleting internal auditors",
  DELETE_SUCCESS: "Internal auditor deleted",
  DELETE_ERROR: "Failed to delete internal auditor",

  SAVE_VISIT_PROGRESS: "Saving visit details",
  SAVE_VISIT_SUCCESS: "Visit details saved",
  SAVE_VISIT_ERROR: "Failed to save visit details",

  UPDATE_EVENT_PROGRESS: "Updating event",
  UPDATE_EVENT_SUCCESS: "Event updated",
  UPDATE_EVENT_ERROR: "Failed to update",

  ADD_EVENT_PROGRESS: "Adding event",
  ADD_EVENT_SUCCESS: "Event added",
  ADD_EVENT_ERROR: "Failed to add event",

  SAVE_EVENT_PROGRESS: "Saving event",
  SAVE_EVENT_SUCCESS: "Event saved",
  SAVE_EVENT_ERROR: "Failed to save event",

  REMOVE_EVENT_PROGRESS: "Removing event",
  REMOVE_EVENT_SUCCESS: "Event removed",
  REMOVE_EVENT_ERROR: "Failed to remove event",

  SAVE_AUDITS_PROGRESS: "Saving audit details",
  SAVE_AUDITS_SUCCESS: "Audit details saved",
  SAVE_AUDITS_ERROR: "Saving audit details failed",

  UPDATE_SURVEY_PROGRESS: "Updating survey status report",
  UPDATE_SURVEY_SUCCESS: "Survey status report updated",
  UPDATE_SURVEY_ERROR: "Failed to update survey status report",

  DOWNLOAD_PROGRESS: "Downloading",
  DOWNLOAD_SUCCESS: "Downloaded successfully",
  DOWNLOAD_FAILED: "Failed to download",

  PREVIEW_SUCCESS: "Preview success",
  PREVIEW_PROGRESS: "Generating preview",
  PREVIEW_FAILED: "Failed to preview",

  PLEASE_SELECT_AUDITOR: "Please select auditor",

  ASSIGNED_AUDITOR_SUCCESS: "Auditor assigned",
  ASSIGNED_AUDITOR_PROGRESS: "Assigning auditor",
  ASSIGN_AUDITOR_FAILED: "Failed to assign auditor",

  REPORT_NUMBER_NOT_GENERATED: "Report number ",

  NO_RECORDS_FOUND_FOR_SEARCH:
    "No data found. Please try changing the search criteria.",

  NO_RECORDS_FOUND: "No records found",
  NO_RECORDS_FOUND_FOR_FORM_BUILDER: "No forms found",

  SAVING_CONTRACT_REVIEW_PROGRESS: "Saving Contract Review",
  SAVING_CONTRACT_REVIEW_SUCCESS: "Contract Review saved",
  SAVING_CONTRACT_REVIEW_FAILED: "Failed to save Contract Review",

  AMEND_CONTRACT_REVIEW_PROGRESS: "Amending Contract Review",
  AMEND_CONTRACT_REVIEW_SUCCESS: "Contract Review amended",
  AMEND_CONTRACT_REVIEW_FAILED: "Failed to amend Contract Review",

  UPLOAD_DOC_PROGRESS: "Uploading document",
  UPLOAD_DOC_SUCCESS: "Document uploaded",
  UPLOAD_DOC_FAILED: "Document uploading failed",

  DELETE_DOC_CONFIRMATION:
    "The document will be deleted and cannot be retrieved",
  DELETE_DOC_PROGRESS: "Deleting document",
  DELETE_DOC_SUCCESS: "Document deleted",
  DELETE_DOC_FAILED: "Failed to delete document",

  SAVE_DOC_PROGRESS: "Adding document",
  SAVE_DOC_SUCCESS: "Document added",
  SAVE_DOC_FAILED: "Failed to save document",

  APPROVE_PROGRESS: "Approving",
  APPROVE_SUCCESS: "Approved",
  APPROVE_FAILED: "Failed to approve",

  AUTHORIZE_PROGRESS: "Authorizing",
  AUTHORIZE_SUCCESS: "Audit has been completed",
  AUTHORIZE_ERROR: "Failed to authorize",

  UPDATE_REMARK_PROGRESS: "Updating remark",
  UPDATE_REMARK_SUCCESS: "Remark updated",
  UPDATE_REMARK_ERROR: "Failed to update remark",

  REMARK_PROGRESS: "Adding remark",
  REMARK_SUCCESS: "Remark added",
  REMARK_ERROR: "Failed to add remark",

  REMOVE_REMARK_PROGRESS: "Removing remark",
  REMOVE_REMARK_SUCCESS: "Remark removed",
  REMOVE_REMARK_ERROR: "Failed to remove remark",

  FORM_DATA_SUBMIT_SUCCESS: "Data saved",
  FORM_DATA_SUBMIT_PROGRESS: "Saving form data",
  FORM_DATA_EMAIL_SENT_SUCCESS: "Mail sent successfully",

  DELETING_SUB_FORM_PROGRESS: "Deleting form",
  SUB_FORM_DELETE_FAILED: "Failed to delete form",

  FORM_DATA_SUBMIT_FAILED: "Failed to save form data",

  DELETE_EVENT_PROGRESS: "Deleting event",
  DELETE_EVENT_SUCCESS: "Event deleted",
  DELETE_EVENT_ERROR: "Failed to delete event",

  THIS_FIELD_IS_REQUIRED: "This field is required",

  FORM_SUCCESSFULLY_RESET: "Successfully reset",

  SURVEY_VISIT_LIMIT_WARNING: "You enter more minutes then actual.",

  ARE_YOU_SURE: "Are You Sure?",

  REPORT_NUMBER_FOR_AUDIT_GENERATED:
    "Report Number for this audit will be generated",

  YOU_MAY_WILL_LOSE_YOUR_DATA: "The changes that you made may not be saved.",
  YOU_MAY_WILL_LOSE_YOUR_DATA_ON_CANCEL: "You may lose your data if you cancel",

  CANCEL: "Cancel",
  YES: "Yes",
  OK: "Ok",
  NO: "No",

  NO_DOCUMENT_AVAILABLE_REQUEST:
    "No documents were attached/uploaded during audit/survey request",
  NO_DOCUMENT_AVAILABLE_SURVEY:
    "No documents were attached/uploaded during audit/survey",

  DO_YOU_WANT_TO_LEAVE: "Do you want to leave the page?",

  AUTHORISATION_LETTER_SENT_TO_DGS: "Authorisation letter sent to DGS.",
  FAILED_TO_SEND_AUTHORISATION_LETTER: "Failed to send authorisation letter.",

  SAVE_DOCUMENT_PROGRESS: "Adding document",
  SAVE_DOCUMENT_FAILED: "Adding document failed",
  SAVE_DOCUMENT_SUCCESS: "Document added",
  DOCUMENT_EXTRACTING: "Document Extracting",
  DOCUMENT_EXTRACTED: "Document Extracted",
  FAILED_DOCUMENT_EXTRACT: "Failed to Extract Document",

  DELETE_DOCUMENT_PROGRESS: "Removing document",
  DELETE_DOCUMENT_SUCCESS: "Document removed",
  DELETE_DOCUMENT_CONFIRMATION: "Do you want to remove this document?",
  DELETE_DOCUMENT_FAILED: "Failed to remove document",

  REJECT_CONTRACT_REVIEW_MESSAGE: "Contract Review will be rejected",
  REJECT_CONTRACT_REVIEW_PROGRESS: "Rejecting Contract Review",
  REJECT_CONTRACT_REVIEW_FAILED: "Failed to reject Contract Review",
  REJECT_CONTRACT_REVIEW_SUCCESS: "Contract Review rejected",

  ONHOLD_CONTRACT_REVIEW_MESSAGE: "Contract Review will be on hold",
  ONHOLD_CONTRACT_REVIEW_PROGRESS: "Placing Contract Review on hold",
  ONHOLD_CONTRACT_REVIEW_FAILED: "Failed to place Contract Review on hold",
  ONHOLD_CONTRACT_REVIEW_SUCCESS: "Contract Review On hold",

  CONTRACT_REVIEW_ACCEPTED: "Contract Review accepted",
  CONTRACT_REVIEW_SAVED: "Contract Review saved",
  ACCEPT_AMENDMENT_REQUEST: "You want to accept amendment request audit?",

  AUDIT_ALREADY_ACCEPTED_ALERT:
    "You cannot make any changes as this audit is already “Accepted”",
  AUDIT_REPORT_NO_GENERATED_ALERT:
    "You cannot make any changes as the Survey Report Number has generated.",

  REQUIRED_FIELD: "This field is required",

  THIS_FIELD_SHOULD_GRETER_THAN: "This field should be greater than",

  AMEND_AUTH_PROGRESS: "Sending amended authorisation letter",
  AMEND_AUTH_SUCCESS: "Amended authorisation letter sent",
  AMEND_AUTH_FAILED: "Failed to send amended authorisation letter",

  DO_YOU_WANT_TO_REMOVE_THIS_AUDIT_DETAILS:
    "Do you want to remove this visit schedule entry?",
  DO_YOU_WANT_TO_REMOVE_THIS_VISIT_DETAIL:
    "Do you want to remove this visit detail?",

  SAVING_AUDITORS_DETAILS: "Saving auditor details",
  AUDITORS_DETAILS_SAVED: "Auditor details saved",

  SAVING_BLOCK_FEE_DETAILS: "Saving block fee details",
  BLOCK_FEE_DETAILS_SAVED: "Block fee details saved.",

  DO_YOU_WANT_TO_REMOVE_THIS_BLOCK_FEE_DETAILS:
    "Do you want to remove this block fee details?",
  DO_YOU_WANT_TO_REMOVE_THIS_AUDITOR_DETAILS:
    "Do you want to remove this auditor details?",

  SUBMITING_AUDITS_PROGRESS: "Saving audit visit schedule details",
  SUBMIT_AUDITS_SUCCESS: "Audit visit schedule details saved",

  SUBMITING_VISIT_DETAILS_PROGRESS: "Saving visit schedule details",
  SUBMIT_VISIT_DETAILS_SUCCESS: "Visit details saved",

  THIS_FIELD_SHOULD_BE_PAST_DATE: "This field should be a past date",

  KINDLY_REFINE_THE_SEARCH_CRITERIA: "Kindly refine the search criteria",

  CONTRACT_REVIEW_WILL_BE_CANCELLED: "Contract Review will be cancelled",
  CANCELLED_CONTRACT_REVIEW_PROGRESS: "Cancelling Contract Review",
  CANCELLED_CONTRACT_REVIEW_SUCCESS: "Contract Review cancelled",
  CANCELLED_CONTRACT_REVIEW_FAILED: "Cancelling Contract Review failed",

  HO_REVIEWER_ASSIGNED: "HO Reviewer assigned",
  HO_REVIEWER_REASSIGNED: "HO Reviewer reassigned",

  PROJECT_REMARKS_ADDED: "Project remarks added",
  DELETE_REPORT_REMARK: "This remark will be deleted",

  SUBMIT_VISIT_DETAILS_FAILED: "Failed to save visit schedule details",

  BLOCK_FEE_DETAILS_SAVED_FAILED: "Failed to save block fee details",

  BLOCK_FEE_DETAILS_DELETE_PROGRESS: "Deleting block fee details",
  BLOCK_FEE_DETAILS_DELETED: "Deleted block fee detail",

  VISITED_DETAILS_DELETED_PROGRESS: "Deleting visit details",
  VISITED_DETAILS_DELETED_SUCCESS: "Deleted visit details",

  CONTRACT_REVIEW_ALREADY_ACCEPTED_ALERT: `You cannot make any changes as this Contract Review is 'Accepted'`,
  CONTRACT_REVIEW_ALREADY_REJECTED_ALERT: `You cannot make any changes as this Contract Review is 'Rejected'`,
  CONTRACT_REVIEW_ALREADY_PENDING_ALERT: `You cannot make any changes as this Contract Review is 'On Hold'`,
  CONTRACT_REVIEW_ASSIGNEE_NOT_AVAILABLE_ALERT:
    "You cannot make any changes as the examiner is not assigned yet",

  AUDITORS_DETAILS_DELETING: "Deleting auditor details",
  AUDITORS_DETAILS_DELETING_SUCESSS: "Auditor details deleted",
  AUDITORS_DETAILS_DELETE_FAILED: "Failed to delete auditor details",

  BLOCK_FEE_DELETED_FAILED: "Failed to delete block dee details",

  AUDIT_VISIT_DELETE_PROGRESS: "Deleting audit visit details",
  AUDIT_VISIT_DELETE_SUCCESS: "Deleted audit details",
  AUDIT_VISIT_DELETE_FAILED: "Failed to delete audit visit details",

  ADD_AUDITORS_FAILED: "At least one team leader must be assigned",
  ADD_BLOCK_FEE_FAILED: "Failed to add block fee details",
  ADD_VISIT_SCHEDULE_FAILED: "Failed to add audit visit details",

  CONTRACT_REVIEW_ON_HOLD: "Contract Review marked 'On Hold'",
  CONTRACT_REVIEW_ON_HOLD_PROGRESS: "Marking Contract Review 'On Hold'",
  CONTRACT_REVIEW_ACCEPTED_PROGRESS: "Accepting Contract Review",
  CONTRACT_REVIEW_SAVED_PROGRESS: "Saving Contract Review",

  PLEASE_FILL_THE_SURVEY_TYPE: "Please fill the survey type",

  SEND_FOR_REVIEW_PROGRESS: "Sending for review",
  SEND_FOR_REVIEW_SUCCESS: "Sent for review",
  SEND_FOR_REVIEW_FAILD: "Failed to sent for review",

  FORM_SUBMITTED: "Form submitted",
  SUB_FORM_DELETED: "Form deleted",

  ONCE_THE_FORM_IS_SUBMITTED_IT_CANNOT_BE_EDITED_LATER:
    "Once the form is submitted, it cannot be edited later",

  CONFIRM_REQUEST_DOCUMENT: "Are you sure you want to request these documents",

  NO_DETENTION_FOUND: "No Detention Details Found",
  NO_DEFICIENCES_FOUND: "No Deficiencies Found",

  VALIDATION_STRING_MSG: "The value must be string",
  VALIDATION_DATE_MSG: "The value must be date",
  VALIDATION_NUMBER_MSG: "The value must be numeric",
  VALIDATION_CHARACTER_MSG: "The value must be character",
  VALIDATION_BOOL_MSG: "The value must be bool",

  MARKING_LAST_DAY_OF_VISIT_PROGRESS: "Marking last day of visit",
  MARKING_LAST_DAY_OF_VISIT_SUCCESS: "Marked last day of visit",
  UNMARK_VISIT_SUCCESS: "Unmark last day of visit",
  UNMARK_VISIT_FAILED: "Failed unmark last day of visit",
  MARKING_LAST_DAY_OF_VISIT_FAILED: "Failed to marking last day of visit",
  MARKING_LAST_DAY_OF_VISIT_CONFIRMATION:
    "You need to mark last visit date of audit",
  UNMARKING_LAST_DAY_OF_VISIT_CONFIRMATION:
    "You need to unmark last visit date of audit",

  SESSION_EXPIRED: {
    TITLE: "Session Expired.",
    HEADING: "You will be redirected to the Login page",
  },

  ACCESS_DENIED: {
    TITLE: "Access is Denied",
    HEADING: (
      <>
        You do not have permission to access this resource. Please check with{" "}
        <span className="uppercase">IFCR</span> Support Team.
      </>
    ),
  },

  TECHNICAL_ERROR: {
    TITLE: "Technical Error",
    HEADING: (
      <>
        Please try again later or check with{" "}
        <span className="uppercase">IFCR</span> Support Team.
      </>
    ),
  },
  APPROVE_REPORT_REVIEW_PROGRESS: "Approving for Report Review",
  APPROVE_REPORT_REVIEW_SUCCESS: "Report Review Approved",
  APPROVE_REPORT_REVIEW_FAILED: "Failed to Approve Report Review",

  APPROVE_AUDIT_REVIEW_PROGRESS: "Approving for Audit Report",
  APPROVE_AUDIT_REVIEW_SUCCESS: "Audit Review Approved",
  APPROVE_AUDIT_REVIEW_FAILED: "Failed to Approve Audit Report",

  TAWA_CLIENT_REQUEST_FORM_SUCCESS:
    "Your request has been submitted.  We will get back to you at the earliest.",

  AMEND_CERTIFICATE_PROGRESS: "Amending Certificate",
  AMEND_CERTIFICATE_SUCCESS: "Certificate is Amended",
  AMEND_CERTIFICATE_FAILED: "Failed to Amend Certificate",

  UPDATE_CERTIFICATE_PROGRESS: "Certificate is Updating",
  UPDATE_CERTIFICATE_SUCCESS: "Certificate is Udpated",
  UPDATE_CERTIFICATE_FAILED: "Failed to Update Certificate",

  ADD_CERTIFICATE_PROGRESS: "Certificate is Adding",
  ADD_CERTIFICATE_SUCCESS: "Certificate is Added",
  ADD_CERTIFICATE_FAILED: "Failed to Add Certificate",

  ISSUE_DUPLICATE_CERT_PROGRESS: "Issuing Duplicate Certificate",
  ISSUE_DUPLICATE_CERT_FAILED: "Failed to Issue Duplicate Certificate",
  ISSUE_DUPLICATE_CERT_SUCCESS: "Duplicate Certificate is Issued",

  ISSUE_CERT_PROGRESS: "Issuing Certificate",
  ISSUE_CERT_FAILED: "Failed to Issue Certificate",
  ISSUE_CERT_SUCCESS: "Certificate is Issued",

  ACITIVITY_MONITORING_SAVE_PROGRESS: "Saving Activity Monitoring Details",
  ACITIVITY_MONITORING_SAVE_SUCCESS: "Activity Monitoring Details Saved",
  ACITIVITY_MONITORING_SAVE_FAILED: "Saving of Activity Monitoring Failed",

  ACITIVITY_MONITORING_SUBMIT_PROGRESS:
    "Submitting Activity Monitoring Details",
  ACITIVITY_MONITORING_SUBMIT_SUCCESS: "Activity Monitoring Details Submitted",
  ACITIVITY_MONITORING_SUBMIT_FAILED:
    "Activity Monitoring Data Submission Failed ",

  GENERATE_CERTIFICATE_PROGRESS: "Certificate is being generated",
  GENERATE_CERTIFICATE_SUCCESS: "Certificate is generated",
  GENERATE_CERTIFICATE_FAILED: "Failed to generate Certificate",
  GENERATE_CERTIFICATE_WARNING: "Please add atleast one digital signature",

  UPDATE_PROJECT_STATUS_PROGRESS: "Updating Project Status",
  UPDATE_PROJECT_STATUS_FAILED: "Failed to Update Project Status",
  UPDATE_PROJECT_STATUS_SUCCESS: "Project Status Updated",

  ACITIVITY_MONITORING_APPROVE_PROGRESS: "Approving Activity Monitoring",
  ACITIVITY_MONITORING_APPROVE_SUCCESS: "Activity Monitoring Approved",
  ACITIVITY_MONITORING_APPROVE_FAILED:
    "Activity Monitoring Approve Request Failed",

  ACITIVITY_MONITORING_ACCEPT_PROGRESS: "Accepting Activity Monitoring",
  ACITIVITY_MONITORING_ACCEPT_SUCCESS: "Activity Monitoring Accepted",
  ACITIVITY_MONITORING_ACCEPT_FAILED:
    "Activity Monitoring Accept Request Failed",

  ACITIVITY_MONITORING_PASSTORM_PROGRESS: "Sending for RM Approval",
  ACITIVITY_MONITORING_PASSTORM_SUCCESS: "Sent for RM Approval",
  ACITIVITY_MONITORING_PASSTORM_FAILED: "Failed to send for RM Approval",

  ACITIVITY_MONITORING_PREVIEW_PROGRESS:
    "Previewing the Activity Monitoring Report",
  ACITIVITY_MONITORING_PREVIEW_FAILED:
    "Failed to Preview Activity Monitoring Report",

  ANNUAL_REQUEST: "Are you sure you want to request Annual Audit?",
  RENEWAL_REQUEST: "Are you sure you want to request Renewal Audit?",
  AMENDMENT_REQUEST: "Are you sure you want to request Amendment Audit?",

  REQUEST_FAILED: "Request Failed",
  AMENDMENT_REQUEST_CONFIRMATION:
    "Requested for Amendment, without involving the SS",

  COMPANY_BRANCHES_ADD_SUCCESS: "Company's Branch Added",
  COMPANY_BRANCHES_ADD_PROGRESS: "Adding Company's Branch",
  COMPANY_BRANCHES_ADD_FAILED: "Failed to Add Company's Branch",

  TAWA_REQUEST: {
    FORM_SUCCESS: id => {
      return `Your request for ID - ${id} has been submitted.  We will get back to you at the earliest.`
    },
  },
  LAST_VISIT_DATE_FUTURE_DATE:
    "The last date of the survey should not be in the future.",
}

export default MESSAGES
