import * as React from "react"

function EmissionShipIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <g
        clipPath="url(#clip0_84_4969)"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1.834 18.333a2.2 2.2 0 001.833.917 2.2 2.2 0 001.834-.917 2.201 2.201 0 011.833-.916 2.2 2.2 0 011.833.916 2.2 2.2 0 001.834.917 2.2 2.2 0 001.833-.917 2.2 2.2 0 011.833-.916 2.2 2.2 0 011.834.916 2.199 2.199 0 001.833.917 2.2 2.2 0 001.833-.917M3.667 16.5l-.917-4.583h16.5l-1.833 3.666M4.584 11.917v-5.5h7.333l3.667 5.5M6.417 6.417V2.75H5.5" />
      </g>
      <defs>
        <clipPath id="clip0_84_4969">
          <path fill="#fff" d="M0 0H22V22H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EmissionShipIcon
