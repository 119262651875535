import React from 'react';
import { Row,Col } from 'reactstrap';
import cx from 'utils/cx';

const Info = props => {
  const { children, className, ...restProps } = props
  return (
    <Row className={"info " + className} {...restProps}>
      {children}
    </Row>
  )
}
const InfoLabel = props => {
  const { children, className, ...restProps } = props
  return (
    <Col  md={6} lg={5} xl={4} className={"info-label " + className} {...restProps}>
      {children}
    </Col>
  )
}
const InfoValue = props => {
  const { children, className, ...restProps } = props
  return (
    <Col md={6} lg={7} xl={8} className={cx("info-value ", className)} {...restProps}>
      {children}
    </Col>
  )
}

Info.Label = InfoLabel;
Info.Value = InfoValue;

export default Info;