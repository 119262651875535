import React from "react"

import { Col, Row } from "reactstrap"
import FormLabel from "components/FormControls/form-label"

import companyPlaceholderImg from "../../assets/images/company-placeholder.svg"

export default function CompnayDetailsBox(props) {
  const {
    name,
    number,
    principal_place_of_business,
    country,
    address,
    contact_person,
  } = props.data || {}
  const logoUrl = props.logoUrl || companyPlaceholderImg

  return (
    <Row>
      <Col xs={12}>
        <div className="company-logo">
          <img src={logoUrl} alt="" />
        </div>
      </Col>
      <Col xs={3}>
        <FormLabel>Company Name</FormLabel>
        <p>{name}</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Company Number</FormLabel>
        <p>{number}</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Principal Place of Business</FormLabel>
        <p>{principal_place_of_business}</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Country</FormLabel>
        <p>{country?.name}</p>
      </Col>
      <Col xs={12}>
        <FormLabel>Company Address</FormLabel>
        <p>{address}</p>
      </Col>

      <Col xs={12}>
        <p className="section-title">Contact Person</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Name</FormLabel>
        <p>{contact_person?.name}</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Email</FormLabel>
        <p>{contact_person?.email}</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Designation</FormLabel>
        <p>{contact_person?.designation}</p>
      </Col>
      <Col xs={3}>
        <FormLabel>Telephone</FormLabel>
        <p>{contact_person?.telephone}</p>
      </Col>
    </Row>
  )
}
