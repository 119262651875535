import React from "react"
import Authbox from "components/Common/auhbox"
import AuthHeader from "components/Common/auth-header"
import ForgotPasswordForm from "components/Forms/forgot-password-form"
import withHOC from "utils/with-hoc"
import {
  ForgotPasswordPageProvider,
  useForgotPasswordPageContext,
} from "./provider"

function ForgotPasswordPage() {
  //meta title
  document.title = "Forgot Password"
  const { handleForgotPassword } = useForgotPasswordPageContext()

  return (
    <div className="layout-wrapper auth-layout">
      <div id="register-page" className="main">
        <AuthHeader />
        <div className="main-content">
          <div className="page-content">
            <Authbox>
              <ForgotPasswordForm onSubmit={handleForgotPassword} />
            </Authbox>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withHOC(ForgotPasswordPageProvider, ForgotPasswordPage)
