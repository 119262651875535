import { withConfig, getApi } from "./index"
const api = getApi("lists")

export const getCountries = withConfig((data, conf) => {
  const { params } = data
  const config = conf({
    url: "/countries",
    method: "GET",
    params: params,
  })
  return api(config)
})

export const getCitiesByCountry = withConfig((data, conf) => {
  const { countryId, params } = data
  const config = conf({
    url: `/countries/${countryId}/cities`,
    method: "GET",
    params: params,
  })
  return api(config)
})

export const getShipTypes = withConfig((data, conf) => {
  const { params } = data
  const config = conf({
    url: `/ship-types`,
    method: "GET",
    params: params,
  })
  return api(config)
})
export const getFuelTypes = withConfig((data, conf) => {
  const { params } = data
  const config = conf({
    url: `/fuel-types`,
    method: "GET",
    params: params,
  })
  return api(config)
})

export const getPorts = withConfig((data, conf) => {
  const { countryId, params } = data
  const config = conf({
    url: `/countries/${countryId}/ports`,
    method: "GET",
    params: params,
  })
  return api(config)
})

export const getClassificationSocieties = withConfig((data, conf) => {
  const { params } = data
  const config = conf({
    url: `/classification-societies`,
    method: "GET",
    params: params,
  })
  return api(config)
})
