import PropTypes from "prop-types"
import React from "react"

import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import LogoImg from "../../assets/images/logo.svg"
import DGLogoImg from "../../assets/images/dg-logo.png"

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

const Header = () => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-block">
              <Link to="/" className="logo">
                <div className="logo-icon">
                  <img src={LogoImg} alt="logo" />
                </div>
                <div className="logo-content">
                  <span className="logo-text">India-FCR</span>
                  <span className="logo-name">Fuel Consumption Reporting</span>
                </div>
              </Link>
            </div>
            <div id="page-heading" />
          </div>

          <div className="d-flex items-center align-items-center">
            <ProfileMenu />
            <div className="dg-logo">
              <img src={DGLogoImg} alt="DG Logo" />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

export default Header
