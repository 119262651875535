import { withConfig, getApi } from "./index"
const api = getApi("assets")

export const getCountryFlagByCode = withConfig((data, conf) => {
  const { countryCode } = data
  const config = conf({
    url: `/countries/${countryCode}/flag`,
    method: "GET",
  })
  return api(config)
})

export const getCompanyLogo = withConfig((data, conf) => {
  const { companyId } = data
  const config = conf({
    url: `/companies/${companyId}/logo`,
    method: "GET",
  })
  return api(config)
})

export const uploadCompanyLogo = withConfig((data, conf) => {
  const { companyId, file } = data
  const formData = new FormData()
  formData.append("file", file)

  const config = conf({
    url: `/companies/${companyId}/logo`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return api(config)
})

export const deleteCompanyLogo = withConfig((data, conf) => {
  const { companyId } = data
  const config = conf({
    url: `/companies/${companyId}/logo`,
    method: "DELETE",
  })
  return api(config)
})
