import * as validations from "../../../helpers/validation-helper"
import * as yup from "yup"

export const FN = {
  PASSWORD: "password",
  RE_PASSWORD: "re_password",
}

export const generateInitialValues = () => {
  return {
    [FN.PASSWORD]: "",
    [FN.RE_PASSWORD]: "",
  }
}

export const generateValidationSchema = () => {
  return yup.object().shape({
    [FN.PASSWORD]: validations.isRequiredString(),
    [FN.RE_PASSWORD]: validations.isRequiredString(),
  })
}

export const generatePayload = formData => {
  return {
    [FN.PASSWORD]: formData[FN.PASSWORD],
    [FN.RE_PASSWORD]: formData[FN.RE_PASSWORD],
  }
}
