import React, { useMemo } from "react"
import { Form, Formik } from "formik"
import { FormikSelect } from "../../FormControls/select"
import { Button, Col, Row } from "reactstrap"
import { FormikInput } from "components/FormControls/input"
import FormLabel from "components/FormControls/form-label"
import {
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
  OTHER_OPTION,
} from "./form-helper"
import { FormikButtonGroupRadio } from "components/FormControls/button-group-radio"
import withHOC from "utils/with-hoc"
import { AddShipFormProvider, useAddShipFormContext } from "./provider"
import Enums from "constants/enums"

function AddShipForm(props) {
  const { onSubmit, onClose, data = {} } = props
  const {
    handleCancelFormClick,
    getFlagsQuery,
    getShipTypesQuery,
    getPortsQuery,
    getClassificationSocietiesQuery,
    handleOnFlagChange,
  } = useAddShipFormContext()

  const flagList = (getFlagsQuery?.data?.data?.data || []).map(item => {
    return {
      label: item.name,
      value: item.id,
    }
  })
  const shipTypeList = [
    ...(getShipTypesQuery?.data?.data?.data || []).map(item => {
      return {
        label: item.name,
        value: item.id,
      }
    }),
    OTHER_OPTION,
  ]
  const classificationSocietyList = [
    ...(getClassificationSocietiesQuery?.data?.data?.data || []).map(item => {
      return {
        label: item.name,
        value: item.id,
      }
    }),
    OTHER_OPTION,
  ]

  const iceClassList = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]

  const intitialValues = useMemo(() => generateInitialValues(data), [data])
  const validationSchema = useMemo(() => generateValidationSchema({}), [])

  const handleOnSubmit = (formData, formikContext) => {
    const payload = generatePayload(formData)
    onSubmit && onSubmit(payload, formikContext)
  }

  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {formik => {
        const formValues = formik.values
        const isShipTypeOther = formValues[FN.SHIP_TYPE] === OTHER_OPTION.value
        const isClassSociOther =
          formValues[FN.CLASSIFICATION_SOCIETY] === OTHER_OPTION.value
        return (
          <Form className="add-company-form form">
            <div className="modal-body">
              <Row>
                <Col xs={3}>
                  <div className="mb-3">
                    <FormLabel required>IMO Number</FormLabel>
                    <FormikInput name={FN.IMO_NUMBER} />
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="mb-3">
                    <FormLabel required>Ship Name</FormLabel>
                    <FormikInput name={FN.SHIP_NAME} />
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="mb-3">
                    <FormLabel required>Flag</FormLabel>
                    <FormikSelect
                      name={FN.FLAG}
                      options={flagList}
                      onChange={handleOnFlagChange(formik)}
                      labelString="label"
                      valueString="value"
                    />
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="mb-3">
                    <FormLabel required>Port of Registry</FormLabel>
                    <FormikInput name={FN.PORT_OF_REGISTRY} />
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="mb-3">
                    <FormLabel required>MARPOL Annex VI Ship Type</FormLabel>
                    <FormikSelect
                      name={FN.SHIP_TYPE}
                      options={shipTypeList}
                      labelString="label"
                      valueString="value"
                    />
                  </div>
                </Col>
                {isShipTypeOther && (
                  <Col xs={4}>
                    <div className="mb-3">
                      <FormLabel required>
                        MARPOL Annex VI Ship Type (Other)
                      </FormLabel>
                      <FormikInput name={FN.SHIP_TYPE_OTHER} />
                    </div>
                  </Col>
                )}

                <Col xs={isShipTypeOther ? 5 : 6}>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-3">
                        <FormLabel required>Gross Tonnage (m tonnes)</FormLabel>
                        <FormikInput name={FN.GROSS_TONNAGE} type="number" />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-3">
                        <FormLabel required>Deadweight (m tonnes)</FormLabel>
                        <FormikInput name={FN.DEADWEIGHT} type="number" />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <div className="mb-3">
                    <FormLabel required>Classification Society</FormLabel>
                    <FormikSelect
                      name={FN.CLASSIFICATION_SOCIETY}
                      options={classificationSocietyList}
                      labelString="label"
                      valueString="value"
                    />
                  </div>
                </Col>
                {isClassSociOther && (
                  <Col xs={3}>
                    <div className="mb-3">
                      <FormLabel required>
                        Classification Society (Other)
                      </FormLabel>
                      <FormikInput name={FN.CLASSIFICATION_SOCIETY_OTHER} />
                    </div>
                  </Col>
                )}
                <Col xs={3}>
                  <div className="mb-3">
                    <FormLabel required>Ship Owner</FormLabel>
                    <FormikInput name={FN.SHIP_OWNER} />
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="mb-3">
                    <FormLabel required>Ship Manager</FormLabel>
                    <FormikInput name={FN.SHIP_MANAGER} />
                  </div>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel>Complies with EEXI requirements?</FormLabel>
                        <div>
                          <FormikButtonGroupRadio
                            name={FN.COMPLY_WITH_EEXI}
                            options={Enums.OPTION_YES_NO_NA}
                            labelString="label"
                            valueString="value"
                            classNames={{
                              container: "d-inline-block  ",
                              item: "w-75",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="mb-3">
                        <FormLabel>Complies with EEDI requirements?</FormLabel>
                        <div>
                          <FormikButtonGroupRadio
                            name={FN.COMPLY_WITH_EEDI}
                            options={Enums.OPTION_YES_NO_NA}
                            labelString="label"
                            valueString="value"
                            classNames={{
                              container: "d-inline-block  ",
                              item: "w-75",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={2}>
                      <div className="mb-3">
                        <FormLabel>Ice Class</FormLabel>
                        <div>
                          <FormikButtonGroupRadio
                            name={FN.ICE_CLASS}
                            options={iceClassList}
                            labelString="label"
                            valueString="value"
                            classNames={{
                              container: "d-inline-flex  ",
                              item: "w-75",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <FormLabel>
                          Ship is equipped with dual fuel engine(s)?
                        </FormLabel>
                        <div>
                          <FormikButtonGroupRadio
                            name={FN.DUAL_FUEL_ENGINES}
                            options={Enums.OPTION_YES_NO}
                            labelString="label"
                            valueString="value"
                            classNames={{
                              container: "d-inline-block  ",
                              item: "w-75",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={5}>
                      <div className="mb-3">
                        <FormLabel>
                          Ship is operating under Specified Period License
                          (SPL)?
                        </FormLabel>
                        <div>
                          <FormikButtonGroupRadio
                            name={FN.UNDER_SPL}
                            options={Enums.OPTION_YES_NO}
                            labelString="label"
                            valueString="value"
                            classNames={{
                              container: "d-inline-block  ",
                              item: "w-75",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="actions modal-footer">
              <div className="actions-left">
                <Button
                  color="success"
                  type="button"
                  outline
                  className="action-button rounded-10"
                  onClick={onClose}
                >
                  Close
                </Button>
              </div>
              <div className="actions-right">
                {formik.dirty && (
                  <Button
                    color="success"
                    type="button"
                    outline
                    className="action-button rounded-10"
                    onClick={handleCancelFormClick(formik)}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  color="success"
                  type="submit"
                  className="action-button rounded-10"
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default withHOC(AddShipFormProvider, AddShipForm)
