import Heading from "components/VerticalLayout/Heading"
import React from "react"
import { Container } from "reactstrap"
import Sidebar from "components/VerticalLayout/Sidebar"
import VirtualizeTable from "components/Common/virtualiza-table"
import ShipDetailCard from "components/Common/ship-details-card"
import {
  AnnualEmissionPageProvider,
  useAnnualEmissionPageContext,
} from "./provider"
import NoRecords from "components/Common/no-records"
import withHOC from "utils/with-hoc"

const renderNoDataRows = () => {
  return <NoRecords message="No emissions found" />
}

const AnualEmissionsPage = () => {
  //meta title
  document.title = "Anual Emissions"

  const {
    getShipQuery,
    getAnnualEmissionsQuery,
    listRef,
    columns,
    breadcrumb,
  } = useAnnualEmissionPageContext()

  return (
    <>
      <Heading title="Sanmar Songbird" breadcrumbs={breadcrumb} />
      <div id="my-fleet-page" className="page-content">
        <Container fluid={true} className="with-sidebar">
          <Sidebar />
          <div className="main">
            <ShipDetailCard data={getShipQuery?.data?.data} />

            <div className="section">
              <div className="section-body">
                <VirtualizeTable
                  ref={listRef}
                  rows={getAnnualEmissionsQuery?.data?.data}
                  columns={columns}
                  hasMore={false}
                  isLoading={getAnnualEmissionsQuery?.isPending}
                  isLoadMoreDirty={!getAnnualEmissionsQuery?.isIdle}
                  loadingRowCount={getAnnualEmissionsQuery?.isPending ? 10 : 0}
                  renderNoDataRows={renderNoDataRows}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
export default withHOC(AnnualEmissionPageProvider, AnualEmissionsPage)
