import { useAsync } from "@react-org/hooks"
import { useMemo, useCallback } from "react"
import generateContext from "utils/generate-context"
import * as ListsApi from "../../../apis/lists.api"
import { FN } from "./form-helper"

function useAddShipForm(props) {
  const getFlagsQuery = useAsync(
    useCallback(
      payload => ListsApi.getCountries({ params: { limit: 250 }, ...payload }),
      []
    )
  )
  const getShipTypesQuery = useAsync(
    useCallback(
      payload => ListsApi.getShipTypes({ params: { limit: 250 }, ...payload }),
      []
    )
  )
  const getPortsQuery = useAsync(
    useCallback(
      payload => ListsApi.getPorts({ params: { limit: 250 }, ...payload }),
      []
    ),
    {
      immediate: false,
    }
  )
  const getClassificationSocietiesQuery = useAsync(
    useCallback(
      payload =>
        ListsApi.getClassificationSocieties({
          params: { limit: 250 },
          ...payload,
        }),
      []
    )
  )

  const handleOnFlagChange = useCallback(
    formik => countryId => {
      if (!countryId) {
        const getPortsQuerySetData = getPortsQuery.setQueryData
        getPortsQuerySetData(null)
      } else {
        const getPortsQueryExecute = getPortsQuery.execute
        getPortsQueryExecute({ countryId })
      }
      formik.setFieldValue(FN.PORT_OF_REGISTRY, "")
    },
    [getPortsQuery.execute, getPortsQuery.setQueryData]
  )

  const handleCancelFormClick = useCallback(
    formik => () => {
      formik.resetForm()
    },
    []
  )

  return useMemo(() => {
    return {
      getFlagsQuery,
      getShipTypesQuery,
      getPortsQuery,
      getClassificationSocietiesQuery,
      handleCancelFormClick,
      handleOnFlagChange,
    }
  }, [
    getFlagsQuery,
    getShipTypesQuery,
    getPortsQuery,
    getClassificationSocietiesQuery,
    handleCancelFormClick,
    handleOnFlagChange,
  ])
}

export const [AddShipFormProvider, useAddShipFormContext] =
  generateContext(useAddShipForm)
