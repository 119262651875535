const Const = {
  API_URL: process.env.REACT_APP_API_URL,
  NOTIFICATION_TYPE: {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "info",
    WARNING: "warning",
    PROGRESS: "progress",
    CLOSE: "close",
    CONFIRM: "confirm",
  },
  CONTEXT_TYPE: {
    USER: "user",
    TOKEN: "token",
  },
  TOKEN_TYPE: {
    ACCESS: "access_token",
    REFRESH: "refresh_token",
  },
  COUNTRIES:{
    IND:{
      LABEL:"India",
      VALUE:93
    }
  },
  DATE_FORMATS:{
    UI:"DD-MM-YYYY",
    API:"YYYY-MM-DD",
    TIME:"hh:mm"
  }
}

export default Const
