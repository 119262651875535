import usePrevious from "hooks/use-previous"
import { useMemo } from "react"

export default function useRows(data_ = [], loadingRowCount) {
  const rows = useMemo(() => {
    const data = data_ || []
    let _rows = {}
    if (data?.length > 0) {
      _rows = data.reduce((prev, current, currentIndex) => {
        prev[currentIndex] = current
        return prev
      }, {})
    }

    //NOTE:added to show skeleton _rows
    if (loadingRowCount > 0) {
      let rowLength = Object.keys(_rows).length
      _rows = Array.from({ length: loadingRowCount }).reduce(
        (prev, current, currentIndex) => {
          prev[rowLength + currentIndex] = { isSkeletonRow: true }
          return prev
        },
        _rows
      )
    }
    return _rows
  }, [data_, loadingRowCount])

  const rowCount = Object.keys(rows).length
  const prevRows = usePrevious(rows) || {}
  const prevRowCount = Object.keys(prevRows).length

  return {
    rows,
    rowCount,
    prevRows,
    prevRowCount,
  }
}
