import { useAsync } from "@react-org/hooks"
import { useMemo, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import generateContext from "../../utils/generate-context"
import * as AuthApi from "../../apis/auth.api"
import { useNotificationModalContext } from "components/notification-modal/provider"
import useQueryParams from "hooks/use-query-params"

function useForgotPasswordPage() {
  const navigate = useNavigate()
  const notificationModal = useNotificationModalContext()
  const queryParams = useQueryParams()
  const forgotPasswordQuery = useAsync(AuthApi.forgotPassword, {
    immediate: false,
  })

  const handleForgotPassword = useCallback(
    (formData, { setSubmitting }) => {
      notificationModal.progress({
        title: "Password Reset Requested",
        heading:
          "We have received your request to reset your password. Please wait as we process your request and send you an email with further instructions. Thank you for your patience.",
      })
      const registerQueryExecute = forgotPasswordQuery.execute
      registerQueryExecute(formData, {
        onSuccess: res => {
          notificationModal.success({
            title: "Password Reset Email Sent",
            heading:
              "We have sent you an email with instructions to reset your password. Please check your inbox and follow the provided steps to reset your password.",
            closeText: "Go to Login",
            onClose: () => {
              navigate("/login")
            },
          })
        },
        onError: err => {
          if (err?.response?.status === 404) {
            notificationModal.error({
              title: "Email Not Found",
              heading:
                "We're sorry, but the email address you provided does not exist in our records. Please ensure that you have entered the correct email address or consider using the email associated with your account.",
              onClose: () => {
                console.log("close")
              },
            })
          } else {
            notificationModal.close()
          }
        },
      })
    },
    [navigate, notificationModal, forgotPasswordQuery.execute]
  )

  return useMemo(() => {
    return {
      handleForgotPassword,
    }
  }, [handleForgotPassword])
}

export const [ForgotPasswordPageProvider, useForgotPasswordPageContext] =
  generateContext(useForgotPasswordPage)
