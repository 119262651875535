import { useAsync } from "@react-org/hooks"
import { useMemo, useCallback } from "react"
import generateContext from "utils/generate-context"
import * as ListsApi from "../../../apis/lists.api"
import { FN, getEmissionInitialValue } from "./form-helper"
import Const from "../../../constants"
import { useNotificationModalContext } from "components/notification-modal/provider"
import Enums from "constants/enums"

function useAddPortEmissionForm() {
  const notificationModal = useNotificationModalContext()

  const getFuelTypesQuery = useAsync(
    useCallback(
      payload => ListsApi.getFuelTypes({ params: { limit: 250 }, ...payload }),
      []
    )
  )

  const getArrivalPortsQuery = useAsync(
    useCallback(
      payload =>
        ListsApi.getPorts({
          params: { limit: 250 },
          countryId: Const.COUNTRIES.IND.VALUE,
          ...payload,
        }),
      []
    )
  )

  const handleCancelFormClick = useCallback(
    formik => () => {
      formik.resetForm()
    },
    []
  )

  const handleOnAddEmissionClick = useCallback(
    arrayHelpers => () => {
      arrayHelpers.push(getEmissionInitialValue())
    },
    []
  )

  const handleDeleteEmissionClick = useCallback(
    (arrayHelpers, index) => () => {
      notificationModal.warning({
        confirmText: "Delete",
        canceltext: "Close",
        title: "Are you sure?",
        heading: "Are you sure you want to delete this record? ",
        onCancel: () => {
          console.log("cancel")
        },
        onConfirm: () => {
          arrayHelpers.remove(index)
        },
      })
    },
    [notificationModal]
  )

  const handleOnFuelTypeChange = useCallback(
    (formik, detailIndex) => value => {
      let conversionFactor = ""
      if (value !== Enums.OTHER_OPTION.value) {
        const fuelType = getFuelTypesQuery?.data?.data?.data.find(
          item => item.id === value
        )
        conversionFactor = fuelType?.conversion_factor
      }
      const timeout = setTimeout(() => {
        formik.setFieldValue(
          `${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.EMISSION_FACTOR}`,
          conversionFactor
        )
        formik.setFieldValue(
          `${FN.EMISSION.ROOT}.[${detailIndex}].${FN.EMISSION.FUEL_TYPE_OTHER}`,
          ""
        )
        clearTimeout(timeout)
      }, 0)
    },
    [getFuelTypesQuery?.data?.data?.data]
  )

  return useMemo(() => {
    return {
      getFuelTypesQuery,
      getArrivalPortsQuery,
      handleCancelFormClick,
      handleOnAddEmissionClick,
      handleDeleteEmissionClick,
      handleOnFuelTypeChange,
    }
  }, [
    getFuelTypesQuery,
    getArrivalPortsQuery,
    handleCancelFormClick,
    handleOnAddEmissionClick,
    handleDeleteEmissionClick,
    handleOnFuelTypeChange,
  ])
}

export const [AddPortEmissionFormProvider, useAddPortEmissionFormContext] =
  generateContext(useAddPortEmissionForm)
