import React from "react";
import cx from "utils/cx";

export default function FormError(props) {
  let { className, message, show, children, ...restProps } = props
  let errorMessage = message || children
  if (!show) return null
  return (
    <div
      className={cx(
        "form-error text-red-500 mt-1 text-xs form-field-error",
        className
      )}
      {...restProps}
    >
      {errorMessage}
    </div>
  )
}
