import * as React from "react"

function VoyageShipIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={20}
      viewBox="0 0 23 20"
      fill="none"
      {...props}
    >
      <path
        d="M2 16.306l.69.345a2.735 2.735 0 002.3.067l.947-.406a2.735 2.735 0 012.154 0l.948.406a2.734 2.734 0 002.3-.067l.377-.189a2.736 2.736 0 012.446 0l.5.25c.648.324 1.399.377 2.087.148l1.66-.553m-14.586-2.28l-.911-4.102h3.025m0 0V7.191h9.737v2.734m-9.737 0h9.737m0 4.102l2.279-4.102h-2.28M2 14.306l.69.345a2.733 2.733 0 002.3.068l.947-.407a2.735 2.735 0 012.154 0l.948.407a2.734 2.734 0 002.3-.068l.377-.189a2.736 2.736 0 012.446 0l.5.25c.648.324 1.399.377 2.087.148l1.66-.553"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 3v4" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
}

export default VoyageShipIcon
