import React, {
  useContext,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import storageService from "../services/storage.service"
import Const from "../constants"

const UserContext = createContext()

export function UserProvider(props) {
  const { children } = props
  const [data, setData] = useState(() => {
    return storageService.getItem(Const.CONTEXT_TYPE.USER) || {}
  })

  const formattedData = useMemo(() => {
    if (Object.keys(data).length <= 0) return {}
    return {
      ...data,
    }
  }, [data])

  const setUser = user => {
    storageService.setItem(Const.CONTEXT_TYPE.USER, user)
  }

  const removeUser = () => {
    storageService.removeItem(Const.CONTEXT_TYPE.USER)
  }

  useEffect(() => {
    function subscribeHandler(user) {
      setData(user || {})
    }
    storageService.addChangeListener(Const.CONTEXT_TYPE.USER, subscribeHandler)
    return () => {
      storageService.removeChangeListener(
        Const.CONTEXT_TYPE.USER,
        subscribeHandler
      )
    }
  }, [])

  const value = useMemo(() => {
    return { user: formattedData, setUser, removeUser }
  }, [formattedData])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export function useUserFromStorage() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error("useUserFromStorage must be used within a UserProvider")
  }
  return context
}

// NOTE: only and only use this functions when needed to use outside react
export function getUserFromStorage() {
  return storageService.getItem(Const.CONTEXT_TYPE.USER)
}

export default UserProvider
