import React from "react"
import { Form, Formik } from "formik"
import { Button, Col, Row } from "reactstrap"
import { FormikInput } from "components/FormControls/input"
import FormLabel from "components/FormControls/form-label"
import {
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
} from "./form-helper"
import { generatePath, Link, useNavigate } from "react-router-dom"

export default function ForgotPasswordForm(props) {
  const { onSubmit } = props

  const navigate = useNavigate()

  const intitialValues = generateInitialValues({})
  const validationSchema = generateValidationSchema({})

  const handleOnSubmit = (formData, formikContext) => {
    const payload = generatePayload(formData)
    onSubmit && onSubmit(payload, formikContext)
  }

  const handleBackToLoginClick = () => {
    const redirectLink = generatePath("/login")
    navigate(redirectLink)
  }
  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {() => {
        return (
          <Form className="forgot-password-form form">
            <div className="form-title">Forgot your Password?</div>
            <div className="form-body">
              <div className="form-body_top">
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <p className="info-text">
                        Please enter the register email address you’d like your password
                        reset information sent to.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="mb-3">
                      <FormLabel required>Email</FormLabel>
                      <FormikInput name={FN.EMAIL} />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="form-body_bottom">
                <Row>
                  <Col xs={12} className="mb-3">
                    <Button color="success" type="submit" className="w-full rounded-10">
                      Continue
                    </Button>
                  </Col>
                  <Col xs={12} >
                    <Button
                      color="success"
                      outline
                      type="button"
                      className="w-full rounded-10"
                      onClick={handleBackToLoginClick}
                    >
                      Back to Login
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
