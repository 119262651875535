import * as validations from "../../../helpers/validation-helper"
import * as yup from "yup"

export const FN = {
  EMAIL: "email",
  PASSWORD: "password",
}

export const generateInitialValues = () => {
  return {
    [FN.EMAIL]: "",
    [FN.PASSWORD]: "",
  }
}

export const generateValidationSchema = () => {
  return yup.object().shape({
    [FN.EMAIL]: validations.isRequiredEMailString(),
    [FN.PASSWORD]: validations.isRequiredString(),
  })
}

export const generatePayload = formData => {
  return {
    [FN.EMAIL]: formData[FN.EMAIL],
    [FN.PASSWORD]: formData[FN.PASSWORD],
  }
}
