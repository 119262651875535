import { useField, useFormikContext } from "formik"
import React from "react"
import { Input as InputRaw } from "reactstrap"
import FormError from "./form-error"

const Input = React.forwardRef(function Input(props, ref) {
  const { error, type, strict, ...restProps } = props;

  const handleKeyDown = (e) => {
    if (type !== "number") return;
    if (!strict) return;

    const { key, ctrlKey } = e;
    if (ctrlKey) return;
    const isValidKey = /^[0-9]$/.test(key) || key === "Backspace"
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  return <InputRaw className="text-input" {...restProps} type={type} onKeyDown={handleKeyDown} ref={ref} />
})

export function FormikInput(props) {
  const { name, setRef = () => {}, onChange, onBlur, ...restProps } = props
  const [field, meta, { setValue }] = useField(name)
  const { submitCount } = useFormikContext()
  const isError = submitCount > 0 && !!meta.error
  const helperText = (submitCount > 0 && meta.error) || ""

  const handleOnChange = e => {
    const value = e.target.value
    setValue(value)
    onChange && onChange(value)
  }

  const handleOnBlur = e => {
    onBlur && onBlur(e)
  }
  return (
    <>
      <Input
        ref={setRef}
        error={isError}
        {...restProps}
        {...field}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      <FormError show={isError} message={helperText} />
    </>
  )
}

export default Input
