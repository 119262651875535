import { useAsync } from "@react-org/hooks"
import { useMemo, useCallback, useState } from "react"
import generateContext from "../../utils/generate-context"
import * as AuthApi from "../../apis/auth.api"
import * as AssetsApi from "../../apis/assets.api"
import { useNotificationModalContext } from "components/notification-modal/provider"

function useRegisterPage() {
  const notificationModal = useNotificationModalContext()
  const [isRegistered, setIsRegistered] = useState(false)
  const registerQuery = useAsync(AuthApi.register, { immediate: false })
  const uploadCompanyLogoQuery = useAsync(AssetsApi.uploadCompanyLogo, {
    immediate: false,
  })

  const handleRegistration = useCallback(
    (formData, { setSubmitting }) => {
      const { file, ...restFormData } = formData
      notificationModal.progress({
        heading: "Registration in progress.",
      })
      const registerQueryExecute = registerQuery.execute
      registerQueryExecute(restFormData, {
        onSuccess: res => {
          if (file) {
            const uploadCompanyLogoQueryExecute = uploadCompanyLogoQuery.execute
            uploadCompanyLogoQueryExecute(
              {
                companyId: res.data.company_id,
                file,
              },
              {
                onComplete: () => {
                  notificationModal.close()
                  setIsRegistered(true)
                },
              }
            )
          } else {
            notificationModal.close()
            setIsRegistered(true)
          }
        },
        onError: err => {
          if (err?.response?.status === 409) {
            notificationModal.error({
              title: "Conflict",
              heading: err.response.data.error,
              onClose: () => {
                console.log("closed")
              },
            })
          } else {
            notificationModal.close()
          }
        },
      })
    },
    [notificationModal, registerQuery.execute, uploadCompanyLogoQuery.execute]
  )

  return useMemo(() => {
    return {
      handleRegistration,
      isRegistered,
    }
  }, [handleRegistration, isRegistered])
}

export const [RegisterPageProvider, useRegisterPageContext] =
  generateContext(useRegisterPage)
