import * as React from "react"

function EmissionShipIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={20}
      viewBox="0 0 23 20"
      fill="none"
      {...props}
    >
      <path
        d="M2 16.306l.69.345a2.733 2.733 0 002.3.067l.947-.406a2.735 2.735 0 012.154 0l.948.406a2.734 2.734 0 002.3-.067l.377-.189a2.736 2.736 0 012.446 0l.5.25c.648.324 1.399.377 2.087.148l1.66-.553m-14.586-2.28l-.911-4.102h3.646m0 0V7.191h4.356l.658 2.734m-5.014 0h5.014m4.102 4.102l2.279-4.102h-7.516 1.135m-2.28 2.735h.092M8.38 4.912L8.2 4.729a1.823 1.823 0 00-1.731-.478l-.307.076c-.336.084-.69.07-1.019-.039L4.28 4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 2c.167.167.8.6 2 1s1.167 1.5 1 2"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default EmissionShipIcon
