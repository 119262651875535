import React, { useRef } from "react"

import "flatpickr/dist/themes/material_blue.css"
import FormError from "./form-error"
import { useField, useFormikContext } from "formik"
import DatePicker from "react-flatpickr"
import mergeRefs from "utils/merge-refs"
import cx from "utils/cx"

const Datepicker = React.forwardRef(function Datepicker(props = {}, ref) {
  const { options = {}, className, error, ...restProps } = props
  const datePicketRef = useRef(null)

  const handleIconClick = () => {
    datePicketRef.current.flatpickr.toggle()
  }
  return (
    <div className={cx("datepicker-wrapper w-full", className)}>
      <DatePicker
        className={cx("form-control d-block text-input")}
        placeholder="DD-MM-YYYY"
        options={{
          allowInput: true,
          enableTime: false,
          dateFormat: "d-m-Y",
          ...options,
        }}
        autoComplete="off"
        ref={mergeRefs([datePicketRef, ref])}
        {...restProps}
      />
      <div className="datepicker-icon" onClick={handleIconClick}>
        <i className="bx bx-calendar"></i>
      </div>
    </div>
  )
})

export function FormikDatepicker(props) {
  const {
    name,
    setRef = () => {},
    errorClassName,
    wrapperClassName,
    onChange,
    onBlur,
    ...restProps
  } = props
  const [field, meta, { setValue }] = useField(name)
  const { submitCount } = useFormikContext()
  const isError = submitCount > 0 && !!meta.error
  const helperText = (submitCount > 0 && meta.error) || ""

  const handleOnChange = value => {
    setValue(value)
    onChange && onChange(value)
  }

  const handleOnBlur = e => {
    onBlur && onBlur(e)
  }

  return (
    <div className={wrapperClassName}>
      <Datepicker
        ref={setRef}
        error={isError}
        {...restProps}
        {...field}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      <FormError
        show={isError}
        message={helperText}
        className={errorClassName}
      />
    </div>
  )
}

export default Datepicker
