import { useEffect } from "react"
import { CellMeasurer } from "react-virtualized"
import useResizeObserver from "use-resize-observer"
import usePrevious from "../../../hooks/use-previous"
import React from "react"

export default function Row(props) {
  let { children, style, rowIndex, cache, rowKey, parent, listRef } = props
  const { height, ref } = useResizeObserver()
  const prevHeight = usePrevious(height)

  useEffect(() => {
    if (prevHeight !== height) {
      listRef?.current?.clearCache && listRef?.current?.clearCache(rowIndex)
    }
  }, [height, listRef, prevHeight, rowIndex])

  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={rowKey}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div style={style} className="style-element">
        <div ref={ref} className="observer-element">
          {children}
        </div>
      </div>
    </CellMeasurer>
  )
}
