import { withConfig, getApi } from "./index"
const api = getApi("ships")

export const getShips = withConfig((data, conf) => {
  const { params } = data
  const config = conf({
    url: "/",
    method: "GET",
    params: params,
  })
  return api(config)
})
export const getShipById = withConfig((data, conf) => {
  const { shipId, params } = data
  const config = conf({
    url: `/${shipId}`,
    method: "GET",
    params: params,
  })
  return api(config)
})

export const addShip = withConfig((data, conf) => {
  const { body } = data
  const config = conf({
    url: "/",
    method: "POST",
    data: body,
  })
  return api(config)
})

export const updateShip = withConfig((data, conf) => {
  const { shipId, body } = data
  const config = conf({
    url: `/${shipId}`,
    method: "PUT",
    data: body,
  })
  return api(config)
})

export const deleteShip = withConfig((data, conf) => {
  const { shipId } = data
  const config = conf({
    url: `/${shipId}`,
    method: "DELETE",
  })
  return api(config)
})

export const getShipEmissions = withConfig((data, conf) => {
  const { shipId, params } = data
  const config = conf({
    url: `/${shipId}/emissions`,
    method: "GET",
    params: params,
  })
  return api(config)
})
export const getShipAnnualEmissions = withConfig((data, conf) => {
  const { shipId, params } = data
  const config = conf({
    url: `/${shipId}/emissions/annual`,
    method: "GET",
    params: params,
  })
  return api(config)
})

export const getShipVoyageReport = withConfig((data, conf) => {
  const { shipId, params } = data
  const config = conf({
    url: `/${shipId}/emissions/voyage-report`,
    method: "GET",
    params: params,
  })
  return api(config)
})

export const getShipPortCalls = withConfig((data, conf) => {
  const { shipId, params } = data
  const config = conf({
    url: `/${shipId}/emissions/port-calls`,
    method: "GET",
    params: params,
  })
  return api(config)
})

export const addShipEmissions = withConfig((data, conf) => {
  const { shipId, body } = data
  const config = conf({
    url: `/${shipId}/emissions`,
    method: "POST",
    data: body,
  })
  return api(config)
})

export const updateShipEmission = withConfig((data, conf) => {
  const { shipId, emissionId, body } = data
  const config = conf({
    url: `/${shipId}/emissions/${emissionId}`,
    method: "PUT",
    data: body,
  })
  return api(config)
})

export const deleteShipEmission = withConfig((data, conf) => {
  const { shipId, emissionId } = data
  const config = conf({
    url: `/${shipId}/emissions/${emissionId}`,
    method: "DELETE",
  })
  return api(config)
})
