import React from "react"
import { Link } from "react-router-dom"

import LogoImg from "../../assets/images/logo.svg"
import DGLogoImg from "../../assets/images/dg-logo.png"

export default function AuthHeader() {
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box d-block">
            <Link className="logo" to="/">
              <div className="logo-icon">
                <img src={LogoImg} alt="logo" />
              </div>
              <div className="logo-content">
                <span className="logo-text">India-FCR</span>
                <span className="logo-name">Fuel Consumption Reporting</span>
              </div>
            </Link>
          </div>
          <div id="page-heading" />
        </div>

        <div className="d-flex items-center align-items-center">
          <div className="dg-logo">
            <img src={DGLogoImg} alt="DG Logo" />
          </div>
        </div>
      </div>
    </header>
  )
}
