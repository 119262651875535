import React from "react"
import { generatePath, useNavigate } from "react-router-dom"

import RegistartionForm from "components/Forms/registration-form"
import AuthHeader from "components/Common/auth-header"
import withHOC from "utils/with-hoc"
import { RegisterPageProvider, useRegisterPageContext } from "./provider"

function RegisterPage() {
  //meta title
  document.title = "Register"

  const { isRegistered, handleRegistration } = useRegisterPageContext()

  return (
    <div className="layout-wrapper public-layout">
      <div id="register-page" className="main">
        <AuthHeader />
        <div className="main-content">
          <div className="page-content">
            {isRegistered ? (
              <div className="message-box">
                <p className="message-box_title">Successfully registered.</p>
                <p className="message-box_content">
                  Congratulations on successfully registering with us. To ensure
                  the security of your account, we have sent a password reset
                  link to the email address you provided. Please check your
                  inbox and follow the instructions to set a new password.
                </p>
              </div>
            ) : (
              <div className="scollable-container">
                <div className="scollable-content">
                  <div className="register-box">
                    <div className="page-title">Registration</div>
                    <RegistartionForm onSubmit={handleRegistration} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withHOC(RegisterPageProvider, RegisterPage)
