import Const from "../constants"
import moment from "moment"
import is from "./is"

export const getFormattedDate = (dateObj, format = Const.DATE_FORMATS.UI) => {
  if (is.array(dateObj)) {
    dateObj = dateObj?.[0]
  }
  if (!dateObj) {
    return ""
  }
  return moment(dateObj).format(format)
}

export const getFormattedDateforUI = dateObj => {
  return getFormattedDate(dateObj, Const.DATE_FORMATS.UI)
}

export const getFormattedDateforAPI = dateObj => {
  return getFormattedDate(dateObj, Const.DATE_FORMATS.API)
}

export const addDaysToDate = (dateObj, days) => {
  let date = null
  if (is.array(dateObj)) {
    date = dateObj?.[0]
  } else {
    date = dateObj
  }
  if (!date) {
    return null
  }
  return moment(date).add(days, "days").toDate()
}
