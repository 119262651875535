import * as validations from "../../../helpers/validation-helper"
import * as yup from "yup"

export const FN = {
  EMAIL: "email",
}

export const generateInitialValues = () => {
  return {
    [FN.EMAIL]: "",
  }
}

export const generateValidationSchema = () => {
  return yup.object().shape({
    [FN.EMAIL]: validations.isRequiredEMailString(),
  })
}

export const generatePayload = formData => {
  return {
    [FN.EMAIL]: formData[FN.EMAIL],
  }
}
