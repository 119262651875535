import React from "react"
import { Card, Col, Row } from "reactstrap"
import Info from "./Info"
import ShipIcon from "../Vectors/ship-icon"

export default function ShipDetailCard(props) {
  const {
    name,
    imo_number,
    port_of_registry,
    port_of_registry_other,
    ship_type,
    ship_type_other,
    flag,
    classification_society,
    classification_society_other,
  } = props.data || {}

  return (
    <Card className="detail-card">
      <Row className="comapny-details">
        <Col xs={12}>
          <Info>
            <Info.Label md={6} lg={6} xl={6} className="fw-500">
              <ShipIcon className="text-success ship-icon" />
              {name}
            </Info.Label>
          </Info>
        </Col>
        <Col>
          <Info>
            <Info.Label>IMO Number</Info.Label>
            <Info.Value>{imo_number}</Info.Value>
          </Info>
          <Info>
            <Info.Label>Ship Type</Info.Label>
            <Info.Value>{ship_type?.name || ship_type_other}</Info.Value>
          </Info>
          <Info>
            <Info.Label>Flag</Info.Label>
            <Info.Value>{flag?.name}</Info.Value>
          </Info>
        </Col>
        <Col>
          <Info>
            <Info.Label md={6} lg={6} xl={6} className="fw-500">
              {" "}
            </Info.Label>
          </Info>
          <Info>
            <Info.Label>Port of Registry</Info.Label>
            <Info.Value>
              {port_of_registry || port_of_registry_other}
            </Info.Value>
          </Info>
          <Info>
            <Info.Label>Classification Society</Info.Label>
            <Info.Value>
              {classification_society?.name || classification_society_other}
            </Info.Value>
          </Info>
        </Col>
      </Row>
    </Card>
  )
}
