import SearchIcon from "components/Vectors/search-icon"
import React from "react"
import { Input } from "reactstrap"

export default function SearchInput(props) {
  const { iconLeft, placeholder, ...restProps } = props

  return (
    <div className="search-input-container">
      {iconLeft && (
        <div className="search-icon">
          <SearchIcon />
        </div>
      )}
      <Input
        type="text"
        className="search-input"
        placeholder={placeholder}
        {...restProps}
      />
    </div>
  )
}
