import Heading from "components/VerticalLayout/Heading"
import React, { useMemo } from "react"

import { Button, Container } from "reactstrap"
import ModalContainer from "components/Common/modal-container"
import VirtualizeTable from "components/Common/virtualiza-table"
import AddUserForm from "components/Forms/add-user-form"
import SearchInput from "components/search-input"
import { UsersPageProvider, useUsersPageContext } from "./provider"
import withHOC from "utils/with-hoc"
import NoRecords from "components/Common/no-records"

const renderNoDataRows = () => {
  return <NoRecords message="No users found" />
}

const UsersPage = () => {
  //meta title
  document.title = "Manage User"

  const {
    listRef,
    isAddUserModalOpen,
    columns,
    activeMenuRowIndex,
    search,
    getUsersQuery,
    handleAddUser,
    handleEditUserClick,
    handleCloseAddUserModalClick,
    isHasMorePages,
    loadingRowCount,
    handleLoadMore,
    handleSearchChange,
  } = useUsersPageContext()

  const activeRow = useMemo(
    () => getUsersQuery?.data?.[activeMenuRowIndex],
    [activeMenuRowIndex, getUsersQuery?.data]
  )

  return (
    <>
      <Heading title="Manage Users" />
      <div id="my-fleet-page" className="page-content">
        <Container fluid={true} className="full-box">
          <div className="main">
            <div className="section">
              <div className="section-heading">
                <div className="section-heading_left">
                  <div className="section-heading_title">
                    <SearchInput
                      value={search}
                      onChange={handleSearchChange}
                      placeholder="Search"
                      type="text"
                      iconLeft
                    />
                  </div>
                </div>
                <div className="section-heading_right">
                  <div className="section-heading_actions">
                    <Button
                      type="button"
                      color="success"
                      onClick={handleEditUserClick}
                      className="rounded-10"
                    >
                      <i className="bx bx-plus font-size-16 align-middle me-2"></i>
                      Add User
                    </Button>
                  </div>
                </div>
              </div>
              <div className="section-body">
                <VirtualizeTable
                  ref={listRef}
                  rows={getUsersQuery.data}
                  columns={columns}
                  hasMore={isHasMorePages}
                  loadMore={handleLoadMore}
                  isLoading={getUsersQuery?.isPending}
                  loadingRowCount={loadingRowCount}
                  isLoadMoreDirty={!getUsersQuery?.isIdle}
                  renderNoDataRows={renderNoDataRows}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <ModalContainer
        isOpen={isAddUserModalOpen}
        title={activeRow ? "Edit User" : "Add User"}
        handleClose={handleCloseAddUserModalClick}
      >
        <AddUserForm
          data={activeRow}
          onSubmit={handleAddUser}
          onClose={handleCloseAddUserModalClick}
        />
      </ModalContainer>
    </>
  )
}

export default withHOC(UsersPageProvider, UsersPage)
