import { useAsync } from "@react-org/hooks"
import React, { useMemo, useCallback, useState, useRef, useEffect } from "react"
import generateContext from "../../utils/generate-context"
import * as UsersApi from "../../apis/users.api"
import { useNotificationModalContext } from "components/notification-modal/provider"
import paginateSelector from "utils/paginate-selector"
import ThreeDotMenu from "./three-dot-menu"
import debounce from "../../utils/debounce"
import is from "utils/is"

function useUsersPage() {
  const listRef = useRef()

  const notificationModal = useNotificationModalContext()
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
  const [columns, setColumns] = useState([])
  const [activeMenuRowIndex, setActiveMenuRowIndex] = useState(null)

  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState("name")
  const [sortOrder, setSortOrder] = useState("asc")

  const getUsersQuery = useAsync(UsersApi.getUsers, {
    intialState: { data: [] },
    immediate: true,
    infinite: true,
    select: paginateSelector,
  })

  const addUserQuery = useAsync(UsersApi.addUser, { immediate: false })
  const updateUserQuery = useAsync(UsersApi.updateUser, { immediate: false })
  const deleteUserQuery = useAsync(UsersApi.deleteUser, { immediate: false })

  const isColumnSorted = useCallback(
    columnId => {
      return sortBy === columnId
    },
    [sortBy]
  )

  const fetchUsers = useCallback(
    data => {
      const { page = 1, term = search, sort = sortBy, order = sortOrder } = data

      if (page === 1) {
        const getUsersQuerySetData = getUsersQuery.setQueryData
        getUsersQuerySetData(null)
      }
      const getUsersQueryCancel = getUsersQuery.cancel
      getUsersQueryCancel()

      const getUsersQueryExecute = getUsersQuery.execute
      getUsersQueryExecute({
        params: {
          page,
          term,
          sort: sort ? `${sort}:${order}` : "created_date:desc",
        },
      })
    },
    [
      getUsersQuery.cancel,
      getUsersQuery.execute,
      getUsersQuery.setQueryData,
      search,
      sortBy,
      sortOrder,
    ]
  )

  const handleEditUser = useCallback(
    formData => {
      const activeRow = getUsersQuery.data[activeMenuRowIndex]
      notificationModal.progress({
        heading: "Updating User",
      })

      const payload = {
        userId: activeRow.id,
        body: formData,
      }

      const updateUserQueryExecute = updateUserQuery.execute

      updateUserQueryExecute(payload, {
        onSuccess: res => {
          notificationModal.close()
          setActiveMenuRowIndex(null)
          setIsAddUserModalOpen(false)
          fetchUsers({ page: 1 })
        },
        onError: err => {
          if (err?.response?.status === 409) {
            notificationModal.error({
              title: "Conflict",
              heading: "User is already exist in the system.",
              onClose: () => {
                console.log("closed")
              },
            })
          } else {
            notificationModal.close()
          }
        },
      })
    },
    [
      activeMenuRowIndex,
      fetchUsers,
      getUsersQuery.data,
      notificationModal,
      updateUserQuery.execute,
    ]
  )

  const handleAddUser = useCallback(
    formData => {
      if (!is.undefined(activeMenuRowIndex) && !is.null(activeMenuRowIndex)) {
        return handleEditUser(formData)
      }
      notificationModal.progress({
        heading: "Adding New User",
      })

      const addUserQueryExecute = addUserQuery.execute
      addUserQueryExecute(
        { body: formData },
        {
          onSuccess: res => {
            notificationModal.close()
            setIsAddUserModalOpen(false)
            fetchUsers({ page: 1 })
          },
          onError: err => {
            if (err?.response?.status === 409) {
              notificationModal.error({
                title: "Conflict",
                heading: "User is already exist in the system.",
                onClose: () => {
                  console.log("closed")
                },
              })
            } else {
              notificationModal.close()
            }
          },
        }
      )
    },
    [
      activeMenuRowIndex,
      addUserQuery.execute,
      fetchUsers,
      handleEditUser,
      notificationModal,
    ]
  )
  const handleDeleteUserClick = useCallback(
    (event, data) => {
      const { rowIndex, row } = data
      setActiveMenuRowIndex(rowIndex)
      notificationModal.warning({
        confirmText: "Delete",
        canceltext: "Close",
        title: "Are you sure?",
        heading:
          "Are you sure you want to delete this record? This action cannot be undone.",
        onCancel: () => {
          setActiveMenuRowIndex(null)
        },
        onConfirm: () => {
          notificationModal.progress({ heading: "Deleting User" })
          setActiveMenuRowIndex(null)
          const deleteUserQueryExecute = deleteUserQuery.execute
          deleteUserQueryExecute(
            { userId: row.id },
            {
              onSuccess: () => {
                notificationModal.close()
                setActiveMenuRowIndex(null)
                fetchUsers({ page: 1 })
              },
              onError: () => {
                notificationModal.error({
                  heading: "Failed to delete user",
                  onClose: () => {
                    setActiveMenuRowIndex(null)
                  },
                })
              },
            }
          )
        },
      })
    },
    [deleteUserQuery.execute, fetchUsers, notificationModal]
  )

  const handleEditUserClick = useCallback((event, data = {}) => {
    const { rowIndex } = data
    setIsAddUserModalOpen(true)
    setActiveMenuRowIndex(rowIndex)
  }, [])

  const handleCloseAddUserModalClick = useCallback(() => {
    setIsAddUserModalOpen(false)
  }, [])

  const isHasMorePages = useMemo(() => {
    if (getUsersQuery.isPending) return true
    return (
      getUsersQuery?.paginate?.page_no * getUsersQuery?.paginate?.page_limit <
      getUsersQuery?.paginate?.total
    )
  }, [
    getUsersQuery.isPending,
    getUsersQuery?.paginate?.page_limit,
    getUsersQuery?.paginate?.page_no,
    getUsersQuery?.paginate?.total,
  ])

  const loadingRowCount = useMemo(() => {
    if (getUsersQuery?.isIdle || !getUsersQuery?.data?.length) {
      return 10
    }
    if (isHasMorePages) {
      return 2
    }
    return 0
  }, [getUsersQuery?.data?.length, getUsersQuery?.isIdle, isHasMorePages])

  const handleLoadMore = useCallback(() => {
    if (getUsersQuery?.isPending || getUsersQuery?.isIdle || !isHasMorePages)
      return
    let page = (getUsersQuery?.paginate?.page_no || 0) + 1
    fetchUsers({ page })
  }, [
    fetchUsers,
    getUsersQuery?.isIdle,
    getUsersQuery?.isPending,
    getUsersQuery?.paginate?.page_no,
    isHasMorePages,
  ])

  const handleThreeDotMenuClick = useCallback(
    (action, event, data) => {
      switch (action) {
        case "edit": {
          handleEditUserClick(event, data)
          break
        }
        case "delete": {
          handleDeleteUserClick(event, data)
          break
        }
        default: {
          // do nothing
        }
      }
    },
    [handleDeleteUserClick, handleEditUserClick]
  )

  const handleSortChange = useCallback(
    (order, column) => {
      let sortBy = order === "" ? "" : column.id
      setSortBy(sortBy)
      setSortOrder(order)
      fetchUsers({ page: 1, sort: sortBy, order: order })
    },
    [fetchUsers]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(value => {
      fetchUsers({ page: 1, term: value })
    }, 500),
    []
  )

  const handleSearchChange = useCallback(
    e => {
      setSearch(e.target.value)
      handleSearch(e.target.value)
    },
    [handleSearch]
  )

  useEffect(
    function setColumnsEffect() {
      const columns = [
        {
          id: "name",
          header: "Name",
          accessor: "name",
          sortOrder: isColumnSorted("name") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          id: "email",
          header: "Email",
          accessor: "email",
          sortOrder: isColumnSorted("email") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          id: "telephone",
          header: "Telephone",
          accessor: "telephone",
          sortOrder: isColumnSorted("telephone") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          id: "designation",
          header: "Designation",
          accessor: "designation",
          sortOrder: isColumnSorted("designation") ? sortOrder : "",
          onSortChange: handleSortChange,
        },
        {
          header: "",
          width: "24px",
          sort: false,
          renderCell: data => {
            return <ThreeDotMenu data={data} />
          },
        },
      ]
      setColumns(columns)
    },
    [handleSortChange, isColumnSorted, sortOrder]
  )

  return useMemo(() => {
    return {
      listRef,
      isAddUserModalOpen,
      setIsAddUserModalOpen,
      columns,
      setColumns,
      activeMenuRowIndex,
      setActiveMenuRowIndex,
      search,
      setSearch,
      sortBy,
      setSortBy,
      sortOrder,
      setSortOrder,
      getUsersQuery,
      addUserQuery,
      updateUserQuery,
      deleteUserQuery,
      isColumnSorted,
      fetchUsers,
      handleEditUser,
      handleAddUser,
      handleDeleteUserClick,
      handleEditUserClick,
      handleCloseAddUserModalClick,
      isHasMorePages,
      loadingRowCount,
      handleLoadMore,
      handleThreeDotMenuClick,
      handleSortChange,
      handleSearch,
      handleSearchChange,
    }
  }, [
    activeMenuRowIndex,
    addUserQuery,
    columns,
    deleteUserQuery,
    fetchUsers,
    getUsersQuery,
    handleAddUser,
    handleCloseAddUserModalClick,
    handleDeleteUserClick,
    handleEditUser,
    handleEditUserClick,
    handleLoadMore,
    handleSearch,
    handleSearchChange,
    handleSortChange,
    handleThreeDotMenuClick,
    isAddUserModalOpen,
    isColumnSorted,
    isHasMorePages,
    loadingRowCount,
    search,
    sortBy,
    sortOrder,
    updateUserQuery,
  ])
}

export const [UsersPageProvider, useUsersPageContext] =
  generateContext(useUsersPage)
