import moment from "moment"
import is from "utils/is"
import * as yup from "yup"
import Const from "../../../constants"
import Enums from "../../../constants/enums"
import * as validations from "../../../helpers/validation-helper"
import {
  getFormattedDate,
  getFormattedDateforAPI,
} from "../../../utils/date-time"

export const FN = {
  ARRIVAL_COUNTRY: "arrival_country",
  ARRIVAL_PORT: "arrival_port",
  ARRIVAL_DATE: "arrival_date",
  ARRIVAL_TIME: "arrival_time",

  DEPARTURE_DATE: "departure_date",
  DEPARTURE_TIME: "departure_time",

  ADDITIONAL_NOTES: "additional_notes",

  SHORE_POWER_AVAILBLE: "shore_power_available",
  SHORE_POWER_USED: "shore_power_used",
  BUNKERING_DONE: "bunkering_done",

  EMISSION: {
    ROOT: "emission",
    FUEL_TYPE: "fuel_type",
    FUEL_TYPE_OTHER: "fuel_type_other",
    FUEL_QUANTITY: "fuel_quantity",
    PORT_ACTIVITY: "port_activity",
    EMISSION_FACTOR: "emission_factor",
  },
}

export const PORT_ACTIVITY_LIST = [
  { label: "At Berth", value: "at_berth" },
  { label: "Movement", value: "movement" },
]

export const getEmissionInitialValue = (data = {}) => {
  return {
    id: data.id || "",
    [FN.EMISSION.PORT_ACTIVITY]: data.port_activity || "",
    [FN.EMISSION.FUEL_TYPE]: data.fuel_type_id,
    [FN.EMISSION.FUEL_TYPE_OTHER]: data.fuel_type_other || "",
    [FN.EMISSION.FUEL_QUANTITY]: data.fuel_quantity || "",
    [FN.EMISSION.EMISSION_FACTOR]: data.emission_factor || "",
  }
}

export const generateInitialValues = (data = {}) => {
  return {
    [FN.ARRIVAL_COUNTRY]: Const.COUNTRIES.IND.VALUE,
    [FN.ARRIVAL_PORT]: data.arrival_port_id || null,
    [FN.ARRIVAL_DATE]: data.arrival_date
      ? [moment(data.arrival_date).toDate()]
      : [],
    [FN.ARRIVAL_TIME]: data.arrival_time
      ? [moment(data.arrival_time).toDate()]
      : [],

    [FN.DEPARTURE_DATE]: data.departure_date
      ? [moment(data.departure_date).toDate()]
      : [],
    [FN.DEPARTURE_TIME]: data.departure_time
      ? [moment(data.departure_time).toDate()]
      : [],

    [FN.ADDITIONAL_NOTES]: data.additional_notes || "",

    [FN.SHORE_POWER_AVAILBLE]: is.undefined(data.shore_power_available)
      ? ""
      : data.shore_power_available,
    [FN.SHORE_POWER_USED]: is.undefined(data.shore_power_used)
      ? ""
      : data.shore_power_used,
    [FN.BUNKERING_DONE]: is.undefined(data.bunkering_done)
      ? ""
      : data.bunkering_done,
    [FN.EMISSION.ROOT]: (data.actual_emissions || [{}]).map(item => {
      return getEmissionInitialValue(item)
    }),
  }
}

export const generateValidationSchema = () => {
  return yup.lazy(values => {
    const isShorePowerWasAvailable = values[FN.SHORE_POWER_AVAILBLE] === true

    return yup.object().shape({
      [FN.ARRIVAL_PORT]: validations.isRequiredString(),
      [FN.ARRIVAL_DATE]: validations.isRequiredDate(),
      [FN.ARRIVAL_TIME]: validations.isRequiredDate({ message: "Required" }),

      [FN.DEPARTURE_DATE]: validations.isRequiredDate(),
      [FN.DEPARTURE_TIME]: validations.isRequiredDate({ message: "Required" }),

      [FN.SHORE_POWER_AVAILBLE]: validations.isRequiredString(),
      [FN.SHORE_POWER_USED]: validations.isRequiredString({
        withRequired: isShorePowerWasAvailable,
      }),
      [FN.BUNKERING_DONE]: validations.isRequiredString(),

      [FN.EMISSION.ROOT]: yup.array().of(
        yup.lazy(values => {
          const isFuelTypeOther =
            values[FN.EMISSION.FUEL_TYPE] === Enums.OTHER_OPTION.value

          return yup.object().shape({
            [FN.EMISSION.FUEL_TYPE]: validations.isRequiredString({
              nullable: true,
            }),
            [FN.EMISSION.FUEL_TYPE_OTHER]: validations.isRequiredString({
              withRequired: isFuelTypeOther,
            }),
            [FN.EMISSION.FUEL_QUANTITY]: validations.isRequiredString(),
            [FN.EMISSION.PORT_ACTIVITY]: validations.isRequiredString(),
            [FN.EMISSION.EMISSION_FACTOR]: validations.isRequiredString({
              withRequired: isFuelTypeOther,
            }),
          })
        })
      ),
    })
  })
}

export const generatePayload = formData => {
  return {
    type: "port",

    arrival_country_id: formData[FN.ARRIVAL_COUNTRY],
    arrival_port_id: formData[FN.ARRIVAL_PORT],
    arrival_date: getFormattedDateforAPI(formData[FN.ARRIVAL_DATE]),
    arrival_time: getFormattedDate(formData[FN.ARRIVAL_DATE], "hh:mm"),

    departure_date: getFormattedDateforAPI(formData[FN.DEPARTURE_DATE]),
    departure_time: getFormattedDate(formData[FN.DEPARTURE_TIME], "hh:mm"),

    additional_notes: formData[FN.ADDITIONAL_NOTES],

    shore_power_available: formData[FN.SHORE_POWER_AVAILBLE],
    shore_power_used: formData[FN.SHORE_POWER_USED],
    bunkering_done: formData[FN.BUNKERING_DONE],

    actual_emissions: formData[FN.EMISSION.ROOT].map(actEm => {
      const isFuelTypeOther =
        actEm[FN.EMISSION.FUEL_TYPE] === Enums.OTHER_OPTION.value

      const item = {
        fuel_type_id: actEm[FN.EMISSION.FUEL_TYPE],
        fuel_type_other: isFuelTypeOther
          ? actEm[FN.EMISSION.FUEL_TYPE_OTHER]
          : null,
        fuel_quantity: actEm[FN.EMISSION.FUEL_QUANTITY],
        emission_factor: actEm[FN.EMISSION.EMISSION_FACTOR],
        port_activity: actEm[FN.EMISSION.PORT_ACTIVITY],
      }
      if (actEm.id) {
        item.id = actEm.id
      }
      return item
    }),
  }
}
