import { useAsync } from "@react-org/hooks"
import { useMemo, useCallback, useState } from "react"
import generateContext from "utils/generate-context"
import * as ListsApi from "../../../apis/lists.api"
import { FN } from "./form-helper"

function useRegisterForm() {
  const [file, setFile] = useState(null)
  const getCountriesQuery = useAsync(
    useCallback(
      payload => ListsApi.getCountries({ params: { limit: 250 }, ...payload }),
      []
    )
  )
  const getCitiesQuery = useAsync(
    useCallback(
      payload =>
        ListsApi.getCitiesByCountry({ params: { limit: 250 }, ...payload }),
      []
    ),
    {
      immediate: false,
    }
  )

  const handleOnDrop = useCallback(file => {
    setFile(file)
  }, [])

  const handleClearFile = useCallback(() => {
    setFile(null)
  }, [])

  const handleCountryChange = useCallback(
    formik => countryId => {
      const country = (getCountriesQuery?.data?.data?.data || []).find(
        item => item.id === countryId
      )
      formik.setFieldValue(FN.CITY, "")
      formik.setFieldValue(FN.CITY_OTHER, "")

      if (country?.code === "ind" || country?.code === "IND") {
        const getCitiesQueryExecute = getCitiesQuery.execute
        getCitiesQueryExecute({ countryId })
      } else {
        // reset cities list
        const getCitiesQuerySetQueryData = getCitiesQuery.setQueryData
        getCitiesQuerySetQueryData(null)
      }
    },
    [
      getCitiesQuery.execute,
      getCitiesQuery.setQueryData,
      getCountriesQuery?.data?.data?.data,
    ]
  )

  const handleCancelFormClick = useCallback(
    formik => () => {
      formik.setFieldValue(FN.COUNTRY, "")
      formik.setFieldValue(FN.CITY, "")
      formik.resetForm()
    },
    []
  )

  return useMemo(() => {
    return {
      getCountriesQuery,
      getCitiesQuery,
      handleCountryChange,
      handleCancelFormClick,
      handleOnDrop,
      handleClearFile,
      file,
    }
  }, [
    file,
    getCitiesQuery,
    getCountriesQuery,
    handleCancelFormClick,
    handleClearFile,
    handleCountryChange,
    handleOnDrop,
  ])
}

export const [RegisterFormProvider, useRegisterFormContext] =
  generateContext(useRegisterForm)
