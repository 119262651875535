import ShipDetailCard from "components/Common/ship-details-card"
import React, { useMemo } from "react"
import { Button, Card, Col, Container, Row } from "reactstrap"
import withHOC from "utils/with-hoc"
import Info from "../../components/Common/Info"
import ModalContainer from "../../components/Common/modal-container"
import NoRecords from "../../components/Common/no-records"
import VirtualizeTable from "../../components/Common/virtualiza-table"
import AddPortEmissionForm from "../../components/Forms/add-port-emission-form"
import AddVoyageEmissionForm from "../../components/Forms/add-voyage-emission-form"
import SearchInput from "../../components/search-input"
import ShipIcon from "../../components/Vectors/ship-icon"
import Heading from "../../components/VerticalLayout/Heading"
import Sidebar from "../../components/VerticalLayout/Sidebar"
import { EmissionPageProvider, useEmissionPageContext } from "./provider"

const renderNoDataRows = () => {
  return <NoRecords message="No emissions found" />
}
const EmissionPage = () => {
  //meta title
  document.title = "Emissions"

  const {
    activeMenuRowIndex,
    getShipQuery,
    getEmissionsQuery,
    listRef,
    columns,
    isHasMorePages,
    loadingRowCount,
    handleLoadMore,
    breadcrumb,
    isAddPortEmissionModalOpen,
    isAddVoyageEmissionModalOpen,
    handleOnAddPortEmissionClick,
    handleCloseAddPortEmissionClick,
    handleAddPortEmissionSubmit,
    handleOnAddVoyageEmissionClick,
    handleCloseAddVoyageEmissionClick,
    handleAddVoyageEmissionSubmit,
    search,
    handleSearchChange,
  } = useEmissionPageContext()

  const activeRow = useMemo(
    () => getEmissionsQuery?.data?.[activeMenuRowIndex],
    [activeMenuRowIndex, getEmissionsQuery?.data]
  )

  return (
    <>
      <Heading title={getShipQuery?.data?.data?.name} breadcrumbs={breadcrumb} />
      <div id="my-fleet-page" className="page-content">
        <Container fluid={true} className="with-sidebar">
          <Sidebar />
          <div className="main">
            <ShipDetailCard data={getShipQuery?.data?.data} />
            <div className="section">
              <div className="section-heading">
                <div className="section-heading_left">
                  <div className="section-heading_title">
                    <SearchInput
                      value={search}
                      onChange={handleSearchChange}
                      placeholder="Search"
                      type="text"
                      className="search-input"
                      iconLeft
                    />
                  </div>
                </div>
                <div className="section-heading_right">
                  <div className="section-heading_actions">
                    <Button
                      type="button"
                      color="success"
                      onClick={handleOnAddPortEmissionClick}
                      className="rounded-10"
                    >
                      <i className="bx bx-plus font-size-16 align-middle me-2"></i>
                      Add Port Emission
                    </Button>
                    <Button
                      type="button"
                      color="success"
                      onClick={handleOnAddVoyageEmissionClick}
                      className="rounded-10"
                    >
                      <i className="bx bx-plus font-size-16 align-middle me-2"></i>
                      Add Voyage Emission
                    </Button>
                  </div>
                </div>
              </div>
              <div className="section-body">
                <VirtualizeTable
                  ref={listRef}
                  rows={getEmissionsQuery.data}
                  columns={columns}
                  hasMore={isHasMorePages}
                  loadMore={handleLoadMore}
                  isLoading={getEmissionsQuery?.isPending}
                  loadingRowCount={loadingRowCount()}
                  isLoadMoreDirty={!getEmissionsQuery?.isIdle}
                  renderNoDataRows={renderNoDataRows}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <ModalContainer
        isOpen={isAddPortEmissionModalOpen}
        title={activeRow ? "Edit Emission - Port" : "Add Emission - Port"}
        handleClose={handleCloseAddPortEmissionClick}
      >
        <AddPortEmissionForm
          data={activeRow}
          onSubmit={handleAddPortEmissionSubmit}
          onClose={handleCloseAddPortEmissionClick}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={isAddVoyageEmissionModalOpen}
        title={activeRow ? "Edit Emission - Voyage" : "Add Emission - Voyage"}
        handleClose={handleCloseAddVoyageEmissionClick}
      >
        <AddVoyageEmissionForm
          data={activeRow}
          onSubmit={handleAddVoyageEmissionSubmit}
          onClose={handleCloseAddVoyageEmissionClick}
        />
      </ModalContainer>
    </>
  )
}
export default withHOC(EmissionPageProvider, EmissionPage)
